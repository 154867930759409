import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import { t } from '@lingui/macro'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import { itensStatusCombo, itensOperation } from '../../mocks/listComboStatus'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RootState } from '../../store/Store'
import { addDays } from 'date-fns'
import BasicDateRangePicker from '@Components/dateRangePicker'

export interface FilterProps {
  onFilter?: (param: any) => void
  filterHide?: boolean
}

// eslint-disable-next-line react/prop-types
const Filter: React.FC<FilterProps> = ({ onFilter, filterHide }) => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [handleComboCalendar, setHandleComboCalendar] = useState('default')
  const [handleComboStatus, setHandleComboStatus] = useState('default')
  const [handleComboOperation, setHandleComboOperation] = useState('default')
  const [authId, setAuthId] = useState('')
  const [errorComboCalendar, setErrorComboCalendar] = useState(false)
  // eslint-disable-next-line
  const [openFilter, setOpenFilter] = useState(false)
  const [count, setCount] = useState(0)

  const handleChangeStatus = (event: any) => {
    setHandleComboStatus(event.target.dataset.value)
  }
  const handleChangeOperation = (event: any) => {
    setHandleComboOperation(event.target.dataset.value)
  }
  const handleChangeInput = (event: any) => {
    setAuthId(event.target.value)
  }

  const handleCalendar = (event: any) => {
    setHandleComboCalendar(event.target.dataset.value)
  }

  const changeDateHandler = (props) => {
    setCount(count + 1)
    // eslint-disable-next-line react/prop-types
    setStartDate(props.startDate)
    // eslint-disable-next-line react/prop-types
    setEndDate(props.endDate)
  }

  const handleFilter = () => {
    let objParam = {}
    if (handleComboOperation !== 'default') objParam['operation'] = handleComboOperation
    if (handleComboStatus !== 'default') objParam['status'] = handleComboStatus
    if (authId !== '') objParam['authorization_id'] = authId
    if (handleComboCalendar !== 'default' && count > 0) {
      setErrorComboCalendar(false)
      objParam[`start${handleComboCalendar}`] = startDate
      objParam[`end${handleComboCalendar}`] = endDate
    }

    onFilter && onFilter(objParam)
  }

  return (
    <Box mx='20px' mb='20px'>
      <Accordion>
        <Box mx='20px'>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography variant='h5' id='titleFilter'>
              {i18n._(t`Filtrar`)}
            </Typography>
          </AccordionSummary>
        </Box>

        <AccordionDetails>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  select
                  data-testid='comboCalendar'
                  error={errorComboCalendar}
                  value={handleComboCalendar}
                  label={errorComboCalendar ? i18n._(t`Por favor selecione uma data`) : ''}
                  InputProps={{}}
                  //eslint-disable-next-line no-restricted-globals
                  onChange={() => handleCalendar(event)}
                >
                  <MenuItem data-testid='' key={0} value={'default'}>
                    {i18n._(t`Selecione a data`)}
                  </MenuItem>
                  <MenuItem key={1} value={'EventDate'}>
                    {i18n._(t`Data de origem`)}
                  </MenuItem>
                  <MenuItem key={2} value={'PaymentDate'}>
                    {i18n._(t`Data de vencimento`)}
                  </MenuItem>
                  <MenuItem key={3} value={'ReceiptDate'}>
                    {i18n._(t`Data de recebimento`)}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <BasicDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={changeDateHandler}
                  language={language}
                  maxDate={addDays(startDate, 10)}
                  placeholderText={i18n._(t`Por favor selecione uma data`)}
                  isClearable
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label={'Status'}
                  data-testid='comboStatus'
                  select
                  value={handleComboStatus}
                  //eslint-disable-next-line no-restricted-globals
                  onChange={() => handleChangeStatus(event)}
                >
                  <MenuItem key={1} value={'default'}>
                    {i18n._(t`Selecione o Status`)}
                  </MenuItem>
                  {itensStatusCombo.map((res, i) => {
                    return (
                      <MenuItem key={i} value={res.value}>
                        {i18n._(`${res.name}`)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={i18n._(t`Operação`)}
                  data-testid='comboOperacao'
                  select
                  value={handleComboOperation}
                  InputProps={{}}
                  //eslint-disable-next-line no-restricted-globals
                  onChange={() => handleChangeOperation(event)}
                >
                  <MenuItem value={'default'}>{i18n._(t`Selecione a operação`)}</MenuItem>
                  {itensOperation.map((res, i) => {
                    return (
                      <MenuItem key={i} value={res}>
                        {i18n._(t`${res}`)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  data-testid='inputAutorizationId'
                  label={i18n._(t`Autorization ID`)}
                  type={'number'}
                  onChange={(event) => handleChangeInput(event)}
                />
              </Grid>
            </Grid>
            <br />
            <Box>
              <Button data-testid='buttonFilter' variant='contained' onClick={() => handleFilter()} color='secondary'>
                {i18n._(t`Filtrar`)}
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export { Filter }
