import { Dispatch } from 'redux'

import { IError } from '@pismo/sdk-core'

import { mountAuthHeaders, interceptor } from '../utils'
import { ITax, Merchants } from 'src/resources/merchants'
import { ICreditorsOperation, IMarketplaceResult, Marketplace } from 'src/resources/marketplace'

export enum SellerTaxEvents {
  CLEAR_TAX = 'CLEAR_TAX',
  CLEAR_MARKETPLACE = 'CLEAR_MARKETPLACE',
  CLEAR_CREDITORS_OPERATIONS = 'CLEAR_CREDITORS_OPERATIONS',
  GET_TAX = 'GET_TAX',
  SET_LOADING_TAX = 'SET_LOADING_TAX',
  GET_MARKETPLACE = 'GET_MARKETPLACE',
  SET_MARKETPLACES_ERROR = 'SET_MARKETPLACES_ERROR',
  SET_TAX_ERROR = 'SET_TAX_ERROR',
  GET_CREDITORS_OPERATIONS = 'GET_CREDITORS_OPERATIONS',
  SET_CREDITORS_OPERATIONS_ERROR = 'SET_CREDITORS_OPERATIONS_ERROR',
}

export interface SellerTaxAction {
  type: SellerTaxEvents
  payload?: any
}

export interface SellerTax {
  tax: ITax | null
  taxError: boolean
  marketplacesError: string
  marketplaces: IMarketplaceResult | null
  creditorsOperations: any | null
  networkList: { [k: string]: string } | null
  creditorsOperationsError: boolean
  loadingTax?: boolean
}

export const sellerTax: SellerTax = {
  tax: null,
  taxError: false,
  marketplacesError: '',
  marketplaces: null,
  creditorsOperations: null,
  creditorsOperationsError: false,
  networkList: null,
  loadingTax: false,
}

export const sellerTaxReducer = (state: SellerTax = sellerTax, action: SellerTaxAction): SellerTax => {
  switch (action.type) {
    case SellerTaxEvents.CLEAR_TAX:
      return { ...state, tax: null, taxError: false }
    case SellerTaxEvents.CLEAR_CREDITORS_OPERATIONS:
      return { ...state, creditorsOperations: null, networkList: null, creditorsOperationsError: false }
    case SellerTaxEvents.CLEAR_MARKETPLACE:
      return { ...state, marketplaces: null, marketplacesError: '' }
    case SellerTaxEvents.SET_TAX_ERROR:
      return { ...state, taxError: true }
    case SellerTaxEvents.SET_MARKETPLACES_ERROR:
      return { ...state, marketplacesError: action.payload }
    case SellerTaxEvents.GET_TAX:
      return { ...state, taxError: false, tax: action.payload }
    case SellerTaxEvents.SET_LOADING_TAX:
      return { ...state, loadingTax: action.payload }
    case SellerTaxEvents.GET_MARKETPLACE:
      return { ...state, marketplaces: action.payload }
    case SellerTaxEvents.SET_CREDITORS_OPERATIONS_ERROR:
      return { ...state, creditorsOperationsError: true }
    case SellerTaxEvents.GET_CREDITORS_OPERATIONS:
      return {
        ...state,
        creditorsOperations: action.payload.list,
        networkList: action.payload.network,
        creditorsOperationsError: false,
      }
    default:
      return state
  }
}

export interface ISellerTaxActions {
  getTax: (merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  getMarketplaces: (merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  getCreditorsOperations: (marketplaceID: number, merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  clearAll: () => (dispatch: Dispatch) => void
}

export const SellerTaxActions: ISellerTaxActions = {
  getTax: (merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: SellerTaxEvents.CLEAR_TAX })
    dispatch({ type: SellerTaxEvents.SET_LOADING_TAX, payload: true })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const resp = await MerchantsClient.getTax(merchantID)

    if ((resp as IError).error) {
      dispatch({ type: SellerTaxEvents.SET_TAX_ERROR })
      return
    }

    dispatch({ type: SellerTaxEvents.GET_TAX, payload: resp as ITax })
    dispatch({ type: SellerTaxEvents.SET_LOADING_TAX, payload: false })
  },

  getMarketplaces: (merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: SellerTaxEvents.CLEAR_MARKETPLACE })

    const MerchantsClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const { data } = await MerchantsClient.getMarketplaces(merchantID)
      dispatch({ type: SellerTaxEvents.GET_MARKETPLACE, payload: data })
    } catch (err: any) {
      dispatch({
        type: SellerTaxEvents.SET_MARKETPLACES_ERROR,
        payload: err?.response?.data?.message || err?.response?.data,
      })
    }
  },

  getCreditorsOperations:
    (marketplaceID: number, merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
      dispatch({ type: SellerTaxEvents.CLEAR_CREDITORS_OPERATIONS })

      const MarketplaceClient = Marketplace({
        headers: mountAuthHeaders(credentials),
      })

      const client = MarketplaceClient.getClient()

      interceptor(client, dispatch)

      const resp: ICreditorsOperation[] | IError = await MarketplaceClient.getCreditorsOperation(
        marketplaceID,
        merchantID,
      )

      if ((resp as IError).error) {
        dispatch({ type: SellerTaxEvents.SET_CREDITORS_OPERATIONS_ERROR })
        return
      }

      let proc: any = {}
      let net: any = {}
      ;(resp as ICreditorsOperation[]).map((el) => {
        if (!proc[el.processing_code]) proc[el.processing_code] = {}

        if (!el.network) net['others'] = 'others'
        else net[el.network] = el.network

        if (!proc[el.processing_code][el.operation_type_description])
          proc[el.processing_code][el.operation_type_description] = {}

        if (!proc[el.processing_code][el.operation_type_description][el.installments])
          proc[el.processing_code][el.operation_type_description][el.installments] = {}

        proc[el.processing_code][el.operation_type_description][el.installments][el.network] = el
      })

      dispatch({
        type: SellerTaxEvents.GET_CREDITORS_OPERATIONS,
        payload: { list: proc, network: net },
      })
    },

  clearAll: () => (dispatch: Dispatch) => {
    dispatch({ type: SellerTaxEvents.CLEAR_TAX })
    dispatch({ type: SellerTaxEvents.CLEAR_MARKETPLACE })
    dispatch({ type: SellerTaxEvents.CLEAR_CREDITORS_OPERATIONS })
  },
}
