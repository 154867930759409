import { I18n } from '@lingui/core'

export enum TranslationEvents {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  EXPOSE_I18N = 'EXPOSE_I18N',
}

export interface TranslationAction {
  type: TranslationEvents
  payload?: any
}

export interface AbledLanguages {
  en: string
  pt: string
  es: string
}

export interface Translation {
  abledLanguages: AbledLanguages
  language: keyof AbledLanguages
  i18n: I18n | null
}

const translations: { [k in keyof AbledLanguages]: AbledLanguages } = {
  en: {
    en: 'English',
    pt: 'Portuguese',
    es: 'Spanish',
  },
  pt: {
    en: 'Inglês',
    pt: 'Português',
    es: 'Espanhol',
  },
  es: {
    en: 'Inglés',
    pt: 'Portugués',
    es: 'Español',
  },
}

export const translation: Translation = {
  abledLanguages: {
    en: 'Inglês',
    pt: 'Português',
    es: 'Espanhol',
  },
  language: 'pt',
  i18n: null,
}

export const translationReducer = (state: Translation = translation, action: TranslationAction) => {
  switch (action.type) {
    case TranslationEvents.EXPOSE_I18N:
      return { ...state, i18n: action.payload }
    case TranslationEvents.CHANGE_LANGUAGE:
      localStorage.setItem('ui.language', action.payload)
      return {
        ...state,
        abledLanguages: translations[action.payload],
        language: action.payload,
      }
    default:
      return state
  }
}

export interface ITranslationActions {
  changeLanguage: (language: keyof AbledLanguages) => TranslationAction
  exposeI18n: (i18n: I18n) => TranslationAction
}

export const TranslationActions = {
  changeLanguage: (language: keyof AbledLanguages) => ({
    type: TranslationEvents.CHANGE_LANGUAGE,
    payload: language,
  }),

  exposeI18n: (i18n: I18n) => ({ type: TranslationEvents.EXPOSE_I18N, payload: i18n }),
}
