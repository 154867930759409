// export const mountAuthHeaders = ({ token, tenant, protocol }): Headers => {
export const mountAuthHeaders = ({ token, tenant, protocol }) => {
  // const headers: Headers = {
  const headers = {
    Authorization: `Bearer ${token}`,
    'x-tenant': tenant,
  }

  if (protocol) {
    headers['x-protocol'] = protocol
  }

  return headers
}
