import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppBar from '@mui/material/AppBar'
import MToolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import SortIcon from '@mui/icons-material/Sort'

import { MobileToolbarActions } from '../../store'
import { RootState } from '../../store/Store'

const { toggle } = MobileToolbarActions

const MobileAppbar: React.FC = () => {
  const { isOpen } = useSelector((state: RootState) => state.mobiletoolbar)
  const dispatch = useDispatch()

  const clicked = () => {
    dispatch(toggle(!isOpen))
  }
  return (
    <AppBar position='relative'>
      <MToolbar>
        <IconButton onClick={clicked} size='large'>
          <SortIcon />
        </IconButton>
      </MToolbar>
    </AppBar>
  )
}

export { MobileAppbar }
