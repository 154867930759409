import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Top } from '@Components/sellers/Top'
import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import AppBar from '@Components/appBar'
import Home from '@mui/icons-material/Home'
import EventIcon from '@mui/icons-material/Event'
import SellerIcon from '@mui/icons-material/ShoppingCartOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import { MarketPlaceActions } from '../../../store'
import { t } from '@lingui/macro'
import { MarketplaceProfile } from './MarketplaceProfile'
import { MarketplaceSchedule } from './MarketplaceSchedule'
import { MarketplaceEstablishments } from './MarketplaceEstablishments'
import { ConditionsList } from './conditions/ConditionsList'
import { RootState } from '../../../store/Store'
import Loading from '@Components/loading'

const { marketplaceEditGet, clearMarketplace } = MarketPlaceActions

const MarketplaceContainer: React.FC = () => {
  const { id, page } = useParams<any>()
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { marketplaceEditResult } = useSelector(
    (state: RootState) => state.marketplaceEdit,
    (prev, next) => prev.marketplaceEditResult === next.marketplaceEditResult,
  )
  const user = useSelector((state: RootState) => state.user)

  const menuElements = [
    {
      Icon: Home,
      label: i18n._(t`home`),

      name: 'home',
    },
    {
      Icon: SellerIcon,
      label: i18n._(t`estabelecimentos`),
      name: 'marketplaceSellers',
    },
    {
      Icon: BallotOutlinedIcon,
      label: i18n._(t`condições`),
      name: 'conditions',
    },
    {
      Icon: EventIcon,
      label: i18n._(t`agenda`),
      name: 'schedule',
    },
  ]

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (!marketplaceEditResult || marketplaceEditResult?.id?.toString() !== id) {
      dispatch(clearMarketplace())
      dispatch(marketplaceEditGet(id, user))
    }
  }, [])

  const onBack = () => history.push('/marketplace')
  const menuClicked = (page: string) => history.push(`/marketplace/edit/${id}/${page}`)
  const getPage = () => {
    switch (page) {
      case 'home':
        return <MarketplaceProfile method={'edit'} />
      case 'marketplaceSellers':
        return <MarketplaceEstablishments marketplace={marketplaceEditResult} />
      case 'conditions':
        return <ConditionsList />
      case 'schedule':
        return <MarketplaceSchedule marketplace={marketplaceEditResult} />
      case 'report':
        return <p> Relatório</p>
      default:
        return <div>page not found</div>
    }
  }

  return (
    <Page>
      <AppBar back={onBack} title={marketplaceEditResult ? marketplaceEditResult.marketplace_name : ''}></AppBar>
      <Top
        items={menuElements}
        name={marketplaceEditResult ? marketplaceEditResult?.marketplace_name : ''}
        page={page}
        onBack={onBack}
        onMenuClick={menuClicked}
      />
      <PageContent>{marketplaceEditResult ? getPage() : <Loading />}</PageContent>
    </Page>
  )
}

export { MarketplaceContainer }
