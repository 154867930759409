import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import GlobeIcon from '@mui/icons-material/Language'

import { FormControl } from '@Components/formControl'
import { PismoBar } from '@Components/pismoBar'
import { ExpansionMenu } from '@Components/expansionMenu'
import TextField from '@Components/textField'

import { Feedback } from '@Components/feedback'

import { SetupActions, TranslationActions } from '../../store'
import { RootState } from '../../store/Store'

const { getSetup, changeSetup, clearChangeResult } = SetupActions
const { changeLanguage } = TranslationActions

const Setup = () => {
  const { id } = useParams<any>()
  const history = useHistory()
  const dispatch = useDispatch()

  const { language, abledLanguages, i18n } = useSelector((state: RootState) => state.translation)
  const { getResult, changeResult } = useSelector((state: RootState) => state.setup)

  const schema = {
    pass: {
      length: {
        minimum: 6,
        message: i18n?._(t`Digite uma senha com pelo menos 6 caracteres`),
      },
    },
    pass2: {
      presence: {
        allowempty: false,
        message: i18n?._(t`Repita a senha`),
      },
      equality: {
        attribute: 'pass',
        message: i18n?._(t`As senhas devem ser iguais`),
      },
    },
  }

  const [isLoading, setIsloading] = useState(true)

  const [openFeedback, setOpenFeedback] = useState(false)
  const [feedbackResult, setFeedbackResult] = useState(true)

  useEffect(() => {
    dispatch(getSetup(id))
  }, [])

  useEffect(() => {
    if (getResult !== null) {
      if (getResult) {
        setIsloading(false)
      } else {
        history.push('/')
      }
    }
  }, [getResult])

  useEffect(() => {
    if (changeResult !== null && !openFeedback) {
      setFeedbackResult(changeResult)
      setOpenFeedback(true)
      dispatch(clearChangeResult())
    }
  }, [changeResult])

  const setLanguage = (val: any) => {
    dispatch(changeLanguage(val.key))
  }

  const submitHandler = (values) => {
    dispatch(changeSetup(id, values.pass, values.pass2))
  }

  const feedbackClosed = () => {
    setOpenFeedback(false)
    history.push('/')
  }

  if (isLoading)
    return (
      <Box display='flex' width={1} height='100vh' justifyContent='center' alignItems='center'>
        <CircularProgress color='primary' />
      </Box>
    )

  return (
    <Box display='flex' width={1} height='100vh' justifyContent='center' alignItems='flex-start'>
      <Box display='flex' height='100vh' flexDirection='column'>
        <PismoBar current='PismoMKT' AppBarProps={{ position: 'relative' }} />
        <Box display='flex' alignItems='flex-end' height='100%'>
          <ExpansionMenu
            data={Object.keys(abledLanguages).map((k) => ({
              key: k,
              label: abledLanguages[k],
            }))}
            getComponent={(item) => <div data-testid='languagebar-item'>{item.label}</div>}
            SumaryComponent={() => (
              <Box display='flex' alignItems='center'>
                <GlobeIcon />
                <Box ml='10px'>
                  <Typography variant='body1'>{String(language).toUpperCase()}</Typography>
                </Box>
              </Box>
            )}
            onChange={setLanguage}
          />
        </Box>
      </Box>
      <Box width={1} display='flex' height='100%' alignItems='center' justifyContent='center'>
        <FormControl initialValue={{}} validationSchema={schema} onSubmit={submitHandler}>
          {(data) => (
            <Card>
              <CardContent>
                <FormView {...data} />
              </CardContent>
            </Card>
          )}
        </FormControl>
      </Box>
      <Feedback
        open={openFeedback}
        title={feedbackResult ? i18n._(t`Sucesso!`) : i18n._(t`Erro!`)}
        message={
          feedbackResult
            ? i18n._(t`Suas alterações foram registradas com sucesso.`)
            : i18n._(t`Ocorreu um erro no servidor. Tente novamente em alguns instantes.`)
        }
        buttonLabel={i18n._(t`continuar`)}
        onClose={feedbackClosed}
        success={feedbackResult}
      />
    </Box>
  )
}

const FormView = ({ values, errors, handleChange }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          label={i18n._(t`senha`)}
          placeholder={i18n._(t`A senha deve conter no mínimo 6 (seis) caracteres`)}
          name='pass'
          value={values.pass}
          error={Boolean(errors.pass)}
          helperText={Boolean(errors.pass) ? errors.pass : ''}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={toggleShowPassword} size='large'>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label={i18n._(t`confirmação`)}
          placeholder={i18n._(t`Repita a senha`)}
          name='pass2'
          value={values.pass2}
          error={Boolean(errors.pass2)}
          helperText={Boolean(errors.pass2) ? errors.pass2 : ''}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
        />
      </Grid>
      <Grid item xs={12}>
        <Box ml='auto' width={1 / 4}>
          <Button type='submit' variant='contained' color='primary'>
            {i18n._(t`enviar`)}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export { Setup }
