import * as React from 'react'

import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  format,
  getDaysInMonth,
  startOfMonth,
  getDay,
  getDate,
  getMonth,
  isSameMonth,
  lastDayOfWeek,
  sub,
  add,
} from 'date-fns'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const { useState, useEffect } = React

export interface DaysContainerProps {
  locale: Locale
  startDate: Date
  currentMonth: number
  currentYear: number
  endDate?: Date
  isSameDate?: boolean
  current?: 'start' | 'end'
  isSingle?: boolean
  onChange?: (selected: Date) => void
  customDay?: (
    day: number,
    index: number,
    currentDay: number[],
    currentMonth: number,
    currentYear: number,
    currentDate: Date,
    sameMonth: boolean,
  ) => React.ReactNode
}

const DaysContainer: React.FC<any> = ({
  locale,
  startDate,
  endDate,
  isSameDate,
  current,
  onChange,
  currentMonth,
  currentYear,
  customDay,
  isSingle = false,
}: any) => {
  const [weeks, setWeeks] = useState(null)
  const [days, setDays] = useState(null)
  const [currentDay, setCurrentDay] = useState<Array<Number>>([])
  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  const sameMonth = isSameMonth(startDate, endDate)

  useEffect(() => {
    const sqStart = document.getElementById('Bolt-Calendar-day-squareStart')
    const sqEnd = document.getElementById('Bolt-Calendar-day-squareEnd')

    if (!sameMonth) {
      // @ts-ignore: Object is possibly 'null'.
      sqStart.style.display = 'none'
      // @ts-ignore: Object is possibly 'null'.
      sqEnd.style.display = 'none'
    }

    setCurrentDate(startDate)

    if (isSameDate) {
      setCurrentDay([getDate(startDate)])
    } else {
      if (startDate && endDate) {
        if (getMonth(startDate) === getMonth(endDate) && sameMonth) {
          setCurrentDay([getDate(startDate), getDate(endDate)])
        } else {
          if (current === 'end') {
            setCurrentDay([getDate(endDate)])
            setCurrentDate(endDate)
          } else {
            setCurrentDay([getDate(startDate)])
          }
        }
      } else {
        setCurrentDay([getDate(startDate)])
      }
    }
    // @ts-ignore.
    setWeeks(getWeekNames(locale, currentDate))
  }, [current, startDate, endDate])

  useEffect(() => {
    // @ts-ignore.
    setDays(getDays())
  }, [currentDay, currentMonth, currentYear])

  useEffect(() => {
    const sqStart = document.getElementById('Bolt-Calendar-day-squareStart')
    const sqEnd = document.getElementById('Bolt-Calendar-day-squareEnd')
    // @ts-ignore: Object is possibly 'null'.
    sqStart.style.display = 'none'
    // @ts-ignore: Object is possibly 'null'.
    sqEnd.style.display = 'none'

    if (!isSameMonth(currentDate, new Date(currentYear, currentMonth)) || isSameDate || isSingle) return

    if (currentDay.length === 2 && sameMonth) {
      // @ts-ignore: Object is possibly 'null'.
      sqStart.style.display = 'block'
      // @ts-ignore: Object is possibly 'null'.
      sqEnd.style.display = 'block'
    } else {
      // @ts-ignore: Object is possibly 'null'.
      sqStart.style.display = 'block'
    }

    if (endDate && current === 'end' && !sameMonth) {
      // @ts-ignore: Object is possibly 'null'.
      sqStart.style.display = 'none'
    }

    const startDateNum = getDate(startDate)
    let startDif = lastDayOfWeek(startDate)

    if (!isSameMonth(startDate, startDif)) startDif = sub(startDif, { days: 7 })
    const startDifNum = getDate(startDif)

    const elemStart = document.getElementById(`Bolt-Calendar-day-${startDateNum}`)
    const startElemDif =
      startDifNum === startDateNum ? null : document.getElementById(`Bolt-Calendar-day-${startDifNum}`)

    if (startElemDif) {
      // @ts-ignore.
      const startElemBounds = elemStart.getBoundingClientRect()
      const startElemDifBounds = startElemDif.getBoundingClientRect()
      // @ts-ignore.
      sqStart.style.width = `${
        startElemDifBounds.x + startElemDifBounds.width - (startElemBounds.x + startElemBounds.width / 2) + 10
      }px`
      // @ts-ignore.
      sqStart.style.top = `${elemStart.offsetTop}px`
      // @ts-ignore.
      sqStart.style.left = `${elemStart.offsetLeft + startElemBounds.width / 2}px`
    } else {
      // @ts-ignore.
      sqStart.style.display = 'none'
    }

    if (endDate && (current === 'end' || sameMonth)) {
      // @ts-ignore.
      sqEnd.style.display = 'block'

      const endDateNum = getDate(endDate)
      let endDif = startOfWeek(endDate)

      if (!isSameMonth(endDate, endDif)) endDif = add(endDif, { days: 7 })
      const endDifNum = getDate(endDif)

      const elemEnd = document.getElementById(`Bolt-Calendar-day-${endDateNum}`)
      const endElemDif = endDifNum === endDateNum ? null : document.getElementById(`Bolt-Calendar-day-${endDifNum}`)

      if (endElemDif) {
        // @ts-ignore.
        const endElemBounds = elemEnd.getBoundingClientRect()
        const endElemDifBounds = endElemDif.getBoundingClientRect()
        // @ts-ignore.
        sqEnd.style.width = `${endElemBounds.x + endElemBounds.width / 2 - endElemDifBounds.x + 10}px`
        // @ts-ignore.
        sqEnd.style.top = `${elemEnd.offsetTop}px`
        // @ts-ignore.
        sqEnd.style.left = `${endElemDif.offsetLeft - 10}px`
      } else {
        // @ts-ignore.
        sqEnd.style.display = 'none'
      }
    }
  }, [days])

  function getDays() {
    const date = new Date(currentYear, currentMonth)
    const totalDays = getDaysInMonth(date)
    const startMonth = getDay(startOfMonth(date))

    let res = []

    for (let i = 0; i < totalDays + startMonth; i++) {
      // @ts-ignore.
      if (i < startMonth) res.push(null)
      // @ts-ignore.
      else res.push(i - startMonth + 1)
    }

    return res
  }

  const dayClicked = (day: number) => () => {
    if (onChange) onChange(new Date(currentYear, currentMonth, day))
  }

  return (
    <Box width={1} display='flex' flexDirection='column' minHeight='280px'>
      <Box width={1} display='flex' p='.6'>
        {weeks &&
          // @ts-ignore.
          weeks.map((w, index) => (
            <Box key={`${w}-${index}`} width={1 / 7} display='flex' justifyContent='center'>
              {w}
            </Box>
          ))}
      </Box>
      <Box width={1} display='flex' p='10px' pt='0px' flexWrap='wrap' position='relative'>
        {days &&
          // @ts-ignore.
          days.map((d, index) => {
            return customDay ? (
              customDay(d, index, currentDay, currentMonth, currentYear, currentDate, sameMonth)
            ) : (
              <Box
                key={`${String(d)}-${index}`}
                id={`Bolt-Calendar-day-${d}`}
                width={1 / 7}
                display='flex'
                justifyContent='center'
                alignItems='center'
                py='5px'
                zIndex={1}
              >
                {d ? (
                  <IconButton onClick={dayClicked(d)} size='large'>
                    <Typography variant='body1'>{d < 10 ? `0${d}` : d}</Typography>
                  </IconButton>
                ) : (
                  ''
                )}
              </Box>
            )
          })}
        <div id='Bolt-Calendar-day-squareEnd' />
        <div id='Bolt-Calendar-day-squareStart' />
      </Box>
    </Box>
  )
}

export function getWeekNames(locale: Locale, date: Date, formating = 'iii') {
  return eachDayOfInterval({
    start: startOfWeek(date),
    end: endOfWeek(date),
  }).reduce((a: Array<any>, d: Date) => {
    a.push(formating === 'iii' ? format(d, formating, { locale }).substr(0, 3) : format(d, formating, { locale }))
    return a
  }, [])
}

export { DaysContainer }
