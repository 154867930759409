import * as React from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}
interface ContentProps {
  message?: string
  variant: keyof typeof variantIcon
}

interface SnackProps extends SnackbarProps {
  message?: string
  variant: keyof typeof variantIcon
}

const Content: React.FC<ContentProps> = React.forwardRef(({ message, variant }: ContentProps, ref: any) => {
  const Icon = variantIcon[variant]
  return (
    <SnackbarContent
      ref={ref}
      className={variant}
      message={
        <Box display='flex'>
          <Icon className={`${variant}-icon`} />
          <Box ml='10px'>{message}</Box>
        </Box>
      }
    />
  )
})

const SlideTransition = (p) => <Slide {...p} direction='up' />

const Snackbar: React.FC<SnackProps> = (props: SnackProps) => {
  const snackProps: SnackProps = { ...props }

  const { message, variant, ...els } = snackProps
  return (
    <MuiSnackbar autoHideDuration={6000} {...els} TransitionComponent={SlideTransition}>
      <Content message={props.message} variant={props.variant} />
    </MuiSnackbar>
  )
}

export { Snackbar }
