import React, { memo } from 'react'
import { Chart, ReactGoogleChartEvent } from 'react-google-charts'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import { RootState } from 'src/store/Store'
import { t } from '@lingui/macro'
import { locale } from '@Components/calendar'
import { formatToDefaultCurrency } from 'src/utils/number'

import { SellerAnticipationActions } from '../../../../store'
import { SellerAnticipationChartPropType } from './interface'
import { IAntecipateResult } from 'src/resources/merchants'

const SellerAnticipationChart = ({ anticipationData, sliderChange }: SellerAnticipationChartPropType) => {
  const dispatch = useDispatch()
  const { setAnticipationDate } = SellerAnticipationActions
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const isEmptyData = () => !anticipationData?.items?.length

  // it is only possible to customize react-google-charts tooltip in the form of a string to render as html
  function generateTooltipContent(item) {
    return `<div style="padding:5px 5px 15px 15px;min-width: 10rem; ">
              <div style="margin-bottom:10px; color: #faa845; font-size: 20px">${format(
                new Date(item.payment_date),
                'PP',
                {
                  locale: locale[language],
                },
              )}</div>
              <div style="color:#CACFD2;" >${i18n._(t`TotalARreceber`)}:</div>
              <div><b>${formatToDefaultCurrency(Number(item.gross_value))}</b></div>
              <div style="color:#CACFD2;">${i18n._(t`ValorAAntecipar`)}:</div>
              <div><b>${formatToDefaultCurrency(Number(item.net_value))}</b></div>
              <div style="color:#CACFD2;">${i18n._(t`liquidoAReceber`)}:</div>
              <div><b>${formatToDefaultCurrency(Number(item.advancement_amount))}</b></div>
          </div>
      `
  }

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart()
        const selection = chart.getSelection()

        if (selection.length === 1) {
          const [selectedItem] = selection
          const { row } = selectedItem
          dispatch(setAnticipationDate(row))
        } else {
          dispatch(setAnticipationDate(null))
        }
      },
    },
  ]

  const buildChartData = (result: IAntecipateResult) => {
    const dataBase = result?.items?.map((item) => [
      new Date(item.payment_date),
      item.advancement_amount,
      generateTooltipContent(item),
    ])

    return [
      [i18n._(t`Data`), i18n._(t`ValorLiquidoAReceber`), { type: 'string', role: 'tooltip', p: { html: true } }],
      ...dataBase,
    ]
  }
  const options = {
    hAxis: {
      format: i18n._(t`chartDateFormat`),
    },
    legend: { position: 'none' },
    tooltip: { isHtml: true },
    trigger: 'selection',
    title: i18n._(t`datasAntecipacao`),
  }

  return !isEmptyData() ? (
    <div>
      <Chart
        chartType='ColumnChart'
        width='100%'
        height='15rem'
        data={buildChartData(anticipationData)}
        options={options}
        chartPackages={['corechart', 'controls']}
        chartEvents={chartEvents}
        controls={[
          {
            controlType: 'ChartRangeFilter',
            options: {
              filterColumnIndex: 0,
              ui: {
                chartType: 'LineChart',
                chartOptions: {
                  chartArea: { width: '70%', height: '100%' },
                  hAxis: { baselineColor: 'none', format: i18n._(t`chartDateFormat`) },
                  height: 50,
                },
              },
            },
            controlPosition: 'bottom',
            controlEvents: [
              {
                eventName: 'statechange',
                callback: ({ controlWrapper }) => {
                  sliderChange(controlWrapper?.getState()?.range)
                },
              },
            ],
          },
        ]}
      />
    </div>
  ) : null
}

export default memo(SellerAnticipationChart)
