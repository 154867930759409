import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import { useHistory } from 'react-router-dom'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import AppBar from '@Components/appBar'

import { AccountForm, AccountData } from './AccountForm'
import { AddressForm, ContactData } from './AddressForm'
import { CreditorForm } from './CreditorForm'

import Footer from './Footer'

import { SellerNewActions } from '../../../store'
import { RootState } from '../../../store/Store'
import { useSnackbar } from 'notistack'
import { NewSeller } from './interface'
import { CreditorResult } from 'src/resources/merchants'

const { createSeller, setOpenFeedback } = SellerNewActions

const SellerNew = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { response, openFeedback } = useSelector((state: RootState) => state.sellerNew)
  const user = useSelector((state: RootState) => state.user)

  const history = useHistory()

  const [currentPage, setCurrentPage] = useState(0)
  const [allData, setAllData] = useState<{ [key: string]: any }>({})
  const [sending, setSending] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const container = useRef<any>()

  const dispatch = useDispatch()

  const createNewSeller = (newSeller: NewSeller) => {
    setSending(true)
    const { contactData: contact, accountData: account, creditorData: creditor } = newSeller

    const seller = {
      zip_code: contact?.postal_code,
      city: contact?.city,
      country: 'brasil',
      number: contact?.number,
      state: contact?.state,
      address: contact?.address,
      complementary_address: contact?.complemento,
      neighborhood: contact?.neighborhood,
      program_id: account?.program_type.program_id,
      document_number: account?.document_number,
      name: account?.name,
      external_code: account?.cnae.external_code,
      email: account?.email,
      area_code: contact?.phone.substring(0, 2),
      phone: contact?.phone.substring(2),
      creditor,
      terminals: [],
      custom_fields: {
        cnes: undefined,
        crm: undefined,
        doctor_name: undefined,
        notes: undefined,
      },
      additional_area_code: contact?.phone2?.substring(0, 2),
      additional_phone: contact?.phone2?.substring(2),
    }
    dispatch(createSeller(seller, user))
  }

  useEffect(() => {
    if (openFeedback) {
      if (response?.data?.id) {
        setAllData({})
        enqueueSnackbar(i18n._(t`Seus dados foram registrados com sucesso.`), { variant: 'success' })
        backClicked()
      } else {
        enqueueSnackbar(response?.message || i18n._(t`genericApiError`), { variant: 'error' })
      }
      setSending(false)
      dispatch(setOpenFeedback(false))
      setCurrentPage(0)
    }
  }, [openFeedback])

  const content = [
    {
      label: 'Conta',
      component: (next, data) => (
        <AccountForm onNext={next} data={data.accountData}>
          <Footer isFirstStep={isFirstStep} goBack={goBack} isLastStep={isLastStep} />
        </AccountForm>
      ),
    },
    {
      label: 'Endereço',
      component: (next, data) => (
        <AddressForm onNext={next} data={data.contactData}>
          <Footer isFirstStep={isFirstStep} goBack={goBack} isLastStep={isLastStep} />
        </AddressForm>
      ),
    },
    {
      label: 'Creditor',
      component: (next, data) => (
        <CreditorForm onNext={next} data={data.creditorData}>
          <Footer isFirstStep={isFirstStep} goBack={goBack} isLastStep={isLastStep} />
        </CreditorForm>
      ),
    },
  ]

  const isLastStep = currentPage >= content.length - 1
  const isFirstStep = currentPage === 0

  const next = function <T>(data: T) {
    if (isAccountData(data)) {
      setAllData((_allData) => ({ ..._allData, accountData: { ...data } }))
      setCurrentPage(currentPage + 1)
    }

    if (isContactData(data)) {
      setAllData((_allData) => ({ ..._allData, contactData: { ...data } }))
      setCurrentPage(currentPage + 1)
    }
    if (isCreditorData(data)) {
      setAllData((_allData) => {
        const newData = { ..._allData, creditorData: { ...data } }
        createNewSeller(newData)
        return newData
      })
    }
  }

  const backClicked = () => {
    history.push('/sellers')
  }

  function isAccountData(data: any): data is AccountData {
    if ((data as AccountData).document_number) {
      return true
    }

    return false
  }

  function isContactData(data: any): data is ContactData {
    if ((data as ContactData).postal_code) {
      return true
    }

    return false
  }

  function isCreditorData(data: any): data is CreditorResult {
    if ((data as CreditorResult).hasOwnProperty('maximum_advancement_percentage')) {
      return true
    }

    return false
  }

  if (sending) {
    return (
      <Box width={1} height={1} display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress color='success' />
      </Box>
    )
  }

  const goBack = () => {
    currentPage >= 1 && setCurrentPage(currentPage - 1)
  }

  return (
    <Page>
      <AppBar title={i18n._(t`Novo estabelecimento`)} back={backClicked}></AppBar>
      <PageContent>
        <Box mx='1rem'>
          <div ref={container}>
            <Box my='2rem'>
              <Stepper activeStep={currentPage} alternativeLabel>
                <Step>
                  <StepLabel>{i18n._(t`Conta`)}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{i18n._(t`Logradouro`)}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{i18n._(t`Creditor`)}</StepLabel>
                </Step>
              </Stepper>
            </Box>

            <Box mt='0rem'>{content[currentPage].component(next, allData)}</Box>
          </div>
        </Box>
      </PageContent>
    </Page>
  )
}

export { SellerNew }
