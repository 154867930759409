import { Dispatch } from 'redux'
import { Events, IMerchantsEvent } from '@pismo/sdk-events'
import { IError } from '@pismo/sdk-core'

import { mountAuthHeaders, interceptor } from '../utils'
import { Merchants, statuses } from 'src/resources/merchants'

export enum SellerWorkflowEvents {
  GET_EVENTS = 'GET_EVENTS_seller-workflow',
  CLEAR_EVENTS = 'CLEAR_EVENTS_seller-workflow',
  GET_STATUSES = 'GET_STATUSES_seller-workflow',
  CLEAR_STATUSES = 'CLEAR_STATUSES_seller-workflow',
  STATUS_RESULT = 'STATUS_RESULT_seller-workflow',
  CLEAR_STATUS_RESULT = 'CLEAR_STATUS_RESULT_seller-workflow',
}

export interface SellerWorkflowAction {
  type: SellerWorkflowEvents
  payload?: any
}

export interface SellerWorkflow {
  events: IMerchantsEvent[] | null
  statuses: statuses[] | any[]
  statusResult: boolean | null
}

export const sellerWorkflow: SellerWorkflow = {
  events: null,
  statuses: [],
  statusResult: null,
}

export const sellerWorkflowReducer = (
  state: SellerWorkflow = sellerWorkflow,
  action: SellerWorkflowAction,
): SellerWorkflow => {
  switch (action.type) {
    case SellerWorkflowEvents.CLEAR_EVENTS:
      return { ...state, events: null }
    case SellerWorkflowEvents.GET_EVENTS:
      return { ...state, events: action.payload as IMerchantsEvent[] }
    case SellerWorkflowEvents.GET_STATUSES:
      return { ...state, statuses: action.payload as statuses[] }
    case SellerWorkflowEvents.CLEAR_STATUSES:
      return { ...state, statuses: [] }
    case SellerWorkflowEvents.STATUS_RESULT:
      return { ...state, statusResult: action.payload }
    case SellerWorkflowEvents.CLEAR_STATUS_RESULT:
      return { ...state, statusResult: null }
    default:
      return state
  }
}

export interface ISellerWorkflowActions {
  getEvents: (merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  clearEvents: () => SellerWorkflowAction
  getStatuses: (merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  clearStatuses: () => SellerWorkflowAction
  changeStatus: (merchantID: number, status: statuses, notes: string, credentials: any) => (dispatch: Dispatch) => void
  clearStatusResult: () => SellerWorkflowAction
}

export const SellerWorkflowActions: ISellerWorkflowActions = {
  getEvents: (merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
    const EventsClient = Events({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = EventsClient.getClient()

    interceptor(client, dispatch)

    const resp: IMerchantsEvent[] | IError = await EventsClient.getMerchantsEvents(merchantID)

    if ((resp as IError).error) {
      return
    }

    dispatch({ type: SellerWorkflowEvents.GET_EVENTS, payload: resp as IMerchantsEvent[] })
  },
  clearEvents: () => ({ type: SellerWorkflowEvents.CLEAR_EVENTS }),

  getStatuses: (merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const resp = await MerchantsClient.getWorkflowStatuses(merchantID)

    if ((resp as IError).error) {
      return
    }

    dispatch({ type: SellerWorkflowEvents.GET_STATUSES, payload: resp as statuses[] })
  },
  clearStatuses: () => ({ type: SellerWorkflowEvents.CLEAR_STATUSES }),

  changeStatus:
    (merchantID: number, status: statuses, notes: string, credentials: any) => async (dispatch: Dispatch) => {
      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)

      try {
        await MerchantsClient.changeWorkflowStatus(merchantID, status, notes)
        dispatch({ type: SellerWorkflowEvents.STATUS_RESULT, payload: true })
      } catch (err: any) {
        dispatch({ type: SellerWorkflowEvents.STATUS_RESULT, payload: false })
      }
    },
  clearStatusResult: () => ({ type: SellerWorkflowEvents.CLEAR_STATUS_RESULT }),
}
