import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import TextField from '@Components/textField'
import { FormControl } from '@Components/formControl'

import { StateResult, CitiesResult } from '@Components/world-format/types'
import { RootState } from '../../../store/Store'

export interface ContactData {
  postal_code: string
  address: string
  neighborhood: string
  city: CitiesResult
  state: StateResult
  number: number
  phone: string
  complemento?: string
  phone2?: string
  terminal_pos?: number
  digit_pos?: number
}

export interface AddressFormProps {
  onNext: <T>(data: T) => void
  data?: ContactData
  children?: React.ReactElement
}

const AddressForm: React.FC<AddressFormProps> = ({ onNext, data, children }: AddressFormProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  const schema = {
    postal_code: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Código postal inválido`),
      },
    },
    address: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o endereço.`),
      },
    },
    number: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o número.`),
      },
    },
    neighborhood: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o bairro.`),
      },
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Número de telefone inválido.`),
      },
    },
    state: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Estado inválido.`),
      },
    },
  }

  const submitHandler = (values) => {
    const res = { ...values }

    onNext<ContactData>(res as ContactData)
  }

  return (
    <div data-testid='contact-container'>
      <FormControl
        initialValue={{
          state: '',
          city: '',
          ...data,
        }}
        onSubmit={submitHandler}
        validationSchema={schema}
      >
        {(data) => (
          <Box mb='1rem'>
            <Card data-testid='account-container'>
              <CardContent>
                <Box mt='1rem'>
                  <FormView {...data} />
                </Box>
              </CardContent>
            </Card>
            {children}
          </Box>
        )}
      </FormControl>
    </div>
  )
}

const FormView = ({ values, errors, handleChange }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <TextField
          label={i18n._(t`código postal`)}
          name='postal_code'
          value={values.postal_code}
          onChange={handleChange}
          error={Boolean(errors.postal_code)}
          helperText={Boolean(errors.postal_code) ? errors.postal_code : ''}
          required
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          label={i18n._(t`Endereço`)}
          name='address'
          value={values.address}
          onChange={handleChange}
          error={Boolean(errors.address)}
          helperText={Boolean(errors.address) ? errors.address : ''}
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label={i18n._(t`Número`)}
          name='number'
          value={values.number}
          onChange={handleChange}
          error={Boolean(errors.number)}
          helperText={Boolean(errors.number) ? errors.number : ''}
          type='number'
          required
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          label={i18n._(t`Complemento`)}
          name='complemento'
          value={values.complemento}
          onChange={handleChange}
          error={Boolean(errors.complemento)}
          helperText={Boolean(errors.complemento) ? errors.complemento : ''}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={i18n._(t`Bairro`)}
          name='neighborhood'
          value={values.neighborhood}
          onChange={handleChange}
          error={Boolean(errors.neighborhood)}
          helperText={errors.neighborhood}
          multiline
          required
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <TextField
          label={i18n._(t`Cidade`)}
          name='city'
          value={values.city}
          onChange={handleChange}
          helperText={errors.city}
          error={Boolean(errors.city)}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label={i18n._(t`Estado`)}
          name='state'
          value={values.state}
          onChange={handleChange}
          helperText={errors.state}
          error={Boolean(errors.state)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={i18n._(t`Telefone principal`)}
          name='phone'
          value={values.phone}
          onChange={handleChange}
          error={Boolean(errors.phone)}
          helperText={Boolean(errors.phone) ? errors.phone : ''}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={i18n._(t`Telefone Adicional (opcional)`)}
          name='phone2'
          value={values.phone2}
          onChange={handleChange}
          error={Boolean(errors.phone2)}
          helperText={Boolean(errors.phone2) ? errors.phone2 : ''}
        />
      </Grid>
    </Grid>
  )
}

export { AddressForm }
