import * as React from 'react'

import { StandardTextFieldProps } from '@mui/material/TextField'

import TextField from '../textField'
import { CurrencyCode } from '../world-format/types'
import { InputAdornment } from '@mui/material'
import { NumericFormat } from 'react-number-format'

const { useEffect, useState } = React

export type Translation = 'en' | 'pt' | 'es'

export interface CurrencyInputProps {
  currency?: CurrencyCode
  lang?: Translation
  initialValue?: number
  onChange?: (value: number) => void
  TextfieldProps?: StandardTextFieldProps
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  onChange,
  lang = 'en',
  currency = '$',
  initialValue = 0,
  TextfieldProps,
}: any) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(initialValue)
  }, [])

  const changeValue = (val) => {
    const value = val.target.value

    if (onChange) onChange(value.replace(/,/g, ''))

    setValue(value)
  }

  return (
    <NumericFormat
      customInput={TextField}
      InputProps={{
        startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
      }}
      {...TextfieldProps}
      value={value}
      onChange={changeValue}
      thousandSeparator=' '
      allowLeadingZeros
      decimalScale={3}
      valueIsNumericString={true}
    ></NumericFormat>
  )
}

export { CurrencyInput }
