import * as React from 'react'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import { PismoMarketplace } from '../../../commons/Icons'
import { ApplicationToolbarProps } from './interfaces'
import Typography from '@mui/material/Typography'

const Logo = ({ name, ...props }: any) => {
  switch (name) {
    case 'marketplace':
      return <PismoMarketplace {...props} />
    default:
      return <PismoMarketplace {...props} />
  }
}

const ApplicationToolbar: React.FC<ApplicationToolbarProps> = ({
  AppBarProps,
  ToolbarProps,
  current,
  onClick,
  applications,
  full,
  contract,
  app,
}: ApplicationToolbarProps) => {
  const appIcon = (status) => {
    if (status) {
      return (
        <IconButton onClick={onClick} data-testid='mainButton' size='large'>
          <MenuIcon data-testid={applications[current] ? applications[current].name : current} />
        </IconButton>
      )
    } else {
      return <MenuIcon data-testid={applications[current] ? applications[current].name : current} />
    }
  }

  return (
    <AppBar {...AppBarProps}>
      <Toolbar {...ToolbarProps}>
        {appIcon(app)}
        {contract ? null : (
          <Hidden smDown={full ? false : true}>
            <Box ml='5px'>
              {applications[current] ? (
                <Logo name={applications[current].name} />
              ) : (
                <Typography variant='h6'>{current}</Typography>
              )}
            </Box>
          </Hidden>
        )}
      </Toolbar>
    </AppBar>
  )
}

export { ApplicationToolbar }
