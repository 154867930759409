import React from 'react'
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { formatToDefaultCurrency } from 'src/utils/number'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/Store'
import { t, date } from '@lingui/macro'
import Loading from '@Components/loading'

const getTextColor = (value) => (Number(value) < 0 ? 'error' : undefined)

type Props = {
  transactions: any
  isPerDay?: boolean
  transactionLoading?: boolean
}

const Statement = ({ transactions, isPerDay, transactionLoading }: Props) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const renderCardActions = (year, month) => {
    const getFormattedValue = (value) => formatToDefaultCurrency(Number(value))

    return (
      <CardActions>
        <Box ml='auto' mr={1}>
          <Typography variant='h6' color='primary'>
            {i18n._(t`Total`)}
          </Typography>
        </Box>
        <Box width='40%' display='flex'>
          <Box width={1 / 3} display='flex' justifyContent='center'>
            {getFormattedValue(transactions?.[year][month].total.gross_value)}
          </Box>
          <Box className='discount' width={1 / 3} display='flex' justifyContent='center'>
            <Typography variant='body1' color={getTextColor(transactions?.[year][month].total.discount)}>
              {getFormattedValue(transactions?.[year][month].total.discount)}
            </Typography>
          </Box>
          <Box width={1 / 3} display='flex' justifyContent='center'>
            {getFormattedValue(transactions?.[year][month].total.net_value)}
          </Box>
        </Box>
      </CardActions>
    )
  }
  const renderOperationContent = (year, month, day, operation, realIndex, type, key) => {
    return (
      <TableRow className='button-base' key={`${day}-${month}-${year}-${key}`} hover={true}>
        <TableCell>
          <Box display='flex'>
            <Box>
              <Typography>
                {i18n._(
                  t`${date(new Date(Number(year), Number(month), Number(day)), {
                    month: 'numeric',
                    year: 'numeric',
                    day: 'numeric',
                  })}`,
                )}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align='center'>
          <Typography>
            {type} / {operation.operation}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography color={getTextColor(operation.gross_value_total)}>
            {formatToDefaultCurrency(Number(operation.gross_value_total))}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className='discount' color={getTextColor(operation.discount_value_total)}>
            {formatToDefaultCurrency(Number(operation.discount_value_total))}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography color={getTextColor(operation.net_value_total)}>
            {formatToDefaultCurrency(Number(operation.net_value_total))}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  const hasData = !!transactions && !!Object.keys(transactions).length
  const isLoading = transactionLoading

  const renderStatement = () => (
    <>
      {Object.keys(transactions).map((year) => {
        return Object.keys(transactions[year]).map((month, key) => {
          let realIndex = -1
          return (
            <Box key={key} mb={8}>
              <Box>
                {!isPerDay && (
                  <Box my={2} textAlign='center'>
                    <Typography variant='h5' color='primary'>
                      {i18n._(t`${date(new Date(Number(year), Number(month)), { month: 'short', year: 'numeric' })}`)}
                    </Typography>
                  </Box>
                )}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>{i18n._(t`data`)}</TableCell>
                        <TableCell align='center'>{i18n._(t`tipo`)}</TableCell>
                        <TableCell align='right'>{i18n._(t`bruto`)}</TableCell>
                        <TableCell align='right'>{i18n._(t`desconto`)}</TableCell>
                        <TableCell align='right'>{i18n._(t`líquido`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(transactions[year][month]).map((day) => {
                        if (day === 'total') return null
                        return Object.keys(transactions[year][month][day]).map((type) => {
                          if (type === 'total') return null
                          if (transactions[year][month][day][type].operations === undefined) {
                            return
                          }
                          return transactions[year][month][day][type].operations.map((operation, key) => {
                            realIndex = realIndex + 1
                            return renderOperationContent(year, month, day, operation, realIndex, type, key)
                          })
                        })
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mt={1}>
                  <Box key={month}>
                    <CardContent className='content'>
                      <Box display='flex' className='table-header' mt='1.25rem' p='0.3rem'>
                        <Box width={6 / 10} display='flex'></Box>
                        <Box width={4 / 10} display='flex'>
                          <Box width={1 / 3} display='flex' justifyContent='center'>
                            <Typography>{i18n._(t`bruto`)}</Typography>
                          </Box>
                          <Box width={1 / 3} display='flex' justifyContent='center'>
                            <Typography>{i18n._(t`desconto`)}</Typography>
                          </Box>
                          <Box width={1 / 3} display='flex' justifyContent='center'>
                            <Typography>{i18n._(t`líquido`)}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                    <Divider />
                    {renderCardActions(year, month)}
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })
      })}
    </>
  )

  const renderPlaceHolder = () =>
    !!isPerDay && (
      <Box px={10}>
        <Typography color='primary'>{i18n._(t`noTransactions`)}</Typography>
      </Box>
    )

  return <Box mt={3}>{isLoading ? <Loading /> : hasData ? renderStatement() : renderPlaceHolder()}</Box>
}

export default Statement
