import React, { Fragment, memo } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { FormControl } from '@Components/formControl'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import { RootState } from '../../store/Store'
import CircularProgress from '@mui/material/CircularProgress'

export interface marketplaceFormInterface {
  method: string
  handleSubmit: (item: any) => void
}

const MarketplaceForm: React.FC<marketplaceFormInterface> = memo(
  ({ handleSubmit, method }: marketplaceFormInterface) => {
    const { i18n } = useSelector((state: RootState) => state.translation)
    const { marketplaceEditResult, loadingMarketplaceEdit } = useSelector((state: RootState) => state.marketplaceEdit)

    const gatewayStatus = marketplaceEditResult && marketplaceEditResult?.marketplace_configs?.length > 0
    const gatewayWith = marketplaceEditResult ? marketplaceEditResult?.marketplace_configs?.length : 0
    const InitDefaultGateway = gatewayStatus
      ? marketplaceEditResult?.marketplace_configs?.[0]?.value
      : 'NoPaymentGateway'
    const InitConfigId = gatewayStatus && gatewayWith > 1 ? marketplaceEditResult?.marketplace_configs?.[1]?.value : ''
    const InitConfigKey = gatewayStatus && gatewayWith > 1 ? marketplaceEditResult?.marketplace_configs?.[2]?.value : ''

    const schema = {
      document_number: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite seu numero de documento`),
        },
      },
      marketplace_name: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o nome razão social`),
        },
      },
      brand_name: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o nome fantasia`),
        },
      },
      number: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o numero do Logradouro`),
        },
      },
      registration: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite a inscrição municipal`),
        },
      },
      contact: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite um nome contato`),
        },
      },
      email: {
        presence: { allowEmpty: false, message: i18n._(t`E-mail inválido`) },
        email: { message: i18n._(t`E-mail inválido`) },
      },
      zip_code: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite seu cep`),
        },
      },
      address: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite seu endereço`),
        },
      },
      marketplaceNeighborhood: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o nome do seu bairro`),
        },
      },
      merchantState: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o estado`),
        },
      },
      marketplaceCity: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite a cidade`),
        },
      },
      marketplaceCountry: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o país`),
        },
      },
      phone_number: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Digite o DDD telefone`),
        },
      },
      defaultGateway: {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Por favor, Selecione uma opção`),
        },
      },
    }

    const onSubmitHandler = (values) => {
      let result = values

      result.marketplaceConfig = [
        {
          config: 'default-gateway',
          value: values.defaultGateway,
        },
      ]
      handleSubmit(result)
    }

    const validationHandler = () => {
      return schema
    }

    return (
      <Box data-testid='mkt-form'>
        <FormControl
          initialValue={{
            document_number: marketplaceEditResult ? marketplaceEditResult.document_number : '',
            marketplace_name: marketplaceEditResult ? marketplaceEditResult.marketplace_name : '',
            brand_name: marketplaceEditResult ? marketplaceEditResult.brand_name : '',
            registration: marketplaceEditResult ? marketplaceEditResult.registration : '',
            contact: marketplaceEditResult ? marketplaceEditResult.contact : '',
            number: marketplaceEditResult ? marketplaceEditResult.number : '',
            email: marketplaceEditResult ? marketplaceEditResult.email : '',
            zip_code: marketplaceEditResult ? marketplaceEditResult.zip_code : '',
            address: marketplaceEditResult ? marketplaceEditResult.address : '',
            marketplaceNeighborhood: marketplaceEditResult ? marketplaceEditResult.neighborhood : '',
            merchantState: marketplaceEditResult ? marketplaceEditResult.state : '',
            marketplaceCity: marketplaceEditResult ? marketplaceEditResult.city : '',
            marketplaceCountry: marketplaceEditResult ? marketplaceEditResult.country : '',
            phone_number: marketplaceEditResult ? marketplaceEditResult.phone_number : '',
            additional_phone_number: marketplaceEditResult ? marketplaceEditResult.additional_phone_number : '',
            gateway: marketplaceEditResult ? marketplaceEditResult.registration : '',
            defaultGateway: InitDefaultGateway !== '' ? InitDefaultGateway : 'NoPaymentGateway',
            configId: InitConfigId !== '' ? InitConfigId : '',
            complementary_address: marketplaceEditResult ? marketplaceEditResult.complementary_address : '',
            configKey: InitConfigKey !== '' ? InitConfigKey : '',
            id: marketplaceEditResult ? marketplaceEditResult.id : '-',
          }}
          onValidation={validationHandler}
          onSubmit={onSubmitHandler}
        >
          {(data) => {
            const { values, handleChange, errors } = data
            return (
              <Fragment>
                <Box m='20px' pb='20px'>
                  <Box display='flex' width={1}>
                    <Grid container spacing={2}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={6} data-testid='mkt-input-cnpj'>
                          <TextField
                            required
                            label={i18n._(t`CNPJ`)}
                            name='document_number'
                            onChange={handleChange}
                            value={values.document_number}
                            helperText={errors.document_number}
                            error={Boolean(errors.document_number)}
                          />
                        </Grid>
                        <Grid item xs={6} data-testid='mkt-input-email'>
                          <TextField
                            required
                            name='email'
                            label={i18n._(t`E-mail`)}
                            value={values.email}
                            error={Boolean(errors.email)}
                            helperText={Boolean(errors.email) ? errors.email : ''}
                            onChange={handleChange}
                            inputProps={{ 'data-testid': 'email' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item container xs={12} md={12} spacing={2}>
                      <Grid item xs={method !== 'new' ? 6 : 12} data-testid='mkt-input-razao-social'>
                        <TextField
                          required
                          label={i18n._(t`Razão Social`)}
                          name='marketplace_name'
                          onChange={handleChange}
                          value={values.marketplace_name}
                          helperText={errors.marketplace_name}
                          error={Boolean(errors.marketplace_name)}
                        />
                      </Grid>
                      {method !== 'new' && (
                        <Grid item xs={6} data-testid='mkt-input-id'>
                          <TextField
                            required
                            label={i18n._(t`Id`)}
                            name='id'
                            onChange={handleChange}
                            value={values.id}
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item container xs={12} md={12} spacing={2}>
                      <Grid item xs={4} data-testid='mkt-input-nome-fantasia'>
                        <TextField
                          required
                          label={i18n._(t`Nome Fantasia`)}
                          name='brand_name'
                          onChange={handleChange}
                          value={values.brand_name}
                          helperText={errors.brand_name}
                          error={Boolean(errors.brand_name)}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid='mkt-input-inscricao-municipal'>
                        <TextField
                          required
                          label={i18n._(t`Inscrição Municipal`)}
                          name='registration'
                          onChange={handleChange}
                          value={values.registration}
                          helperText={errors.registration}
                          error={Boolean(errors.registration)}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid='mkt-input-contato'>
                        <TextField
                          required
                          label={i18n._(t`Contato`)}
                          name='contact'
                          onChange={handleChange}
                          value={values.contact}
                          helperText={errors.contact}
                          error={Boolean(errors.contact)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item container xs={12} md={12} spacing={2}>
                      <Grid item xs={4} data-testid='mkt-input-cep'>
                        <TextField
                          required
                          label={i18n._(t`CEP`)}
                          name='zip_code'
                          onChange={handleChange}
                          value={values.zip_code}
                          helperText={errors.zip_code}
                          error={Boolean(errors.zip_code)}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid='mkt-input-logradouro'>
                        <TextField
                          required
                          label={i18n._(t`Logradouro`)}
                          name='address'
                          onChange={handleChange}
                          value={values.address}
                          helperText={errors.address}
                          error={Boolean(errors.address)}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid='mkt-input-numero'>
                        <TextField
                          required
                          label={i18n._(t`Número`)}
                          name='number'
                          onChange={handleChange}
                          value={values.number}
                          helperText={errors.number}
                          error={Boolean(errors.number)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item container xs={12} md={12} spacing={2}>
                      <Grid item xs={6} data-testid='mkt-input-complemento'>
                        <TextField
                          label={i18n._(t`Complemento`)}
                          name='complementary_address'
                          onChange={handleChange}
                          value={values.complementary_address}
                        />
                      </Grid>
                      <Grid item xs={6} data-testid='mkt-input-bairro'>
                        <TextField
                          required
                          label={i18n._(t`Bairro`)}
                          name='marketplaceNeighborhood'
                          onChange={handleChange}
                          value={values.marketplaceNeighborhood}
                          helperText={errors.marketplaceNeighborhood}
                          error={Boolean(errors.marketplaceNeighborhood)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item container xs={12} md={12} spacing={2}>
                      <Grid item xs={4} data-testid='mkt-input-estado'>
                        <TextField
                          required
                          label={i18n._(t`Estado`)}
                          name='merchantState'
                          onChange={handleChange}
                          value={values.merchantState}
                          helperText={errors.merchantState}
                          error={Boolean(errors.merchantState)}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid='mkt-input-cidade'>
                        <TextField
                          required
                          label={i18n._(t`Cidade`)}
                          name='marketplaceCity'
                          onChange={handleChange}
                          value={values.marketplaceCity}
                          helperText={errors.marketplaceCity}
                          error={Boolean(errors.marketplaceCity)}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid='mkt-input-country'>
                        <TextField
                          required
                          label={i18n._(t`País`)}
                          name='marketplaceCountry'
                          onChange={handleChange}
                          value={values.marketplaceCountry}
                          helperText={errors.marketplaceCountry}
                          error={Boolean(errors.marketplaceCountry)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item container xs={12} md={12} spacing={2}>
                      <Grid item xs={6} data-testid='mkt-input-telefone'>
                        <TextField
                          required
                          label={i18n._(t`Telefone`)}
                          name='phone_number'
                          onChange={handleChange}
                          value={values.phone_number}
                          helperText={errors.phone_number}
                          error={Boolean(errors.phone_number)}
                        />
                      </Grid>
                      <Grid item xs={6} data-testid='mkt-input-telefone-opcional'>
                        <TextField
                          label={i18n._(t`Telefone Adicional (opcional)`)}
                          name='additional_phone_number'
                          onChange={handleChange}
                          value={values.additional_phone_number}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} data-testid='mkt-input-gateway'>
                      <TextField
                        label={i18n._(t`Escolha o Gateway`)}
                        name='defaultGateway'
                        select
                        onChange={handleChange}
                        value={values.defaultGateway}
                        helperText={errors.defaultGateway}
                        error={Boolean(errors.defaultGateway)}
                      >
                        <MenuItem key={'NoPaymentGateway'} value={'NoPaymentGateway'} data-testid='mkt-option-gateway'>
                          NoPaymentGateway
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  <br />
                  <Box width={1} display='flex' justifyContent='center' py='25px'>
                    <Button
                      disabled={loadingMarketplaceEdit}
                      startIcon={loadingMarketplaceEdit && <CircularProgress color='inherit' size={16} />}
                      type='submit'
                      color='primary'
                      variant='contained'
                      fullWidth
                      data-testid='mkt-button-salvar'
                    >
                      {i18n._(t`Salvar`)}
                    </Button>
                  </Box>
                </Box>
              </Fragment>
            )
          }}
        </FormControl>
      </Box>
    )
  },
  () => true,
)
export { MarketplaceForm }
