import React from 'react'

import { default as ButtonMaterial } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { styled } from '@mui/material/styles'

const StyledButton = styled(ButtonMaterial)({
  display: 'block',
})

export interface SubMenuButtonProps {
  Icon: any
  label: string
  name: string
  selected?: boolean
  onClick?: (name: string) => void
}

const SubMenuButton: React.FC<SubMenuButtonProps> = ({ Icon, label, selected, name, onClick }: SubMenuButtonProps) => {
  const clicked = () => {
    if (selected) return

    if (onClick) onClick(name)
  }

  const variant = selected ? 'contained' : 'outlined'

  return (
    <StyledButton
      data-testid={`seller-button-${name}`}
      data-selected={selected}
      variant={variant}
      color='success'
      onClick={clicked}
    >
      <Box mb='2px'>
        <Icon />
      </Box>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Typography variant='subtitle2'>{label}</Typography>
      </Box>
    </StyledButton>
  )
}

export { SubMenuButton }
