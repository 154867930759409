import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import { FormControl } from '@Components/formControl'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@Components/button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { CurrencyInput } from '@Components/currency-input'
import { PercentInput } from '@Components/percentageInput'
import { CreditorActions } from '../../../store'
import { RootState } from '../../../store/Store'
import Banklist from '../../../resources/data/listBanksBrasil.json'

const { clear } = CreditorActions

export interface Header {
  method: string
  handleSubmit: (item: any) => void
}

export interface Bank {
  bank_id: number
  bank_code: string
  bank_i_s_p_b: any
  bank_name: string
  bank_i_s_p_b_str: any
}

const CreditorCreationForm: React.FC<Header> = ({ method, handleSubmit }: Header) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { creditorResult } = useSelector((state: RootState) => state.creditor)
  const user = useSelector((state: RootState) => state.user)

  const listBank = Banklist.data

  const [agency_init, agency__init_dv] =
    creditorResult && creditorResult.bank && creditorResult.bank.agency && method !== 'new'
      ? creditorResult.bank.agency.split('-')
      : [null, null]
  const [count_currency, count_currency_dv] =
    creditorResult && creditorResult.bank && creditorResult.bank.account && method !== 'new'
      ? creditorResult.bank.account.split('-')
      : [null, null]

  const [bank_Select, setBankSelect] = useState<Bank>()
  const [credit_method, setCredit_method] = useState<null | string>(
    creditorResult ? creditorResult.crediting_method : null,
  )
  const [type_account, setType_account] = useState<null | string>(creditorResult ? creditorResult.bank.type : null)

  const [openBack, setOpenBack] = useState(false)
  const schemaPlatform = {
    name: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, preencha o campo nome`),
      },
    },
    methodCredit: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione uma opção`),
      },
    },
    receivingPeriod: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione uma opção`),
      },
    },
    receivingDay: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione uma opção`),
      },
    },
  }

  const schemaBank = {
    ...schemaPlatform,
    bankSelect: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione um banco`),
      },
    },
    typeAccount: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione o tipo da conta.`),
      },
    },
    agency: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, digite o numero da agencia`),
      },
    },
    currentAccount: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, digite o numero da conta`),
      },
    },
    currentAccountDv: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, digite o numero`),
      },
    },
  }
  const resetVariables = () => {
    setType_account(null)
    setCredit_method(null)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    if (listBank && creditorResult) {
      setBankSelect(listBank.find((el) => el.bank_id === creditorResult.bank.id))
    }
  }, [listBank])

  useEffect(() => {
    if (method === 'new') {
      dispatch(clear())
      resetVariables()
    }
  }, [method])

  const onSubmitHandler = (values) => {
    let bank = {}
    let editDetails = {}

    if (values.methodCredit === 'BANK' && values.bankSelect && Object.keys(values.bankSelect).length > 0) {
      bank = {
        account: `${values.currentAccount}-${values.currentAccountDv}`,
        account_dv: values.currentAccountDv,
        account_number: values.currentAccount,
        agency: `${values.agency}-${values.agencyDv}`,
        agency_dv: values.agencyDv || null,
        agency_number: values.agency,
        type: values.typeAccount,
        id: values.bankSelect.bank_id || null,
      }
    }

    editDetails = {
      account: creditorResult ? creditorResult.account : null,
      marketplaces: creditorResult ? creditorResult.marketplaces : null,
      operations: creditorResult ? creditorResult.operations : null,
    }

    const dataResult = {
      values: values,
      bank: bank,
      details: editDetails,
    }

    handleSubmit(dataResult)
    setOpenBack(true)
  }

  const validationHandler = (values) => {
    if (values.methodCredit === 'BANK') {
      return schemaBank
    } else {
      return schemaPlatform
    }
  }

  const valueInitReceivingDay = () => {
    let resultDay
    switch (creditorResult?.crediting_frequency) {
      case 'DAILY':
        resultDay = method === 'edit' ? 0 : ''
        return resultDay
      default:
        resultDay = method === 'edit' ? (resultDay = creditorResult?.crediting_frequency_day) : ''
        return resultDay
    }
  }

  return (
    <Box display='flex' width={1} height={1} flexDirection='column' position='relative'>
      <Box style={{ opacity: openBack ? 0 : '' }}>
        <FormControl
          initialValue={{
            name: creditorResult ? creditorResult.name : '',
            bankSelect: bank_Select,
            agency: agency_init,
            agencyDv: agency__init_dv,
            currentAccount: count_currency,
            currentAccountDv: count_currency_dv,
            idAccount: creditorResult ? creditorResult.account.id : null,
            receivingPeriod: creditorResult ? creditorResult.crediting_frequency : '',
            receivingDay: creditorResult ? valueInitReceivingDay() : '',
            methodCredit: credit_method,
            typeAccount: type_account,
            advancementFee: creditorResult && method !== 'new' ? creditorResult.advancement_fee : 0,
            discountRate: creditorResult && method !== 'new' ? creditorResult.discount_rate : 0,
            maximumAdvancementPercentage:
              creditorResult && method !== 'new' ? creditorResult.maximum_advancement_percentage : 0,
          }}
          onValidation={validationHandler}
          onSubmit={onSubmitHandler}
        >
          {(data) => {
            return <CreditorLayout {...data} banklist={listBank} method={method} />
          }}
        </FormControl>
      </Box>
    </Box>
  )
}

const CreditorLayout: React.FC<any> = ({ values, handleChange, errors, banklist, method }: any) => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const commonMask = { placeholder: ' ', autoUnmask: true, showMaskOnFocus: false, showMaskOnHover: false }

  let crediting_day
  const handleSelect = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value } })
  }

  const handlePeriod = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value } })

    if (item.target.value === 'DAILY') {
      handleChange({ target: { name: 'receivingDay', value: 0 } })
    } else {
      handleChange({ target: { name: 'receivingDay', value: '' } })
    }
  }

  const handleDays = (handleChange: (prop: any) => void) => (item) => {
    let valueDays = crediting_day === 0 ? 0 : item.target.value
    handleChange({ target: { name: item.target.name, value: valueDays } })
  }

  const DayMonth = () => {
    let days = {}

    for (let day = 1; day < 29; day++) {
      days[day - 1] = {
        name: day,
        value: day,
      }
    }
    return days
  }

  switch (values.receivingPeriod) {
    case 'DAILY':
      crediting_day = 0
      break
    case 'WEEKLY':
      crediting_day = [
        {
          name: i18n._(t`Segunda-feira`),
          value: 2,
        },
        {
          name: i18n._(t`Terça-feira`),
          value: 3,
        },
        {
          name: i18n._(t`Quarta-feira`),
          value: 4,
        },
        {
          name: i18n._(t`Quinta-feira`),
          value: 5,
        },
        {
          name: i18n._(t`Sexta-feira`),
          value: 6,
        },
      ]
      break
    default:
      crediting_day = DayMonth()
      break
  }

  const contentBank = (item, itemErros, banks) => {
    return (
      <Fragment>
        <Grid mb={2} mt={1} container spacing={2}>
          <Grid item xs={6} data-testid='creditors-input-name'>
            {banklist && (
              <TextField
                name='bankSelect'
                value={item.bankSelect}
                onChange={handleSelect(handleChange)}
                label={i18n._(t`Banco`)}
                helperText={itemErros.bankSelect}
                error={Boolean(itemErros.bankSelect)}
                placeholder={i18n._(t`Escolha um banco`)}
                select
              >
                {banks.map((res, i) => {
                  return (
                    <MenuItem key={`optionBank-${i}-${res.bank_code}`} value={res} data-testid='creditor-bank-options'>
                      {`${res.bank_code} - ${res.bank_name}`}
                    </MenuItem>
                  )
                })}
              </TextField>
            )}
          </Grid>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`Tipo de conta`)}
              name='typeAccount'
              select
              onChange={handleSelect(handleChange)}
              value={item.typeAccount}
              helperText={itemErros.typeAccount}
              error={Boolean(itemErros.typeAccount)}
            >
              <MenuItem key={'method-0'} value={'CA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta corrente`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'DA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta Deposito`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'PA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta Pagamento`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'SA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta Poupança`)}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid mb={2} container spacing={2}>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`Agência`)}
              name='agency'
              onChange={handleChange}
              value={item.agency}
              helperText={itemErros.agency}
              error={Boolean(itemErros.agency)}
              mask
              maskOptions={{ mask: '9', repeat: 10, ...commonMask }}
            />
          </Grid>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`DV`)}
              name='agencyDv'
              onChange={handleChange}
              value={item.agencyDv}
              helperText={itemErros.agencyDv}
              error={Boolean(itemErros.agencyDv)}
              mask
              maskOptions={{ regex: '[a-zA-Z0-9]{3}', ...commonMask }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`Conta Corrente`)}
              name='currentAccount'
              onChange={handleChange}
              value={item.currentAccount}
              helperText={itemErros.currentAccount}
              error={Boolean(itemErros.currentAccount)}
              mask
              maskOptions={{ mask: '9', repeat: 15, ...commonMask }}
            />
          </Grid>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`DV`)}
              name='currentAccountDv'
              onChange={handleChange}
              value={item.currentAccountDv}
              helperText={itemErros.currentAccountDv}
              error={Boolean(itemErros.currentAccountDv)}
              mask
              maskOptions={{ regex: '[a-zA-Z0-9]{3}', ...commonMask }}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
  return (
    <Box display='flex' height={1} flexDirection='column' ml='20px'>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`creditorName`)}
              name='name'
              onChange={handleChange}
              value={values.name}
              helperText={errors.name}
              error={Boolean(errors.name)}
              required
            />
          </Grid>
          <Grid item xs={6} data-testid='creditors-input-methodCredit'>
            <TextField
              label={i18n._(t`Tipo da Conta para Liquidação`)}
              description={i18n._(t`O tipo de conta pode ser interno ou externa`)}
              name='methodCredit'
              select
              onChange={handleSelect(handleChange)}
              value={values.methodCredit}
              helperText={errors.methodCredit}
              error={Boolean(errors.methodCredit)}
              required
            >
              <MenuItem key={'method-0'} value={'ACCOUNT'} data-testid='creditor-method-options'>
                {i18n._(t`Usar conta da plataforma`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'BANK'} data-testid='creditor-method-options'>
                {i18n._(t`Banco`)}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {values.methodCredit === 'BANK' ? contentBank(values, errors, banklist) : ''}
        <br />
        <Grid container spacing={2}>
          {method === 'edit' && (
            <Grid item xs={4} data-testid='creditors-input-idAccount'>
              <TextField
                label={i18n._(t`Id da conta`)}
                name='idAccount'
                onChange={handleChange}
                value={values.idAccount ? values.idAccount : '-'}
                helperText={errors.idAccount}
                error={Boolean(errors.idAccount)}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={method === 'edit' ? 4 : 6} data-testid='creditors-input-receivingPeriod'>
            <TextField
              label={i18n._(t`Periodicidade de Liquidação`)}
              description={i18n._(t`Periodicidade de liquidação que pode ser configurada de diária a mensal`)}
              name='receivingPeriod'
              onChange={handlePeriod(handleChange)}
              select
              value={values.receivingPeriod}
              helperText={errors.receivingPeriod}
              error={Boolean(errors.receivingPeriod)}
              required
            >
              <MenuItem key={'pReceivement-1'} value={'DAILY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Diário`)}
              </MenuItem>
              <MenuItem key={'pReceivement-2'} value={'WEEKLY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Semanal`)}
              </MenuItem>
              <MenuItem key={'pReceivement-3'} value={'BIWEEKLY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Quinzenal`)}
              </MenuItem>
              <MenuItem key={'pReceivement-4'} value={'MONTHLY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Mensal`)}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={method === 'edit' ? 4 : 6} data-testid='creditors-input-receivingDay'>
            <TextField
              label={i18n._(t`Dia de recebimento`)}
              name='receivingDay'
              select
              onChange={handleDays(handleChange)}
              value={values.receivingDay}
              helperText={errors.receivingDay}
              error={Boolean(errors.receivingDay)}
              disabled={!crediting_day}
              required
            >
              {crediting_day ? (
                Object.keys(crediting_day).map((res) => {
                  return (
                    <MenuItem
                      key={crediting_day[res].value}
                      value={crediting_day[res].value}
                      data-testid='creditor-receivingDay-options'
                    >
                      {crediting_day[res].name}
                    </MenuItem>
                  )
                })
              ) : (
                <MenuItem key={0} value={crediting_day === 0 ? 0 : -1} data-testid='creditor-receivingDay-options'>
                  -
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box my='20px'>
          <Typography variant='h6'>{i18n._(t`Dados de antecipação`)}</Typography>
        </Box>
        <Box display='flex' width={1} height={1} flexDirection='row' justifyContent='space-around'>
          <Box width={1 / 3} pr={'20px'} data-testid='creditors-input-advancementFee'>
            <Tooltip
              title={i18n._(t`A taxa de antecipação pode ser Fixa, Percentual ou a combinação de ambos.`)}
              arrow
              placement='top'
            >
              <Box>
                <CurrencyInput
                  lang={language}
                  initialValue={values.advancementFee}
                  TextfieldProps={{
                    label: `${i18n._(t`Tarifa de Antecipação`)}`,
                    name: 'advancementFee',
                  }}
                  onChange={(val) => {
                    handleChange({ target: { name: 'advancementFee', value: val } })
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box width={1 / 3} pr={'20px'} data-testid='creditors-input-discountRate'>
            <Tooltip
              title={i18n._(t`A taxa de desconto pode ser Fixa, Percentual ou a combinação de ambos.`)}
              arrow
              placement='top'
            >
              <Box>
                <PercentInput
                  TextfieldProps={{
                    label: `${i18n._(t`Taxa de desconto`)}`,
                    name: 'discountRate',
                  }}
                  initialValue={values.discountRate}
                  onChange={(val) => {
                    handleChange({ target: { name: 'discountRate', value: val } })
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box width={1 / 3} data-testid='creditors-input-maxPercentage'>
            <Tooltip
              title={i18n._(t`Porcentagem máxima que pode ser antecipada pelo estabelecimento`)}
              arrow
              placement='top'
            >
              <Box>
                <PercentInput
                  TextfieldProps={{
                    label: `${i18n._(t`Porcentagem Máx de antecipação`)}`,
                    name: 'maximumAdvancementPercentage',
                  }}
                  initialValue={values.maximumAdvancementPercentage}
                  onChange={(val) => {
                    handleChange({ target: { name: 'maximumAdvancementPercentage', value: val } })
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box mx='auto' display='flex' justifyContent='center' py='30px'>
        <Button type='submit' color='success' variant='contained' data-testid='creditors-input-salvar'>
          {i18n._(t`salvar`)}
        </Button>
      </Box>
    </Box>
  )
}

export { CreditorCreationForm }
