export const itensStatusCombo = [
  {
    name: 'Pago',
    value: 'PAID',
  },
  {
    name: 'Pendente',
    value: 'PENDING',
  },
  {
    name: 'Agendado',
    value: 'SCHEDULED',
  },
  {
    name: 'Liquidado',
    value: 'SETTLED',
  },
  {
    name: 'Devolvido',
    value: 'RETURNED',
  },
  {
    name: 'Bloqueado',
    value: 'BLOCKED',
  },
  {
    name: 'Adiantada',
    value: 'ADVANCED',
  },
  {
    name: 'Cancelado',
    value: 'CANCELED',
  },
]

export const itensOperation = [
  'SAQUE',
  'PAGAMENTO',
  'TRANSFERENCIA',
  'AJUSTE A CREDITO',
  'AJUSTE A DEBITO',
  'CONSULTA DE SALDO',
  'VENDA DE ITENS',
  'CARGA PRE PAGO',
  'PAGAMENTO ADESAO',
  'VENDA CREDITO A VISTA',
  'VENDA PARCELADA SEM JUROS',
  'VENDA PARCELADA COM JUROS',
  'VENDA VOUCHER',
  'VENDA DEBITO',
]
