import { Dispatch } from 'redux'
import { AuthAPI } from '@pismo/sdk-auth'
import { IError, ISuccess } from '@pismo/sdk-core'
import { interceptor } from '../utils'

export enum SetupEvents {
  GET = 'GET_setup',
  CLEAR_GET = 'CLEAR_GET_setup',
  CHANGE = 'CHANGE_setup',
  CLEAR_CHANGE = 'CLEAR_CHANGE_setup',
}

export interface SetupAction {
  type: SetupEvents
  payload?: any
}

export interface SetupType {
  getResult: boolean | null
  changeResult: boolean | null
}

export const setup: SetupType = {
  getResult: null,
  changeResult: null,
}

export const setupReducer = (state: SetupType = setup, action: SetupAction): SetupType => {
  switch (action.type) {
    case SetupEvents.GET:
      return { ...state, getResult: action.payload }
    case SetupEvents.CLEAR_GET:
      return { ...state, getResult: null }
    case SetupEvents.CHANGE:
      return { ...state, changeResult: action.payload }
    case SetupEvents.CLEAR_CHANGE:
      return { ...state, changeResult: null }
    default:
      return state
  }
}

export interface ISetupActions {
  getSetup: (id: string) => (dispatch: Dispatch) => void
  clearGetResult: () => SetupAction
  changeSetup: (id: string, password: string, passwordConfirmation: string) => (dispatch: Dispatch) => void
  clearChangeResult: () => SetupAction
}

export const SetupActions: ISetupActions = {
  getSetup: (id: string) => async (dispatch: Dispatch) => {
    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: {},
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const resp: ISuccess | IError = await AuthAPIClient.getSetup(id)

    let result: boolean = resp.hasOwnProperty('success')

    dispatch({ type: SetupEvents.GET, payload: result })
  },
  clearGetResult: () => ({ type: SetupEvents.CLEAR_GET }),

  changeSetup: (id: string, password: string, passwordConfirmation: string) => async (dispatch: Dispatch) => {
    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: {},
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const resp: ISuccess | IError = await AuthAPIClient.changeSetup(id, password, passwordConfirmation)

    let result: boolean = resp.hasOwnProperty('success')

    dispatch({ type: SetupEvents.CHANGE, payload: result })
  },
  clearChangeResult: () => ({ type: SetupEvents.CLEAR_CHANGE }),
}
