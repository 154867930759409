import { Box, Tab, Tabs as MuiTabs } from '@mui/material'
import React from 'react'

type Value = {
  label: string
  value: number | string
}

type Props = {
  setTypeView: (value: number) => void
  currentValue?: number | string
  values: Value[]
}

const Tabs = ({ setTypeView, currentValue, values }: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTypeView(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <MuiTabs value={currentValue} onChange={handleChange}>
        {values.map((item, key) => (
          <Tab key={key} value={item.value} label={item.label} />
        ))}
      </MuiTabs>
    </Box>
  )
}

export default Tabs
