/* eslint-disable eqeqeq */
const pt = {
  languageData: {
    plurals: function (n, ord) {
      var s = String(n).split('.'),
        i = s[0]
      if (ord) return 'other'
      return i == 0 || i == 1 ? 'one' : 'other'
    },
  },
  messages: {
    '(demais parcelas)': '(demais parcelas)',
    '(dias de repasse da 1\xB0 parcela)': '(dias de repasse da 1\xB0 parcela)',
    '- N\xE3o informado': '- N\xE3o informado',
    'A receber': 'A receber',
    'A senha deve conter no m\xEDnimo 6 (seis) caracteres': 'A senha deve conter no m\xEDnimo 6 (seis) caracteres',
    'Acesso Restrito': 'Acesso Restrito',
    Afastamento: 'Afastamento',
    Agendado: 'Agendado',
    Agência: 'Ag\xEAncia',
    Analisando: 'Analisando',
    'Período de recebimento': 'Período de recebimento',
    'Antecipa\xE7\xE3o de Receb\xEDveis': 'Antecipa\xE7\xE3o de Receb\xEDveis',
    'Ao vincular um Marketplace, esta conta n\xE3o poder\xE1 ser alterada no futuro.':
      'Ao vincular um Marketplace, esta conta n\xE3o poder\xE1 ser alterada no futuro.',
    'Ao vincular um Seller, esta conta n\xE3o poder\xE1 ser alterada no futuro.':
      'Ao vincular um Seller, esta conta n\xE3o poder\xE1 ser alterada no futuro.',
    Aprovado: 'Aprovado',
    'As senhas devem ser iguais': 'As senhas devem ser iguais',
    Ativo: 'Ativo',
    'Autorization ID': 'Autorization ID',
    Bairro: 'Bairro',
    Banco: 'Banco',
    'Banco Cedente': 'Banco Cedente',
    'Bloqueado + Pendente': 'Bloqueado + Pendente',
    CEP: 'CEP',
    CNES: 'CNES',
    CNPJ: 'CNPJ',
    CRM: 'CRM',
    'Campos adicionais': 'Campos adicionais',
    Cancelado: 'Cancelado',
    'Carregando...': 'Carregando...',
    Carteira: 'Carteira',
    Cedente: 'Cedente',
    Cedentes: 'Cedentes',
    'Chave Maxipago': 'Chave Maxipago',
    Cidade: 'Cidade',
    'Como os clientes v\xE3o comprar nesta loja?': 'Como os clientes v\xE3o comprar nesta loja?',
    Complemento: 'Complemento',
    Conta: 'Conta',
    'Conta Corrente': 'Conta Corrente',
    'Conta Deposito': 'Conta Deposito',
    'Conta Pagamento': 'Conta Pagamento',
    'Conta Poupan\xE7a': 'Conta Poupan\xE7a',
    'Conta corrente': 'Conta corrente',
    Contato: 'Contato',
    Convênio: 'Conv\xEAnio',
    Creditor: 'Creditor',
    Credores: 'Credores',
    'Criar novo creditor': 'Criar novo creditor',
    'C\xF3digo postal inv\xE1lido': 'C\xF3digo postal inv\xE1lido',
    DV: 'DV',
    'Dados de antecipa\xe7\xe3o': 'Configura\xE7\xf5es da antecipa\xE7\xE3o',
    'Dados enviados com sucesso.': 'Dados enviados com sucesso.',
    'Dados salvos com sucesso.': 'Dados salvos com sucesso.',
    'Data de origem': 'Data de origem',
    'Data de recebimento': 'Data de recebimento',
    'Data de vencimento': 'Data de vencimento',
    Deletar: 'Deletar',
    'Desc.': 'Desc.',
    'Dia de recebimento': 'Dia do m\xeas para recebimento',
    'Dia do recebimento': 'Dia do recebimento',
    daysForRepaymentOfTheFirstInstallment: 'Dias de repasse  da primeira parcela',
    transferDays: 'Dias de repasse (demais parcelas)',
    'Dias de repasse da primeira parcela': 'Dias de repasse da primeira parcela',
    'Digite o nome do creditor': 'Digite o nome do creditor',
    'Digite seu convenio': 'Digite seu convenio',
    'Digite sua ag\xEAncia': 'Digite sua ag\xEAncia',
    'Digite sua conta corrente': 'Digite sua conta corrente',
    'Digite uma senha com pelo menos 6 caracteres': 'Digite uma senha com pelo menos 6 caracteres',
    'Digito verificador': 'Digito verificador',
    Diário: 'Di\xE1rio',
    'E-mail': 'E-mail',
    'E-mail inv\xE1lido': 'E-mail inv\xE1lido',
    'E-mail inv\xE1lido.': 'E-mail inv\xE1lido.',
    Ecommerce: 'Ecommerce',
    Endereço: 'Endere\xE7o',
    'Erro inesperado. Por favor tente mais tarde.': 'Erro inesperado. Por favor tente mais tarde.',
    'Erro!': 'Erro!',
    'Escolha a \xE1rea de neg\xF3cio.': 'Escolha a \xE1rea de neg\xF3cio.',
    'Escolha o Gateway': 'Escolha o Gateway',
    'Escolha o cedente': 'Escolha o cedente',
    'Escolha o tipo de programa.': 'Escolha o tipo de programa.',
    'Escolha um banco': 'Escolha um banco',
    'Escolha um banco cedente': 'Escolha um banco cedente',
    'Escolha um banco cedente.': 'Escolha um banco cedente.',
    'Escolha um credor': 'Escolha um credor',
    'Escolha um nome para o novo cedente.': 'Escolha um nome para o novo cedente.',
    Espécie: 'Esp\xE9cie',
    'Esp\xE9cie Documento': 'Esp\xE9cie Documento',
    'Essa transa\xE7\xE3o nao possui detalhes': 'Essa transa\xE7\xE3o nao possui detalhes',
    'Esta conta est\xE1 vinculada \xE0 um {0} e n\xE3o pode ser alterada.': function (a) {
      return ['Esta conta est\xE1 vinculada \xE0 um ', a('0'), ' e n\xE3o pode ser alterada.']
    },
    Estabelecimentos: 'Estabelecimentos',
    Estado: 'Estado',
    'Exibir apenas estabelecimentos vinculados': 'Exibir apenas estabelecimentos vinculados',
    'Falha na socicita\xE7\xE3o. Por favor, tente novamente.':
      'Falha na socicita\xE7\xE3o. Por favor, tente novamente.',
    'Falha no envio dos dados. Por favor, tente novamente mais tarde':
      'Falha no envio dos dados. Por favor, tente novamente mais tarde',
    'Falha!': 'Falha!',
    Fechar: 'Fechar',
    Filtrar: 'Filtrar',
    mdrTransferForm: 'Forma de repasse MDR',
    transferFormOfTheTransaction: 'Forma de repasse da transa\xE7\xE3o',
    'Forma de repasse do MDR': 'Forma de repasse do MDR',
    'Formato n\xE3o suportado.': 'Formato n\xE3o suportado.',
    'ID da conta': 'ID da conta',
    'ID de autoriza\xE7\xE3o': 'ID de autoriza\xE7\xE3o',
    Id: 'Id',
    'Id Maxipago': 'Id Maxipago',
    'Id da conta': 'Id da conta',
    'Informe o bairro.': 'Informe o bairro.',
    'Informe o endere\xE7o.': 'Informe o endere\xE7o.',
    'Informe o nome da empresa.': 'Informe o nome da empresa.',
    'Informe o n\xFAmero.': 'Informe o n\xFAmero.',
    'Inscri\xE7\xE3o Municipal': 'Inscri\xE7\xE3o Municipal',
    'Insira seu nome.': 'Insira seu nome.',
    'Insira um nome': 'Insira um nome',
    Instruções: 'Instru\xE7\xF5es',
    Integral: 'Integral',
    'Item deletado com sucesso.': 'Item deletado com sucesso.',
    Liquidado: 'Liquidado',
    Local: 'Local',
    Logradouro: 'Logradouro',
    MDR: 'MDR',
    Marketplace: 'Marketplace',
    Mensal: 'Mensal',
    Moeda: 'Moeda',
    'M\xE9todo de cr\xE9dito': 'M\xE9todo de cr\xE9dito',
    'Nenhum item encontrado.': 'Nenhum item encontrado.',
    'Nenhum resultado encontrado. Tente fazer outra pesquisa.':
      'Nenhum resultado encontrado. Tente fazer outra pesquisa.',
    Nome: 'Nome',
    'Nome Fantasia': 'Nome Fantasia',
    'Nome da Empresa': 'Nome da Empresa',
    operationName: 'Nome da Opera\xE7\xE3o',
    'Nome da marca': 'Nome da marca',
    'Nome do Marketplace': 'Nome do Marketplace',
    'Nome do contato': 'Nome do contato',
    creditorName: 'Nome do credor',
    'Nome do estabelecimento': 'Nome do estabelecimento',
    'Nome do m\xE9dico': 'Nome do m\xE9dico',
    'Nome do novo cedente': 'Nome do novo cedente',
    'Novo Cedente': 'Novo Cedente',
    'Novo Credor': 'Novo Credor',
    'Novo Marketplace': 'Novo Marketplace',
    'Novo cedente': 'Novo cedente',
    'Novo credor': 'Novo credor',
    'Novo estabelecimento': 'Novo estabelecimento',
    documentNumber: 'Numero do documento',
    'N\xE3o Existem transa\xE7\xF5es para a data iniciada em: {initialDateText}': function (a) {
      return ['N\xE3o Existem transa\xE7\xF5es para a data iniciada em: ', a('initialDateText')]
    },
    'N\xE3o Existem transa\xE7\xF5es para o perido entre: {initialDateText} - {endDateText}': function (a) {
      return ['N\xE3o Existem transa\xE7\xF5es para o perido entre: ', a('initialDateText'), ' - ', a('endDateText')]
    },
    'N\xE3o informado': 'N\xE3o informado',
    'N\xE3o possui taxas e tarifas cadastradas': 'N\xE3o possui taxas e tarifas cadastradas',
    Número: 'N\xFAmero',
    'N\xFAmero de documento inv\xE1lido.': 'N\xFAmero de documento inv\xE1lido.',
    'N\xFAmero de telefone inv\xE1lido.': 'N\xFAmero de telefone inv\xE1lido.',
    'N\xFAmero do documento': 'N\xFAmero do documento',
    'N\xFAmero do terminal POS': 'N\xFAmero do terminal POS',
    'O c\xF3digo do seu estabelecimento na plataforma PISMO \xE9:':
      'O c\xF3digo do seu estabelecimento na plataforma PISMO \xE9:',
    Observações: 'Observa\xE7\xF5es',
    'Ocorreu um erro no servidor. Tente novamente em alguns instantes.':
      'Ocorreu um erro no servidor. Tente novamente em alguns instantes.',
    Operação: 'Opera\xE7\xE3o',
    operations: 'Opera\xE7\xF5es',
    'Ou para o modelo de liquida\xE7\xE3o CNAB, por favor selecione um creditor existente':
      'Ou para o modelo de liquida\xE7\xE3o CNAB, por favor selecione um creditor existente',
    'Ou para o modelo de liquida\xE7\xE3o digital, por favor crie um novo creditor':
      'Ou para o modelo de liquida\xE7\xE3o digital, por favor crie um novo creditor',
    PDV: 'PDV',
    'PORCENTAGEM RAV': 'PORCENTAGEM RAV',
    POS: 'POS',
    'Pagamento obrigat\xF3rio? (Aceite)': 'Pagamento obrigat\xF3rio? (Aceite)',
    Pago: 'Pago',
    installments: 'Parcelado',
    Parcelas: 'Parcelas',
    País: 'Pa\xEDs',
    Pendente: 'Pendente',
    allowCardlessTransactions: 'Permitir transa\xE7\xF5es sem cart\xE3o',
    allowTransactionsOnlyWithDoc: 'Permitir transa\xE7\xF5es usando somente o CPF/CNPJ',
    'Periodicidade de liquida\xE7\xE3o': 'Periodicidade de liquida\xE7\xE3o',
    'Pesquise por usu\xE1rio (e-mail)': 'Pesquise por usu\xE1rio (e-mail)',
    'Pesquise um Marketplace': 'Pesquise um Marketplace',
    'Pesquise um cedente': 'Pesquise um cedente',
    'Pesquise um credor': 'Pesquise um credor',
    'Pesquise um estabelecimento': 'Pesquise um estabelecimento',
    'Por favor selecione uma data': 'Por favor selecione uma data',
    'Por favor, Digite a chave Maxipago': 'Por favor, Digite a chave Maxipago',
    'Por favor, Digite a cidade': 'Por favor, Digite a cidade',
    'Por favor, Digite a inscri\xE7\xE3o municipal': 'Por favor, Digite a inscri\xE7\xE3o municipal',
    'Por favor, Digite o DDD telefone': 'Por favor, Digite o DDD telefone',
    'Por favor, Digite o estado': 'Por favor, Digite o estado',
    'Por favor, Digite o id': 'Por favor, Digite o id',
    'Por favor, Digite o nome do seu bairro': 'Por favor, Digite o nome do seu bairro',
    'Por favor, Digite o nome fantasia': 'Por favor, Digite o nome fantasia',
    'Por favor, Digite o nome raz\xE3o social': 'Por favor, Digite o nome raz\xE3o social',
    'Por favor, Digite o numero do Logradouro': 'Por favor, Digite o numero do Logradouro',
    'Por favor, Digite o pa\xEDs': 'Por favor, Digite o pa\xEDs',
    'Por favor, Digite seu cep': 'Por favor, Digite seu cep',
    'Por favor, Digite seu endere\xE7o': 'Por favor, Digite seu endere\xE7o',
    'Por favor, Digite seu numero de documento': 'Por favor, Digite seu numero de documento',
    'Por favor, Digite um nome contato': 'Por favor, Digite um nome contato',
    'Por favor, Digite um numero correspondente a dias de repasse a demais parcelas':
      'Por favor, Digite um numero correspondente a dias de repasse a demais parcelas',
    'Por favor, Digite um numero correspondente a dias de repasse da primeira parcela':
      'Por favor, Digite um numero correspondente a dias de repasse da primeira parcela',
    selectAnOption: 'Por favor, Selecione uma op\xE7\xE3o',
    'Por favor, digite o numero': 'Por favor, digite o numero',
    'Por favor, digite o numero da agencia': 'Por favor, digite o numero da agencia',
    'Por favor, digite o numero da conta': 'Por favor, digite o numero da conta',
    'Por favor, preencha o campo nome': 'Por favor, preencha o campo nome',
    'Por favor, selecione o tipo da conta.': 'Por favor, selecione o tipo da conta.',
    'Por favor, selecione um banco': 'Por favor, selecione um banco',
    'Por favor, selecione um banco cedente': 'Por favor, selecione um banco cedente',
    'Por favor, selecione uma op\xE7\xE3o': 'Por favor, selecione uma op\xE7\xE3o',
    'Porcentagem M\xE1x de antecipa\xE7\xE3o': 'Porcentagem M\xE1x de antecipa\xE7\xE3o',
    'Porcentagem m\xE1x. de antecipa\xE7\xE3o': 'Porcentagem m\xE1x. de antecipa\xE7\xE3o',
    Quantidade: 'Quantidade',
    'Quarta-feira': 'Quarta-feira',
    'Quinta-feira': 'Quinta-feira',
    Quinzenal: 'Quinzenal',
    'Raz\xE3o Social': 'Raz\xE3o Social',
    Rejeitado: 'Rejeitado',
    Repasse: 'Repasse',
    'Repasse MDR': 'Repasse MDR',
    'Repasse da transa\xE7\xE3o': 'Repasse da transa\xE7\xE3o',
    'Repita a senha': 'Repita a senha',
    'Requisi\xE7\xE3o n\xE3o efetuada. Por favor tente mais tarde':
      'Requisi\xE7\xE3o n\xE3o efetuada. Por favor tente mais tarde',
    Salvar: 'Salvar',
    'Segunda-feira': 'Segunda-feira',
    'Selecionar um creditor existente': 'Selecionar um creditor existente',
    'Selecione a data': 'Selecione a data',
    'Selecione a opera\xE7\xE3o': 'Selecione a opera\xE7\xE3o',
    'Selecione ao menos uma regra:': 'Selecione ao menos uma regra:',
    'Selecione o Status': 'Selecione o Status',
    'Selecione um Marketplace': 'Selecione um Marketplace',
    'Selecione um Seller': 'Selecione um Seller',
    Semanal: 'Semanal',
    successfullyRegisteredData: 'Seus dados foram registrados com sucesso.',
    'Seus dados foram salvos com sucesso.': 'Seus dados foram salvos com sucesso.',
    'Sexta-feira': 'Sexta-feira',
    'Status alterado com sucesso.': 'Status alterado com sucesso.',
    'Status do processo': 'Status do processo',
    'Status registrado.': 'Status registrado.',
    'Suas altera\xE7\xF5es foram registradas com sucesso.': 'Suas altera\xE7\xF5es foram registradas com sucesso.',
    'Sucesso!': 'Sucesso!',
    'TARIFA RAV': 'TARIFA RAV',
    'TAXA RAV': 'TAXA RAV',
    'Tarifa de Antecipa\xE7\xE3o': 'Tarifa de Antecipa\xE7\xE3o (valor fixo)',
    'Tarifa de antecipa\xE7\xE3o': 'Tarifa de antecipa\xE7\xE3o',
    'Tarifa por transa\xE7\xE3o': 'Tarifa por transa\xE7\xE3o',
    'Taxa de desconto': 'Taxa de desconto (percentual)',
    transitionFee: 'Taxa de transa\xE7\xE3o',
    'Taxa de transi\xE7\xE3o': 'Taxa de transi\xE7\xE3o',
    'Taxas e tarifas relacionadas ao marketplace n\xE3o foram encontradas.':
      'Taxas e tarifas relacionadas ao marketplace n\xE3o foram encontradas.',
    Telefone: 'Telefone',
    'Telefone Adicional (opcional)': 'Telefone adicional (opcional)',
    'Telefone II (opcional)': 'Telefone II (opcional)',
    'Telefone principal': 'Telefone principal',
    'Tem certeza que deseja alterar o status?': 'Tem certeza que deseja alterar o status?',
    'Tem certeza que deseja remover este item?': 'Tem certeza que deseja remover este item?',
    'Ter\xE7a-feira': 'Ter\xE7a-feira',
    'Tipo de conta': 'Tipo de conta',
    'Tipo de opera\xE7\xE3o': 'Tipo de opera\xE7\xE3o',
    'Todos os estabelecimentos': 'Todos os estabelecimentos',
    'Total a pagar': 'Total a pagar',
    'Tipo da Conta para Liquida\xE7\xE3o': 'Tipo da Conta para Liquida\xE7\xE3o',
    'Use este n\xFAnero para as configura\xE7\xF5es do seu POS ou PDV.':
      'Use este n\xFAnero para as configura\xE7\xF5es do seu POS ou PDV.',
    Usuários: 'Usu\xE1rios',
    'Vence em': 'Vence em',
    'Vincular conta a um Marketplace': 'Vincular conta a um Marketplace',
    'Vincular conta a um Seller': 'Vincular conta a um Seller',
    'Voc\xEA n\xE3o tem permiss\xE3o para acessar esta \xE1rea.':
      'Voc\xEA n\xE3o tem permiss\xE3o para acessar esta \xE1rea.',
    Webpos: 'Webpos',
    'Workflow status': 'Workflow status',
    'a pagar': 'a pagar',
    agenda: 'Agenda',
    'agenda a receber': 'Agenda',
    'autoriza\xE7\xE3o: {0}': function (a) {
      return ['autoriza\xE7\xE3o: ', a('0')]
    },
    bruto: 'Valor bruto',
    cancelar: 'cancelar',
    condições: 'Condi\xE7\xF5es',
    confirmar: 'confirmar',
    confirmação: 'confirma\xE7\xE3o',
    continuar: 'continuar',
    'c\xF3digo postal': 'C\xF3digo postal',
    data: 'Data',
    'data do recebimento': 'Data do recebimento',
    'data final': 'Data final',
    'data inicial': 'Data inicial',
    'data origem': 'Data origem',
    'data recebimento': 'Data recebimento',
    'data vencimento': 'Data vencimento',
    deletar: 'Deletar',
    desconto: 'Desconto',
    'desconto antecipa\xE7\xE3o': 'Desconto antecipa\xE7\xE3o',
    'desconto taxa': 'Desconto taxa',
    desvincular: 'Desvincular',
    download: 'Download',
    'e-mail': 'E-mail',
    'elo cr\xE9dito': 'elo cr\xE9dito',
    'elo d\xE9bito': 'elo d\xE9bito',
    enviar: 'Enviar',
    estabelecimentos: 'Estabelecimentos',
    'hiper cr\xE9dito': 'hiper cr\xE9dito',
    'hiper d\xE9bito': 'hiper d\xE9bito',
    home: 'Home',
    'id de autoriza\xE7\xE3o': 'id de autoriza\xE7\xE3o',
    incluir: 'incluir',
    líquido: 'Valor l\xEDquido',
    liquidoAReceber: 'Líquido a receber',
    moeda: 'moeda',
    nome: 'nome',
    novo: 'novo',
    'n\xFAmero do documento': 'N\xFAmero do documento',
    observações: 'observa\xE7\xF5es',
    operação: 'Opera\xE7\xE3o',
    outros: 'outros',
    país: 'pa\xEDs',
    próximo: 'pr\xF3ximo',
    receita: 'receita',
    remover: 'remover',
    salvar: 'salvar',
    voltar: 'voltar',
    senha: 'senha',
    status: 'Status',
    'status do processo': 'Status do processo',
    'taxas / tarifas': 'Taxas / Tarifas',
    tipo: 'Tipo',
    'tipo do programa': 'Programa',
    transações: 'Transa\xE7\xF5es',
    'valor bruto': 'Valor bruto',
    'valor l\xEDquido': 'Valor l\xEDquido',
    'valor recebido': 'Valor recebido',
    vincular: 'vincular',
    'N\xdamero do documento de identifica\xE7\xE3o do estabelecimento comercial':
      'N\xdamero do documento de identifica\xE7\xE3o do estabelecimento comercial',
    'Programa do tipo merchant (criado previamente)': 'Programa do tipo merchant (criado previamente)',
    '\xe3rea de neg\xf3cio conforme tabela previamente cadastrada':
      '\xe3rea de neg\xf3cio conforme tabela previamente cadastrada',
    establishmentName: 'Nome do Estabelecimento',
    'O tipo de conta pode ser interno ou externa':
      'O tipo de conta pode ser interno (conta na plataforma Pismo) ou externa (conta em Banco Externo)',
    'Periodicidade de liquida\xE7\xE3o que pode ser configurada de di\xe3ria a mensal':
      'Periodicidade de liquida\xE7\xE3o que pode ser configurada de di\xe3ria a mensal',
    'A taxa de antecipa\xE7\xE3o pode ser Fixa, Percentual ou a combina\xE7\xE3o de ambos.':
      'A taxa de antecipa\xE7\xE3o pode ser Fixa, Percentual ou a combina\xE7\xE3o de ambos.',
    'A taxa de desconto pode ser Fixa, Percentual ou a combina\xE7\xE3o de ambos.':
      'A taxa de desconto pode ser Fixa, Percentual ou a combina\xE7\xE3o de ambos.',
    'Porcentagem m\xe3xima que pode ser antecipada pelo estabelecimento.':
      'Porcentagem m\xe3xima que pode ser antecipada pelo estabelecimento.',
    '{0,date,date0}': function (a) {
      return [a('0', 'date', 'date0')]
    },
    '{0,number,number0}': function (a) {
      return [a('0', 'number', 'number0')]
    },
    '{item,number,number0}': function (a) {
      return [a('item', 'number', 'number0')]
    },
    '{res}': function (a) {
      return [a('res')]
    },
    '\xC1rea de neg\xF3cio': '\xC1rea de neg\xF3cio',
    Detalhes: 'Detalhes',
    sair: 'Sair',
    TotalARreceber: 'Total a receber',
    ValorAAntecipar: 'Valor a antecipar',
    LiquidoAReceber: 'Liquido a receber',
    SolicitarAntecipacao: 'Solicitar antecipação',
    AntecipacaoRealizadaComSucesso: 'Antecipação realizada com sucesso.',
    NaoExistemAntecipacoesRalizaveis: 'Não existem antecipações a serem realizadas.',
    antecipacao: 'Antecipação',
    juros: 'juros',
    mes: 'mês',
    Tarifa: 'Tarifa',
    TaxaEfetivaCET: 'TaxaEfetivaCET',
    ConfirmarAntecipacao: 'Confirmar antecipação',
    ValorLiquidoAReceber: 'ValorLiquidoAReceber',
    datasAntecipacao: 'Datas disponíveis para realizar antecipação',
    selecionePeriodoAntecipacao:
      'Selecione o período desejado utilizando o filtro de seleção acima, ou clique diretamente sobre os ítens do gráfico',
    chartDateFormat: 'dd/MM/yy',
    total: 'Total',
    selecionePeriodo: 'Selecione o período',
    transactionWithoutDetails: 'Essa transação nao possui detalhes',
    transactionsOfTheDay: 'Transações do dia',
    noTransactions: 'Não existem transações para o dia selecionado',
    selectPeriod: 'Selecionar período',
    selectSeller: 'Selecione um Seller',
    genericApiError: 'Ocorreu um erro no servidor. Tente novamente em alguns instantes.',
    mustHaveAtLeastOneEstablishmentLinked: 'O marketplace deve ter pelo menos um estabelecimento vinculado a ele.',
    selectAnEstablishmentToViewTransactions: 'Selecione um estabelecimento para visualisar as transações:',
    dataSuccessfullySaved: 'Dados salvos com sucesso',
    itemDeletedSuccessfully: 'Item excluído com sucesso',
    accountTypeForSettlement: 'Tipo da conta para liquidação',
    platformAccount: 'Conta da plataforma',
  },
}

export default pt
