import { UserActions } from '../store'
import { refreshToken } from '../utils/authHelper'

const interceptor = (client, dispatch) => {
  client.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      // 401 handler
      if (error.response.status === 401) {
        // This check is needed because initially these actions are null
        if (UserActions.clear !== null && UserActions.updateUser !== null) {
          // Makes the refreshToken request, if this request fails the user will be logged out to prevent recursion
          const refresh = await refreshToken(dispatch)

          // Retries the request that failed with an updated token
          error.config.headers.Authorization = `Bearer ${refresh.token}`
          return client.request(error.config)
        }
      }

      // Generic error handler
      return Promise.reject(error)
    },
  )
}

export { interceptor }
