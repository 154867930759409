import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const animation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

export const InfiniteRotate = styled.div`
  animation: ${animation} 4s linear infinite;
`
