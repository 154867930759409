import React from 'react'

import get from 'lodash.get'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Pagination } from '@Components/pagination'
import Fab from '@mui/material/Fab'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Loading from '@Components/loading'
import { NoResults } from './NoResults'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export interface Header {
  title: string
  prop: string
  clickIcon?: (item: any) => void
  funcContent?: (item: any) => void
}

export interface SearchResultsProps {
  header: Header[]
  data:
    | {
        items: { [key: string]: any }[]
        current_page: number
        per_page: number
        total_items: number
        pages: number
      }
    | any
  changePage?: any
  onClick?: (item: any) => void
  loading?: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({
  header,
  data,
  changePage,
  onClick,
  loading = false,
}: SearchResultsProps) => {
  let dNow = new Date()

  const handlerClickItem = (item) => () => {
    if (onClick) onClick(item)
  }

  return (
    <Box p='10px' height={1} className='contentSearch' data-testid='search-page-container'>
      <Paper sx={{ height: '100%' }}>
        <Box display={'flex'} height={1} flexDirection='column'>
          {loading ? (
            <Loading />
          ) : (
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <StyledTableRow>
                    {header?.map((el, index) => (
                      <StyledTableCell
                        key={`${el?.prop ? el?.prop + 3 + dNow?.getTime() : ''}-${index + dNow?.getTime()}`}
                      >
                        {el?.title}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {data?.items?.map((item, index) => (
                    <StyledTableRow
                      key={`${item[header[0].prop] ? item[header[0].prop] : ''}-${index + dNow.getTime()}`}
                      hover
                      onClick={handlerClickItem(item)}
                      data-testid={`search-result-${index}`}
                    >
                      {header.map((k, i) => (
                        <StyledTableCell key={`-i${dNow.getTime() + i * 3}`}>
                          {k.funcContent && k.funcContent(item)}
                          {get(item, k.prop)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {data && !data?.items?.length && <NoResults />}
          {data?.pages > 1 && (
            <Box display='flex' py='1.25rem' justifyContent='center'>
              <Pagination pages={data?.pages} onChange={changePage} disabled={loading} />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

interface IArrows {
  className?: string
  onClick?: () => void
  disabled?: boolean
}
export const Prev: React.FC<IArrows> = (props) => (
  <Fab size='small' {...props} variant='circular'>
    <ArrowForwardIosIcon style={{ transform: 'rotate(180deg)' }} />
  </Fab>
)

export const Next: React.FC<IArrows> = (props) => (
  <Fab size='small' {...props} variant='circular'>
    <ArrowForwardIosIcon />
  </Fab>
)

export { SearchResults }
