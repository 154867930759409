import React, { useEffect, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import { SearchResults } from '@Components/search/SearchResults'

import { MarketplaceConditionsActions } from '../../../../store'
import Button from '@mui/material/Button'
import { RootState } from '../../../../store/Store'

const { getListCreditor, setListCreditorError } = MarketplaceConditionsActions

const ConditionsList: React.FC = () => {
  const { id } = useParams<any>()

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { marketplaceCreditorsList, errorListCreditors, loading } = useSelector(
    (state: RootState) => state.marketplaceConditions,
  ) as any
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(getListCreditor(id, user))
  }, [])

  useEffect(() => {
    if (errorListCreditors.error) {
      enqueueSnackbar(errorListCreditors.message, { variant: 'error' })
      dispatch(setListCreditorError(false))
    }
  }, [errorListCreditors])

  const filterListCreditor = () => {
    let result = {
      current_page: 1,
      per_page: 1,
      total_items: 1,
      pages: 1,
      idMarketplace: id,
      items: marketplaceCreditorsList?.items,
    }

    return result
  }

  const menuClicked = (item) => {
    history.push(`/marketplace/edit/${id}/conditions/TypeOperations/${item.creditor_id}`)
  }
  const newClicked = () => {
    history.push(`/marketplace/edit/${id}/conditions/new/TypeOperations`)
  }

  return (
    <Box width={1} height={1} display='flex' flexDirection='column'>
      <Fragment>
        <Box display='flex' margin={'20px 0 10px 10px'}>
          <Box width='150px'>
            <Button color='success' variant='contained' onClick={newClicked} data-testid='button-new-item'>
              {i18n._(t`novo`)}
            </Button>
          </Box>
        </Box>
        <SearchResults
          header={[{ title: i18n._(t`creditorName`), prop: 'creditor_name' }]}
          data={!!marketplaceCreditorsList ? filterListCreditor() : []}
          onClick={menuClicked}
          loading={loading}
        />
      </Fragment>
    </Box>
  )
}

export { ConditionsList }
