import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  marginBottom: '0px',
  padding: '.6rem 1rem',
  textTransform: 'capitalize',
  color: theme.palette.success.dark,
}))

interface ToolbarButtonProps {
  Icon: React.ReactNode | React.FC
  label: string
  selected?: boolean
  iconMode?: boolean
  onClick?: () => void
  testid?: string
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  Icon,
  label,
  selected,
  iconMode,
  onClick,
  testid = '',
}: ToolbarButtonProps) => {
  const clickHandler = () => (onClick ? onClick() : null)
  const variant = selected ? 'contained' : 'text'

  return (
    <Box width='90%' mx='auto' onClick={clickHandler}>
      <StyledButton data-testid={testid} fullWidth variant={variant}>
        {!iconMode ? (
          <>
            {Icon}
            <Box ml='15px'>
              <Typography>{label}</Typography>
            </Box>
          </>
        ) : null}
      </StyledButton>
    </Box>
  )
}

export { ToolbarButton }
