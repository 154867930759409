import { Dispatch } from 'redux'
import { mountAuthHeaders, interceptor } from '../utils'
import { IError } from '@pismo/sdk-core'
import { IGet, IMerchantResult, Merchants } from 'src/resources/merchants'

export enum SellerTransactionEvents {
  GET_TRANSACTION = 'GET_TRANSACTION',
  TRANSACTION_RESULT = 'TRANSACTION_RESULT',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  CLEAR_ITEMS = 'CLEAR_ITEMS',
  LOADING_COMBO = 'LOADING_COMBO',
  ERROR_COMBO = 'ERROR_COMBO',
}

export interface SellerTransactionAction {
  type: SellerTransactionEvents
  payload?: any
}

export interface SellerTransaction {
  term: string
  loading: boolean
  loadingCombo: boolean
  transactionResult: IMerchantResult | any
  paginationLoading: boolean
  error: string | null
  errorCombo: string | null
}

export const sellerTransaction: SellerTransaction = {
  term: '',
  loading: false,
  loadingCombo: false,
  transactionResult: null,
  paginationLoading: false,
  error: null,
  errorCombo: null,
}

export const sellerTransactionReducer = (
  state: SellerTransaction = sellerTransaction,
  action: SellerTransactionAction,
) => {
  switch (action.type) {
    case SellerTransactionEvents.GET_TRANSACTION:
      return {
        ...state,
        transactionResult: action.payload,
        loading: false,
      }
    case SellerTransactionEvents.TRANSACTION_RESULT:
      return {
        ...state,
        transactionResult: action.payload,
        loading: false,
        loadingCombo: false,
        errorCombo: false,
      }
    case SellerTransactionEvents.LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SellerTransactionEvents.LOADING_COMBO:
      return {
        ...state,
        loadingCombo: action.payload,
      }
    case SellerTransactionEvents.ERROR_COMBO:
      return {
        ...state,
        errorCombo: action.payload,
      }
    case SellerTransactionEvents.ERROR:
      return {
        ...state,
        error: action.payload?.error?.message,
        loading: false,
      }
    case SellerTransactionEvents.CLEAR_ITEMS:
      return {
        ...state,
        term: '',
        loading: false,
        loadingCombo: false,
        transactionResult: null,
        paginationLoading: false,
        error: null,
        errorCombo: null,
      }
    default:
      return state
  }
}

export interface ISellerTransactionActions {
  // setTerm: (term: string) => (dispatch: Dispatch) => void
  transactionSeller: (data: any, props?: IGet) => (dispatch: Dispatch) => void
  // sellerPagination: (page: number, credentials: any) => (dispatch: Dispatch) => void
  clear: () => SellerTransactionAction
  updateStatusTransactions: (data: any, props?: IGet) => (dispatch: Dispatch) => void
}

export const SellerTransactionActions: ISellerTransactionActions = {
  transactionSeller: (data: any, props?: IGet) => async (dispatch: Dispatch) => {
    let dataParams
    const page = props && props.page ? { page: props.page } : { page: 1 }
    dataParams = { ...props, ...page }

    dispatch({ type: SellerTransactionEvents.LOADING, payload: true })

    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(data.credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const response = await MerchantsClient.getTransactions(data.idMerchant, dataParams)
      dispatch({ type: SellerTransactionEvents.TRANSACTION_RESULT, payload: response.data })
    } catch (err: any) {
      dispatch({ type: SellerTransactionEvents.ERROR, payload: err?.error?.message })
    }
  },

  updateStatusTransactions: (data: any, props?: IGet) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(data.credential),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response = await MerchantsClient.updateStatusTransaction(data.data.merchantId, data.data.id, data.data)

    if ((response as IError).error) {
      dispatch({ type: SellerTransactionEvents.ERROR_COMBO, payload: true })
      return
    }

    dispatch({ type: SellerTransactionEvents.LOADING_COMBO, payload: true })
  },

  clear: () => ({ type: SellerTransactionEvents.CLEAR_ITEMS }),
}
