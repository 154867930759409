import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material/styles'
import { NoResults } from '@Components/search/NoResults'

import { SellerTaxActions } from '../../../store'
import TextField from '@Components/textField'

import Hipercard from '../../../assets/hipercard.svg'
import Visa from '../../../assets/visa.svg'
import VisaElectron from '../../../assets/visaelectron.svg'
import Elo from '../../../assets/elo.svg'
import Maestro from '../../../assets/maestro.svg'
import Master from '../../../assets/mastercard.svg'
import Amex from '../../../assets/amex.svg'
import OtherCard from '../../../assets/others.svg'
import { RootState } from '../../../store/Store'
import Loading from '@Components/loading'
import { tableCellClasses } from '@mui/material/TableCell'
import { useSnackbar } from 'notistack'
import { formatToDefaultCurrency } from 'src/utils/number'
import { IMarketplace } from 'src/resources/merchants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const { getTax, getMarketplaces, getCreditorsOperations, clearAll } = SellerTaxActions

const SellerTax = ({ seller }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const {
    tax,
    taxError,
    marketplacesError,
    marketplaces,
    creditorsOperations,
    creditorsOperationsError,
    networkList,
    loadingTax,
  } = useSelector((state: RootState) => state.sellerTax)
  const user = useSelector((state: RootState) => state.user)
  const { enqueueSnackbar } = useSnackbar()
  const cardsIcons = {
    amex: { Icon: Amex, label: 'amex' },
    'elo credito': { Icon: Elo, label: i18n._(t`elo crédito`) },
    'elo debito': { Icon: Elo, label: i18n._(t`elo débito`) },
    'hipercard debito': { Icon: Hipercard, label: i18n._(t`hiper débito`) },
    'hipercard credito': { Icon: Hipercard, label: i18n._(t`hiper crédito`) },
    'visa electron': { Icon: VisaElectron, label: 'visa electron' },
    'visa credito': { Icon: Visa, label: 'visa' },
    visa: { Icon: Visa, label: 'visa' },
    'mastercard credito': { Icon: Master, label: 'master' },
    'mastercard maestro': { Icon: Maestro, label: 'maestro' },
    others: { Icon: OtherCard, label: i18n._(t`outros`) },
  }

  const baseHead = [
    { title: i18n._(t`Operação`), prop: 'operation_type_description' },
    { title: i18n._(t`Parcelas`), prop: 'installments' },
    { title: i18n._(t`Taxa de transação`), prop: 'transaction_fee' },
    {
      title: (
        <Fragment key='Afastamento'>
          {i18n._(t`Afastamento`)}
          <br />
          <span>{i18n._(t`(dias de repasse da 1° parcela)`)}</span>
        </Fragment>
      ),
      prop: 'first_installment_days_to_payment',
    },
    {
      title: (
        <Fragment key='Repasse'>
          {i18n._(t`Repasse`)}
          <br />
          <span>{i18n._(t`(demais parcelas)`)}</span>
        </Fragment>
      ),
      prop: 'n_installment_days_to_payment',
    },
    { title: i18n._(t`Repasse MDR`), prop: 'matched_mdr' },
    { title: i18n._(t`Repasse da transação`), prop: 'matched_settlement' },
  ]

  const dispatch = useDispatch()

  const [marketplaceSelected, setMarketplaceSelected] = useState<IMarketplace | null>(null)
  const [operationsHead, setOperationsHead] = useState<any>(null)

  useEffect(() => {
    return () => {
      dispatch(clearAll())
    }
  }, [])

  useEffect(() => {
    if (networkList) {
      const cardsHead: any[] = []

      Object.values(networkList).map((v) => {
        let card = cardsIcons[String(v).toLowerCase()]
        if (!card) card = cardsIcons['others']
        cardsHead.push({
          title: (
            <>
              <Box component='span' height='35px' display='flex' justifyContent='center'>
                <img src={card.Icon} alt='Card' />
              </Box>
              <Typography component='span' variant='caption'>
                {card.label}
              </Typography>
            </>
          ),
          prop: v,
          isNetwork: true,
        })
      })

      setOperationsHead([...baseHead, ...cardsHead])
    }
  }, [networkList])

  useEffect(() => {
    if (!tax && !taxError) {
      dispatch(getTax(seller.id, user))
    }
  }, [tax, taxError])

  useEffect(() => {
    if (tax && !taxError && !marketplaces) {
      dispatch(getMarketplaces(seller.id, user))
    }
  }, [tax, taxError, marketplaces])

  useEffect(() => {
    if (marketplaces && marketplaces?.items?.length > 0 && !marketplaceSelected) {
      setMarketplaceSelected(marketplaces.items[0] as IMarketplace)
    }
  }, [marketplaces, marketplaceSelected])

  useEffect(() => {
    if (marketplaceSelected) {
      dispatch(getCreditorsOperations((marketplaceSelected as IMarketplace).id, seller.id, user))
    }
  }, [marketplaceSelected])

  useEffect(() => {
    if (!!marketplacesError) {
      enqueueSnackbar(marketplacesError?.toString() || i18n._(t`genericApiError`), {
        variant: 'error',
      })
    }
  }, [marketplacesError])

  if (!loadingTax && (!tax || taxError))
    return (
      <Box display='flex' height={1}>
        <NoResults message={i18n._(t`Não possui taxas e tarifas cadastradas`)} />
      </Box>
    )

  const getItems = () => {
    return !!marketplaces?.items?.length
      ? marketplaces?.items.map((item) => ({ ...item, label: item.name }))
      : [{ label: '' }]
  }

  const changeMarketplace = (_, value) => {
    setMarketplaceSelected(value)
  }

  return loadingTax ? (
    <Loading />
  ) : (
    <Box data-testid='tax-page-container'>
      {marketplaces && marketplaces?.items?.length > 0 && (
        <Box display='flex' justifySelf='flex-start' px='20px' mt='20px' flexDirection='column' data-type='tax'>
          <Box my='20px'>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={getItems()}
              renderInput={(params) => <TextField {...params} label='Marketplace' />}
              onChange={changeMarketplace}
              //@ts-ignore
              value={{ ...marketplaceSelected, label: marketplaceSelected?.name }}
            />
          </Box>
          {creditorsOperations && !creditorsOperationsError ? (
            <Paper>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      {operationsHead &&
                        operationsHead.map((el, index) => (
                          <StyledTableCell key={index}>
                            <Typography variant='subtitle2' align='center'>
                              {el.title}
                            </Typography>
                          </StyledTableCell>
                        ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {creditorsOperations &&
                      Object.keys(creditorsOperations).map((k, indexK) =>
                        Object.keys(creditorsOperations[k]).map((i, indexI) =>
                          Object.keys(creditorsOperations[k][i]).map((j, indexJ) => {
                            let first
                            for (let l in creditorsOperations[k][i][j]) {
                              first = creditorsOperations[k][i][j][l]
                              break
                            }
                            return (
                              <StyledTableRow key={`${indexK}-${indexI}-${indexJ}`}>
                                {operationsHead &&
                                  operationsHead.map((head, hindex) => {
                                    let value = ''

                                    switch (head.prop) {
                                      case 'transaction_fee':
                                        value = formatToDefaultCurrency(Number(first[head.prop]))
                                        break
                                      case 'matched_mdr':
                                        value = first[head.prop] ? i18n._(t`Parcelado`) : i18n._(t`Integral`)
                                        break
                                      case 'matched_settlement':
                                        value = first[head.prop] ? i18n._(t`Parcelado`) : i18n._(t`Integral`)
                                        break
                                      case 'installments':
                                        value = j === 'null' ? '-' : j
                                        break
                                      case 'operation_type_description':
                                      case 'n_installment_days_to_payment':
                                      case 'first_installment_days_to_payment':
                                        value = first[head.prop]
                                        break
                                      default:
                                        const p = head.prop === 'others' ? 'null' : head.prop
                                        const co = creditorsOperations[k][i][j][p]
                                        value = co ? `${Number(co.mdr * 100).toFixed(2)}%` : '-'
                                    }

                                    return (
                                      <StyledTableCell key={`${head.prop}-${hindex}`}>
                                        <Typography variant='subtitle2' align='center'>
                                          {value}
                                        </Typography>
                                      </StyledTableCell>
                                    )
                                  })}
                              </StyledTableRow>
                            )
                          }),
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Box px='20px' my='30px' data-type='tax' data-testid='operations-no-result'>
              <NoResults
                message={i18n._(t`Taxas e tarifas relacionadas ao marketplace não foram encontradas.`)}
                inline
              />
            </Box>
          )}
        </Box>
      )}
      <Box
        display='flex'
        flexDirection='column'
        px='20px'
        mt='40px'
        pb='50px'
        data-type='tax'
        data-testid='tax-container'
      >
        <Typography color={'primary'} variant='h6'>
          {i18n._(t`Antecipação de Recebíveis`)}
        </Typography>
        <Box display='flex' justifyContent='space-around' mt='20px'>
          <Card>
            <CardContent>
              <Box display='flex' flexDirection={'column'} alignItems='center' p={2}>
                <Typography variant='body1'>{i18n._(t`TAXA RAV`)}</Typography>
                <Typography variant='h4'>{tax && tax.rav.tax * 100} %</Typography>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box display='flex' flexDirection={'column'} alignItems='center' p={2}>
                <Typography variant='body1'>{i18n._(t`TARIFA RAV`)}</Typography>
                <Typography variant='h4'>{tax && formatToDefaultCurrency(Number(tax.rav.tariff))}</Typography>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box display='flex' flexDirection={'column'} alignItems='center' p={2}>
                <Typography variant='body1'>{i18n._(t`PORCENTAGEM RAV`)}</Typography>
                <Typography variant='h4'>{tax && tax.rav.percentage * 100} %</Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export { SellerTax }
