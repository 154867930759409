import { Dispatch } from 'redux'
import { mountAuthHeaders, interceptor } from '../utils'
import { IError, ISuccess } from '@pismo/sdk-core'
import { AWS } from '@pismo/sdk-aws'
import { ILinkedItem, Marketplace } from 'src/resources/marketplace'

export enum MarketplaceEstablishmentsEvents {
  GET_LINKED_LIST = 'GET_LINKED_LIST_marketplaceEstablishments',
  TOGGLE_LINK_ERROR = 'TOGGLE_LINK_ERROR_marketplaceEstablishments',
  UPLOAD_RESULT = 'UPLOAD_RESULT_marketplaceEstablishments',
  CLEAR_UPLOAD_RESULT = 'CLEAR_UPLOAD_RESULT_marketplaceEstablishments',
}

export interface MarketplaceEstablishmentsAction {
  type: MarketplaceEstablishmentsEvents
  payload?: any
}

export interface MarketplaceEstablishments {
  linkedList: ILinkedItem[] | null
  linkError: boolean | null
  uploadResult: boolean | null
}

export const marketplaceEstablishments: MarketplaceEstablishments = {
  linkedList: null,
  linkError: null,
  uploadResult: null,
}

export const marketplaceEstablishmentsReducer = (
  state: MarketplaceEstablishments = marketplaceEstablishments,
  action: MarketplaceEstablishmentsAction,
): MarketplaceEstablishments => {
  switch (action.type) {
    case MarketplaceEstablishmentsEvents.GET_LINKED_LIST:
      return { ...state, linkedList: action.payload }
    case MarketplaceEstablishmentsEvents.TOGGLE_LINK_ERROR:
      return { ...state, linkError: action.payload }
    case MarketplaceEstablishmentsEvents.UPLOAD_RESULT:
      return { ...state, uploadResult: action.payload }
    case MarketplaceEstablishmentsEvents.CLEAR_UPLOAD_RESULT:
      return { ...state, uploadResult: null }
    default:
      return state
  }
}

export interface IMarketplaceEstablishmentsActions {
  getLinkedItem: (marketplaceID: number, credentials: any) => (dispatch: Dispatch) => void
  linkMerchant: (marketplaceID: number, merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  unlinkMerchant: (marketplaceID: number, merchantID: number, credentials: any) => (dispatch: Dispatch) => void
  clearToggleLinkError: () => MarketplaceEstablishmentsAction
  uploadFile: (file: File, bucket: string, credentials: any) => (dispatch: Dispatch) => void
  clearUploadResult: () => MarketplaceEstablishmentsAction
}

export const MarketplaceEstablishmentsActions: IMarketplaceEstablishmentsActions = {
  getLinkedItem: (marketplaceID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceEstablishmentsEvents.GET_LINKED_LIST, payload: null })
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const response: ILinkedItem[] | IError = await MarketplaceClient.getLinkedList(marketplaceID)

    if ((response as IError).error) {
      return
    }

    dispatch({ type: MarketplaceEstablishmentsEvents.GET_LINKED_LIST, payload: response })
  },

  linkMerchant: (marketplaceID: number, merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const resp: ISuccess | IError = await MarketplaceClient.linkMerchant(marketplaceID, merchantID)

    let result: boolean = resp.hasOwnProperty('success')

    if (result) {
      MarketplaceEstablishmentsActions.getLinkedItem(marketplaceID, credentials)(dispatch)
    } else {
      dispatch({ type: MarketplaceEstablishmentsEvents.TOGGLE_LINK_ERROR, payload: true })
    }
  },

  unlinkMerchant: (marketplaceID: number, merchantID: number, credentials: any) => async (dispatch: Dispatch) => {
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const resp: ISuccess | IError = await MarketplaceClient.unlinkMerchant(marketplaceID, merchantID)

    let result: boolean = resp.hasOwnProperty('success')

    if (result) {
      MarketplaceEstablishmentsActions.getLinkedItem(marketplaceID, credentials)(dispatch)
    } else {
      dispatch({ type: MarketplaceEstablishmentsEvents.TOGGLE_LINK_ERROR, payload: true })
    }
  },

  clearToggleLinkError: () => ({ type: MarketplaceEstablishmentsEvents.TOGGLE_LINK_ERROR, payload: null }),

  uploadFile: (file: File, bucket: string, credentials: any) => async (dispatch: Dispatch) => {
    const AWSClient = AWS({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = AWSClient.getClient()

    interceptor(client, dispatch)

    const upload: ISuccess | IError = await AWSClient.upload(bucket, file)

    if ((upload as IError).error) {
      return dispatch({ type: MarketplaceEstablishmentsEvents.UPLOAD_RESULT, payload: false })
    }

    dispatch({ type: MarketplaceEstablishmentsEvents.UPLOAD_RESULT, payload: true })
  },

  clearUploadResult: () => ({ type: MarketplaceEstablishmentsEvents.CLEAR_UPLOAD_RESULT }),
}
