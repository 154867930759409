import React, { useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import { MarketPlaceActions } from '../../../store'
import { MarketplaceForm } from '../MarketplaceForm'
import { RootState } from '../../../store/Store'
import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import AppBar from '@Components/appBar'
import { useSnackbar } from 'notistack'

const { updateMarketplace, clearMarketplace, setMarketplace, clearMarketplaceError } = MarketPlaceActions

export interface marketplaceUser {
  method: string
}

const MarketplaceProfile: React.FC<marketplaceUser> = memo(
  ({ method }: marketplaceUser) => {
    const history = useHistory()
    const { id } = useParams<any>()
    const dispatch = useDispatch()
    const { i18n } = useSelector((state: RootState) => state.translation)
    const { marketplaceEditResult, marketplaceErrorMessage, marketplaceEditSuccess } = useSelector(
      (state: RootState) => state.marketplaceEdit,
    )
    const user = useSelector((state: RootState) => state.user)
    const { enqueueSnackbar } = useSnackbar()

    const backToList = () => {
      history.push('/marketplace')
    }

    useEffect(() => {
      if (method === 'new') {
        dispatch(clearMarketplace())
      }
    }, [])

    useEffect(() => {
      return () => {
        dispatch(clearMarketplaceError())
      }
    }, [])

    useEffect(() => {
      if (marketplaceEditSuccess) {
        enqueueSnackbar(i18n._(t`Seus dados foram registrados com sucesso.`), { variant: 'success' })
        if (method === 'new') {
          dispatch(clearMarketplace())
          if (marketplaceEditResult.id) {
            history.push(`/marketplace/edit/${marketplaceEditResult.id}/home`)
          }
        }
      } else if (!!marketplaceErrorMessage) {
        enqueueSnackbar(marketplaceErrorMessage, {
          variant: 'error',
        })
      }
    }, [marketplaceEditSuccess, marketplaceErrorMessage])

    const onSubmit = (data) => {
      const { marketplaceConfig } = data
      const result = {
        document_number: data.document_number,
        registration: data.registration,
        registration2: data.registration2 || '',
        brand_name: data.brand_name,
        marketplace_name: data.marketplace_name,
        address: data.address,
        number: Number(data.number),
        complementary_address: data.complementary_address || '',
        neighborhood: data.marketplaceNeighborhood,
        zip_code: data.zip_code,
        city: data.marketplaceCity,
        state: data.merchantState,
        country: data.marketplaceCountry,
        contact: data.contact,
        email: data.email,
        phone_number: data.phone_number,
        additional_phone_number: data.additional_phone_number || '',
        financial_email: data.financial_email || '',
        marketplace_configs: marketplaceConfig,
        additional_phone_number_zone: data.additional_phone_number || '',
      }

      if (method === 'edit') {
        const resultEdit = {
          ...result,
          id: marketplaceEditResult ? marketplaceEditResult.id : '',
        }
        dispatch(updateMarketplace(id, resultEdit, user))
      } else {
        dispatch(setMarketplace(result, user))
      }
    }

    const Container = method === 'new' ? AppBar : Box
    const PageBox = method === 'new' ? Page : Box
    const PageContentBox = method === 'new' ? PageContent : Box
    const title = method === 'new' ? i18n._(t`Novo Marketplace`) : i18n._(t`Marketplace`)
    const onBack = method === 'new' ? backToList : undefined

    return (
      <PageBox>
        <Container title={title} back={onBack}></Container>
        <PageContentBox>
          <MarketplaceForm method={method} handleSubmit={onSubmit} />
        </PageContentBox>
      </PageBox>
    )
  },
  () => true,
)

export { MarketplaceProfile }
