import React from 'react'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import { translationReducer } from './Translation'
import { userReducer } from './User'
import { toolbarReducer } from './Toolbar'
import { sellerSearchReducer } from './SellerSearch'
import { sellerTransactionReducer } from './SellerTransaction'
import { transactionDetailsReducer } from './SellerTransactionDetails'
import { mobileToolbarReducer } from './MobileToolbar'
import { sellernewReducer } from './SellerNew'
import { sellerAnticipationReducer } from './SellerAnticipation'
import { creditorsSearchReducer } from './CreditorsSearch'
import { creditorReducer } from './Creditor'
import { sellerReducer } from './Seller'
import { marketplaceSearchReducer } from './MarketplaceSearch'
import { sellerEventReducer } from './SellerEvent'
import { sellerTaxReducer } from './SellerTax'
import { sellerWorkflowReducer } from './SellerWorkflow'
import { marketplaceEditReducer } from './MarketplaceEdit'
import { userscreenReducer } from './Userscreen'
import { setupReducer } from './Setup'
import { marketplaceEstablishmentsReducer } from './MarketplaceEstablishments'
import { marketplaceConditionsReducer } from './MarketplaceConditions'
import { marketplaceScheduleReducer } from './MarketplaceSchedule'

const getLogger = () => (process.env.NODE_ENV === 'development' ? logger : () => (next) => (action) => next(action))
getLogger()

const rootReducer = combineReducers({
  translation: translationReducer,
  user: userReducer,
  toolbar: toolbarReducer,
  mobiletoolbar: mobileToolbarReducer,
  sellerNew: sellernewReducer,
  sellersearch: sellerSearchReducer,
  seller: sellerReducer,
  sellerAnticipation: sellerAnticipationReducer,
  creditorssearch: creditorsSearchReducer,
  creditor: creditorReducer,
  marketplacesearch: marketplaceSearchReducer,
  sellerTransaction: sellerTransactionReducer,
  transactionDetails: transactionDetailsReducer,
  sellerEvent: sellerEventReducer,
  sellerTax: sellerTaxReducer,
  sellerWorkflow: sellerWorkflowReducer,
  marketplaceEdit: marketplaceEditReducer,
  userscreen: userscreenReducer,
  setup: setupReducer,
  marketplaceEstablishments: marketplaceEstablishmentsReducer,
  marketplaceConditions: marketplaceConditionsReducer,
  marketplaceSchedule: marketplaceScheduleReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(
  (state: any, action) => (action.type === 'LOGOUT' ? rootReducer(undefined, action) : rootReducer(state, action)),
  applyMiddleware(thunk, getLogger()),
)

if ((window as any).Cypress) (window as any).store = store

interface StoreProps {
  children: React.ReactNode
}

const Store: React.FC<StoreProps> = ({ children }: StoreProps) => {
  return <Provider store={store}>{children}</Provider>
}

export { Store, store }
