export enum MobileToolbarEvents {
  TOGGLE = 'TOGGLE',
  SET_MAIN_CURRENT = 'SET_MAIN_CURRENT',
}

export interface MobileToolbarAction {
  type: MobileToolbarEvents
  payload?: any
}

export interface MobileToolbar {
  isOpen: boolean
  mainCurrent: string
}

export const mobiletoolbar: MobileToolbar = {
  isOpen: true,
  mainCurrent: '',
}

export const mobileToolbarReducer = (state: MobileToolbar = mobiletoolbar, action: MobileToolbarAction) => {
  switch (action.type) {
    case MobileToolbarEvents.TOGGLE:
      return { ...state, isOpen: action.payload }
    case MobileToolbarEvents.SET_MAIN_CURRENT:
      return { ...state, mainCurrent: action.payload }
    default:
      return state
  }
}

export interface IMobileToolbarActions {
  toggle: (open: boolean) => MobileToolbarAction
  setMainCurrent: (name: string) => MobileToolbarAction
}

export const MobileToolbarActions: IMobileToolbarActions = {
  toggle: (open: boolean) => ({ type: MobileToolbarEvents.TOGGLE, payload: open }),
  setMainCurrent: (name: string) => ({ type: MobileToolbarEvents.SET_MAIN_CURRENT, payload: name }),
}
