import { Dispatch } from 'redux'
import { ISearchResult, Marketplace } from '../resources/marketplace'
import { mountAuthHeaders, interceptor } from '../utils'
import { IError } from '@pismo/sdk-core'

export enum MarketplaceSearchEvents {
  SEARCHRESULT = 'SEARCHRESULT',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export interface MarketplaceSearchAction {
  type: MarketplaceSearchEvents
  payload?: any
}

export interface MarketplaceSearch {
  term: string
  loading: boolean
  searchResult: ISearchResult | null
  paginationLoading: boolean
  error: string | null
}

export const marketplaceSearch: MarketplaceSearch = {
  term: '',
  loading: false,
  searchResult: null,
  paginationLoading: false,
  error: null,
}

export const marketplaceSearchReducer = (
  state: MarketplaceSearch = marketplaceSearch,
  action: MarketplaceSearchAction,
): MarketplaceSearch => {
  switch (action.type) {
    case MarketplaceSearchEvents.SEARCHRESULT:
      return { ...state, searchResult: action.payload, loading: false }
    case MarketplaceSearchEvents.LOADING:
      return { ...state, loading: action.payload }
    case MarketplaceSearchEvents.ERROR:
      return { ...state, error: action.payload?.error?.message, loading: false }
    default:
      return state
  }
}

export interface IMarketplaceSearchActions {
  getMarketplaceList: (credentials: any, props?: any) => (dispatch: Dispatch) => void
}

export const MarketplaceSearchActions: IMarketplaceSearchActions = {
  getMarketplaceList: (credentials: any, props?: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceSearchEvents.LOADING, payload: true })
    const MarketplaceClient = Marketplace({ headers: mountAuthHeaders(credentials) })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const response = await MarketplaceClient.search(props)

    if ((response as IError).error) {
      dispatch({ type: MarketplaceSearchEvents.ERROR, payload: (response as IError).error.message })
      return
    }

    dispatch({ type: MarketplaceSearchEvents.SEARCHRESULT, payload: response })
  },
}
