import { CurrencyCode } from '@Components/world-format/types'

const translation = {
  en: 'en-US',
  es: 'es-ES',
  pt: 'pt-BR',
}

export type Translation = 'en' | 'pt' | 'es'

export const rounds = (num, decimalPlaces) => (Math.round(Number(num) * 100) / 100).toFixed(decimalPlaces)

function formatCurrency(num: number, lang) {
  let literal = '',
    _currency
  const num_str = new Intl.NumberFormat(translation[lang], {
    style: 'currency',
    currency: CurrencyCode.US,
  })
    .formatToParts(num)
    .map((p) => {
      switch (p.type) {
        case 'literal':
          literal = p.value
          break
        case 'currency':
          _currency = p.value
          break
        default:
          return p.value
      }
    })
    .join('')

  return _currency + literal + num_str
}

const formatToDefaultCurrency = (value: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value).replace(/^(\D+)/, '$1 ')

export { formatCurrency, formatToDefaultCurrency }
