import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t, date } from '@lingui/macro'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Button from '@Components/button'

import { SearchResultsTyped, NoResults } from '@Components/search'

import { SellerWorkflowActions } from '../../../store'
import { IMerchantsEvent } from '@pismo/sdk-events'
import { Snackbar } from '@Components/snackbar'

import TextField from '@Components/textField'
import { RootState } from '../../../store/Store'

const {
  clearEvents,
  getEvents,
  getStatuses,
  clearStatuses,
  changeStatus: changeWorkflowStatus,
  clearStatusResult,
} = SellerWorkflowActions

const SellerWorkflow = ({ seller }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { events, statuses, statusResult } = useSelector((state: RootState) => state.sellerWorkflow)
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()

  const [indexStatus, setIndexStatus] = useState(0)
  const [notes, setNotes] = useState('')
  const [saveDisabled, setSaveDisabled] = useState(false)

  const [openFeedback, setOpenfeedback] = useState(false)
  const [successFeedback, setSuccessfeedback] = useState(false)

  const feedbackMessage = [i18n._(t`Status registrado.`), i18n._(t`Falha na socicitação. Por favor, tente novamente.`)]

  const status = {
    APPROVED: i18n._(t`Aprovado`),
    PENDING: i18n._(t`Pendente`),
    REJECTED: i18n._(t`Rejeitado`),
    ANALYSING: i18n._(t`Analisando`),
    CANCELLED: i18n._(t`Cancelado`),
    ACTIVE: i18n._(t`Ativo`),
  }

  useEffect(() => {
    dispatch(getEvents(seller.id, user))
    dispatch(getStatuses(seller.id, user))

    return () => {
      dispatch(clearEvents())
      dispatch(clearStatuses())
    }
  }, [])

  useEffect(() => {
    if (statusResult !== null) {
      const _isSuccess = statusResult
      dispatch(clearStatusResult())

      setSuccessfeedback(_isSuccess)
      setOpenfeedback(true)

      if (_isSuccess) {
        dispatch(getEvents(seller.id, user))
        dispatch(getStatuses(seller.id, user))

        setIndexStatus(0)
        setNotes('')
      }

      setSaveDisabled(false)
    }
  }, [statusResult])

  const changeStatus = (e) => {
    setIndexStatus(e.target.value)
  }

  const changeNotes = (e) => {
    setNotes(e.target.value)
  }

  const saveStatus = () => {
    setSaveDisabled(true)
    dispatch(changeWorkflowStatus(seller.id, statuses[indexStatus], notes, user))
  }

  const closeFeedback = () => {
    setOpenfeedback(false)
  }

  return (
    <Fragment>
      <Box>
        {statuses && statuses.length > 0 && (
          <Box my='20px' px='20px' display='flex' data-testid='edit-container'>
            <Box width={3 / 10}>
              <TextField
                select
                value={indexStatus}
                label={i18n._(t`Workflow status`)}
                onChange={changeStatus}
                SelectProps={{ SelectDisplayProps: { id: 'workflow-selection' } }}
              >
                {statuses.map((st, index) => (
                  <MenuItem value={index} key={`${st}-${index}`} data-testid='workflow-states-options'>
                    {status[st]}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box width={5 / 10} mx='10px'>
              <TextField
                multiline
                maxRows={3}
                label={i18n._(t`Observações`)}
                value={notes}
                onChange={changeNotes}
                inputProps={{ 'data-testid': 'workflow-notes' }}
              />
            </Box>
            <Box width={2 / 10} display='flex' alignSelf='flex-end'>
              <Button
                variant='contained'
                color='primary'
                onClick={saveStatus}
                disabled={saveDisabled}
                data-testid='workflow-save'
              >
                {i18n._(t`salvar`)}
              </Button>
            </Box>
          </Box>
        )}
        <Box height={events && events.length > 0 ? 'auto' : '100%'}>
          {events && events.length > 0 ? (
            <SearchResultsTyped<IMerchantsEvent>
              header={[
                {
                  title: i18n._(t`data`),
                  prop: 'registration_date',
                  cellComponent: (item: IMerchantsEvent) =>
                    i18n._(
                      t`${date(new Date(item.registration_date), {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}`,
                    ),
                },
                { title: i18n._(t`observações`), prop: 'details' },
              ]}
              data={{
                items: events as IMerchantsEvent[],
                current_page: 1,
                per_page: 9999,
                total_items: events.length,
                pages: 1,
              }}
              loading={!events}
            />
          ) : (
            <NoResults message={i18n._(t`Nenhum item encontrado.`)} />
          )}
        </Box>
      </Box>
      <Snackbar
        open={openFeedback}
        variant={successFeedback ? 'success' : 'error'}
        message={successFeedback ? feedbackMessage[0] : feedbackMessage[1]}
        onClose={closeFeedback}
        data-testid='snackbar'
      />
    </Fragment>
  )
}

export { SellerWorkflow }
