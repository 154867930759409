import { Auth } from '@pismo/sdk-auth'
import { getBaseAPIURL } from './getBaseAPIURL'
import { mapUser } from './mapUser'
import { UserActions, User } from '../store'

export const refreshToken = async (dispatch) => {
  const updateHandler = (_input: User) => {
    if (!_input) {
      return logout()
    }
    dispatch(UserActions?.updateUser(_input))
  }

  const logout = () => {
    dispatch(UserActions?.clear())
    AppAuth.resetSession()
    AppAuth.logout()
  }

  const baseAPIURL: string = getBaseAPIURL()
  const AppAuth = Auth({ baseURL: baseAPIURL, onUpdate: updateHandler })
  try {
    const retryLogin = await AppAuth.ensure()

    const updatedUser = mapUser(retryLogin)

    if (retryLogin) {
      AppAuth.setSession(retryLogin)
      dispatch(UserActions?.updateUser(updatedUser))
      return updatedUser
    }

    return false
  } catch (err) {
    dispatch({ type: 'LOGOUT' })
    window.location.href = '/'
    logout()
  }
}
