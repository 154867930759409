export enum UserEvents {
  UPDATE_USER = 'UPDATE_USER',
  CLEAR = 'CLEAR',
}

export interface UserAction {
  type: UserEvents
  payload?: any
}

export interface User {
  token: string
  refreshToken: string
  roles: string[] | null
  tenant: string
  email: string
  merchant?: string
  marketplace?: string
}

export const user: User = {
  token: '',
  refreshToken: '',
  roles: null,
  tenant: '',
  email: '',
}

export const userReducer = (state: User = user, action: UserAction) => {
  switch (action.type) {
    case UserEvents.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      }
    case UserEvents.CLEAR:
      return {
        ...user,
      }
    default:
      return state
  }
}

export interface IUserActions {
  updateUser: (input: User) => UserAction
  clear: () => UserAction
}

export const UserActions: IUserActions = {
  updateUser: (input: User) => ({
    type: UserEvents.UPDATE_USER,
    payload: input,
  }),
  clear: () => ({
    type: UserEvents.CLEAR,
  }),
}
