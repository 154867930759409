import React, { useRef, useEffect } from 'react'

import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'

import { SubMenuButton } from './SubMenuButton'

gsap.registerPlugin(Draggable)

export interface TopProps {
  items: any
  name?: string
  page: string
  onBack?: () => void
  onMenuClick?: (page: string) => void
}

const Top: React.FC<TopProps> = ({ items, name, page, onBack, onMenuClick }: TopProps) => {
  const dragContainer = useRef<any>()
  const menuElements = items

  useEffect(() => {
    if (dragContainer.current) {
      const cont: HTMLElement = dragContainer.current
      const p: HTMLElement = cont.parentElement as HTMLElement

      if (cont.clientWidth > p.clientWidth) {
        Draggable.create(cont, {
          bounds: p,
          edgeResistance: 0.65,
          type: 'x',
          inertia: false,
          autoScroll: 1,
          dragClickables: true,
          dragResistance: 0,
        })
      }
    }
  }, [dragContainer.current])

  const menuClicked = (name: string) => {
    if (onMenuClick) onMenuClick(name)
  }

  return (
    <Box width={1} px='1.25rem' pt='.6rem' display='flex' flexDirection='column'>
      <Box width={1} overflow='hidden' display='flex' py='1.25rem' justifyContent={'center'}>
        <div ref={dragContainer} style={{ display: 'flex' }}>
          <ButtonGroup>
            {menuElements.map((el, index) => (
              <SubMenuButton key={`${el.name}-${index}`} {...el} selected={page === el.name} onClick={menuClicked} />
            ))}
          </ButtonGroup>
        </div>
      </Box>
    </Box>
  )
}

export { Top }
