import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import Box from '@mui/material/Box'
import { t } from '@lingui/macro'
import { IAntecipateResult, IAntecipateResultItems } from '../../../../resources/merchants'
import { RootState } from '../../../../store/Store'
import useFetch from 'src/resources/useFetch'
import { SellerAnticipationActions } from '../../../../store'
import Loading from '@Components/loading'
import { Typography } from '@mui/material'
import SellerAnticipationChart from './SellerAnticipationChart'
import SellerAnticipationChartLegend from './SellerAnticipationChartLegend'
import { SellerAnticipationPropType } from './interface'

const SellerAnticipation = (props: SellerAnticipationPropType) => {
  const { merchantId, anticipationData } = props

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { updateChartData, setAnticipationData } = SellerAnticipationActions
  const dispatch = useDispatch()

  const [client, fetch, toaster] = useFetch('merchants')

  const getAntecipate = async () => {
    const antecipadeData: IAntecipateResult = await fetch(() => client?.getAntecipate(merchantId))
    dispatch(setAnticipationData(antecipadeData))
  }

  useEffect(() => {
    getAntecipate()
  }, [client])

  const isBetweenTwoDates = (date, start, end) => {
    if (new Date(date) >= new Date(start) && new Date(date) <= new Date(end)) {
      return true
    } else {
      return false
    }
  }

  const Placeholder = () => {
    return (
      <Box height={1} display='flex' justifyContent='space-around' alignItems='center'>
        {toaster()}
        <Box>
          <Typography variant='h6' gutterBottom>
            {i18n._(t`NaoExistemAntecipacoesRalizaveis`)}
          </Typography>
        </Box>
      </Box>
    )
  }
  const sliderChange = useCallback(
    (range) => {
      const first = range.start
      const last = range.end

      const items: IAntecipateResultItems = anticipationData?.items

      const filtered = items?.filter((item) => isBetweenTwoDates(item.payment_date, first, last))

      dispatch(updateChartData(filtered))
    },
    [anticipationData],
  )

  const isEmptyData = () => !anticipationData?.items?.length

  const getComponent = () => (isEmptyData() ? <Placeholder /> : <Content />)

  const Content = () => {
    return (
      <>
        <SellerAnticipationChart anticipationData={anticipationData} sliderChange={sliderChange} />
        <SellerAnticipationChartLegend anticipationData={anticipationData} merchantId={merchantId} />
      </>
    )
  }

  return anticipationData ? getComponent() : <Loading />
}

const mapStateToProps = function (state) {
  return {
    anticipationData: state.sellerAnticipation.anticipationData,
  }
}

export default connect(mapStateToProps)(SellerAnticipation)
