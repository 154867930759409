import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const isProduction = process.env.NODE_ENV === 'production'
if (isProduction) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root')
const root = container && createRoot(container)
root && root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
