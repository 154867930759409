import { ClientInterface, createClient } from './api/createClient'

export interface transactionInterface {
  baseURL: string
  headers: {
    Authorization: string
    'x-tenant': string
    'x-protocol'?: string
  }
}

export interface IOperationTypes {
  id: Number
  processing_code: Number
  description: string
  statement_description: any
  code: number
  quantity: any
  total_value: any
  days_expiration: string | number
  type: {
    id: number
  }
}

export const Transaction = ({ headers }) => {
  const baseURL = String(process.env.REACT_APP_API_ADDRESS)
  const client = createClient({ baseURL: `${baseURL}/transactions`, headers })

  return {
    getClient(): ClientInterface {
      return client
    },

    async getAllOperationsTypes() {
      return await client.get(`/v1/org/operations/types`)
    },
  }
}
