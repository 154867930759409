import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import Grid from '@mui/material/Grid'

import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'

import TextField from '@Components/textField'
import { FormControl } from '@Components/formControl'
import { Countrys, CurrencyCode } from '@Components/world-format/types'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import { SellerNewActions } from '../../../store'
import { ProgramType } from '@pismo/sdk-orgs'
import { RootState } from '../../../store/Store'
import { CNAE } from 'src/resources/merchants'
import { useSnackbar } from 'notistack'

const { getProgramsTypes, getBusinessCategories } = SellerNewActions

export interface AccountData {
  cnae: string
  document_number: string
  program_type: ProgramType
  name: string
  email: string
}

export interface AccountFormProps {
  onNext: (data: AccountData) => void
  data?: AccountData
  children?: React.ReactElement
}

const AccountForm: React.FC<AccountFormProps> = ({ onNext, data, children }: AccountFormProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { programsTypes } = useSelector((state: RootState) => state.sellerNew)

  const containerRef = useRef<any>()

  const schema = {
    document_number: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Número de documento inválido.`),
      },
    },
    program_type: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Escolha o tipo de programa.`),
      },
    },
    name: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Insira seu nome.`),
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`E-mail inválido.`),
      },
      email: {
        message: i18n._(t`E-mail inválido.`),
      },
    },
  }

  const submitHandler = (values) => {
    if (programsTypes === null) return
    const pt = programsTypes.items.filter((_pt: ProgramType) => _pt.program_id === values.program_type)

    const res = { ...values, program_type: pt[0] }

    onNext(res as AccountData)

    containerRef.current.scrollTop = 0
  }

  return (
    <div ref={containerRef}>
      <FormControl
        initialValue={{
          document_number: data?.document_number ?? '',
          name: data?.name ?? '',
          cnae: data?.cnae ?? '',
          program_type: data?.program_type.program_id,
          email: data?.email,
        }}
        onSubmit={submitHandler}
        validationSchema={schema}
      >
        {(data) => (
          <>
            <Card data-testid='account-container'>
              <CardContent>
                <Box mt='1rem'>
                  <FormView {...data} />
                </Box>
              </CardContent>
            </Card>
            {children}
          </>
        )}
      </FormControl>
    </div>
  )
}

const FormView = ({ values, errors, handleChange }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { programsTypes, businessCategories, error } = useSelector((state: RootState) => state.sellerNew)
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (programsTypes === null) {
      dispatch(getProgramsTypes(user))
    }

    if (!businessCategories) {
      dispatch(getBusinessCategories(user))
    }
  }, [])

  useEffect(() => {
    if (error?.hasError) {
      enqueueSnackbar(error?.errorMessage?.toString() || i18n._(t`genericApiError`), {
        variant: 'error',
      })
    }
  }, [error])

  useEffect(() => {
    const initial = businessCategories?.filter(
      (el: CNAE) => el.business_category_id === values.cnae?.business_category_id,
    )[0]
    handleChange({ target: { name: 'cnae', value: initial } })
  }, [businessCategories])

  const changeCNAE = (handleChange: (prop: any) => void) => (_, value) => {
    handleChange({ target: { name: 'cnae', value } })
  }

  const handleProgram = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value } })
  }

  const hasCNAEList = () => !!businessCategories?.length

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} style={{ display: 'none' }}>
        <TextField label={i18n._(t`país`)} select value={Object.keys(Countrys)[0]} disabled>
          {Object.keys(Countrys).map((country, index) => (
            <MenuItem key={`${country}-${index}`} value={country}>
              {country}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: 'none' }}>
        <TextField label={i18n._(t`moeda`)} select value={Object.values(CurrencyCode)[0]} disabled>
          {Object.values(CurrencyCode).map((currency, index) => (
            <MenuItem key={`${currency}-${index}`} value={currency}>
              {currency}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          description={i18n._(t`Número do documento de identificação do estabelecimento comercial`)}
          label={i18n._(t`documentNumber`)}
          name='document_number'
          value={values.document_number}
          onChange={handleChange}
          error={Boolean(errors.document_number)}
          helperText={Boolean(errors.document_number) ? errors.document_number : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {programsTypes && (
          <TextField
            required
            label={i18n._(t`tipo do programa`)}
            description={i18n._(t`Programa do tipo merchant (criado previamente)`)}
            select
            name='program_type'
            value={values.program_type ?? ''}
            onChange={handleProgram(handleChange)}
            error={Boolean(errors.program_type)}
            helperText={Boolean(errors.program_type) ? errors.program_type : ''}
            data-testid='program_type'
          >
            {programsTypes?.items
              .filter((pt: ProgramType) => pt.program_type === 'MERCHANT')
              .map((pt: ProgramType, index) => (
                <MenuItem key={`${pt.program_id}-${index}`} value={pt.program_id} data-testid='program_type-item'>
                  {pt.program_name}
                </MenuItem>
              ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12}>
        {hasCNAEList() && (
          <Autocomplete
            options={businessCategories || []}
            clearOnEscape
            autoHighlight
            getOptionLabel={(option: CNAE) => `${option.description || ''}`}
            value={values?.cnae}
            renderInput={(params) => (
              <TextField
                {...params}
                name='cnaeelement'
                label={i18n._(t`Área de negócio`)}
                description={i18n._(t`Área de negócio conforme tabela previamente cadastrada`)}
                helperText={errors.cnae}
                error={Boolean(errors.cnae)}
                multiline
                data-testid='cnae-input'
              />
            )}
            onChange={changeCNAE(handleChange)}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          label={i18n._(t`establishmentName`)}
          description={i18n._(t`establishmentName`)}
          name='name'
          value={values.name}
          onChange={handleChange}
          error={Boolean(errors.name)}
          helperText={Boolean(errors.name) ? errors.name : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          label={i18n._(t`E-mail`)}
          name='email'
          value={values.email}
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={Boolean(errors.email) ? errors.email : ''}
        />
      </Grid>
    </Grid>
  )
}

export { AccountForm }
