import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import TextField from '@Components/textField'

import { SellerEvent } from '@Screens/sellers/SellerEdit/SellerEvent'
import { MarketplaceScheduleActions } from '@Store/index'
import { RootState } from '@Store/Store'
import { BackdropLoading } from '@Components/loading'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

const { getMerchantsList } = MarketplaceScheduleActions

const MarketplaceSchedule = ({ marketplace }: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { merchantsList, merchantsListLoading } = useSelector((state: RootState) => state.marketplaceSchedule)
  const user = useSelector((state: RootState) => state.user)

  const [merchantSelected, setMerchantSelected] = useState(0)

  useEffect(() => {
    dispatch(getMerchantsList(marketplace.id, user))
  }, [])

  const changeMerchant = (e) => {
    setMerchantSelected(e.target.value)
  }

  const onBack = () => history.goBack()

  const renderMarketplaceMessageWithoutMerchants = () =>
    !merchantsListLoading && (
      <Box display='flex' mt={2} alignItems='center'>
        <Typography sx={{ mr: 1 }}>{i18n._(t`mustHaveAtLeastOneEstablishmentLinked`)}</Typography>
        <Button onClick={onBack} variant='contained'>
          {i18n._(t`voltar`)}
        </Button>
      </Box>
    )
  const showMerchantSelect = !!(merchantsList && merchantsList?.items?.length > 0)

  const renderMerchantSelect = () => (
    <>
      <Box>
        <Typography>{i18n._(t`selectAnEstablishmentToViewTransactions`)}</Typography>
      </Box>
      <Box ml={2}>
        <TextField select value={merchantSelected} onChange={changeMerchant}>
          <MenuItem value={0}>{i18n._(t`selectSeller`)}</MenuItem>
          {merchantsList &&
            merchantsList.items.map((el, index) => (
              <MenuItem key={`-${index}`} value={index + 1}>
                {el.merchant.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    </>
  )

  return (
    <Fragment>
      <Box width={1} display='flex' flexDirection='column' data-testid='events-page-container'>
        <Box width={1} display='flex' flexDirection='column'>
          <Box width={1} display='flex' justifyContent='left' alignItems='center' py={2}>
            {showMerchantSelect ? renderMerchantSelect() : renderMarketplaceMessageWithoutMerchants()}

            <BackdropLoading loading={merchantsListLoading} />
          </Box>
        </Box>
        {!!merchantSelected && <SellerEvent sellerId={merchantSelected} marketplaceId={marketplace.id} />}
      </Box>
    </Fragment>
  )
}

export { MarketplaceSchedule }
