import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import { RootState } from '../../../../store/Store'
import Box from '@mui/material/Box'
import { formatToDefaultCurrency, rounds } from 'src/utils/number'
import { locale } from '@Components/calendar'
import { IAntecipateResultItems, ISetAntecipate } from 'src/resources/merchants'
import { t } from '@lingui/macro'
import { styled } from '@mui/material/styles'
import { Alert, Snackbar, Typography } from '@mui/material'
import Button from '@Components/button'
import { SellerAnticipationActions } from 'src/store/SellerAnticipation'

import SellerAnticipationModal from './SellerAnticipationModal'
import { SellerAnticipationPropType } from './interface'
import useFetch from 'src/resources/useFetch'

const LeftStylizedBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
}))

const MiddleStylizedBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.common.white,
}))

const RightStylizedBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}))

const getValue = (filteredData, key): number => {
  return !!filteredData
    ? filteredData?.reduce(function (prev, cur) {
        return prev + cur[key]
      }, 0)
    : 0
}

const Placeholder = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  return (
    <Box height={1} display='flex' justifyContent='space-around' alignItems='center'>
      <Box>
        <Typography variant='h6' gutterBottom>
          {i18n._(t`NaoExistemAntecipacoesRalizaveis`)}
        </Typography>
      </Box>
    </Box>
  )
}

const SellerAnticipationChartLegend = ({ anticipationData, merchantId }: SellerAnticipationPropType) => {
  const { updateChartData, setAnticipationData, setOpenAnticipationFeedback } = SellerAnticipationActions

  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const { chartData, openAnticipationFeedback, selectedDate } = useSelector(
    (state: RootState) => state.sellerAnticipation,
  )

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [legendData, setLegendData] = useState<any>({ total: 0, valueToAnticipate: 0, advancementAmount: 0, fees: 0 })
  const [client] = useFetch('merchants')

  const isEmptyData = () => !anticipationData?.items?.length

  useEffect(() => {
    const data = selectedDate ? [selectedDate] : chartData
    setLegendData({
      total: getValue(data, 'max_advancement_amount'),
      valueToAnticipate: getValue(data, 'net_value'),
      advancementAmount: getValue(data, 'advancement_amount'),
      fees: rounds(anticipationData?.tax.rav.percentage, 2),
    })
  }, [chartData, selectedDate])

  useEffect(() => {
    dispatch(updateChartData(anticipationData.items))
  }, [])

  const dispatch = useDispatch()

  const buildPayload = (data: IAntecipateResultItems): ISetAntecipate => {
    const begin_date = format(new Date(data?.[0].payment_date), 'dd/MM/yyyy', {
      locale: locale[language],
    })
    const end_Date = data?.[data.length - 1]?.payment_date
    const amount = getValue(data, 'advancement_amount')
    const payload = { begin_date, end_Date, amount, merchantId }
    return payload
  }

  const setAntecipate = async (data: IAntecipateResultItems) => {
    setLoading(true)
    try {
      await client?.setAntecipate(merchantId, buildPayload(data))

      const selectedDates = data.map((item) => item.payment_date)

      const newItems = selectedDates.length
        ? anticipationData?.items.filter((item) => !selectedDates.includes(item.payment_date))
        : anticipationData?.items

      const newData = { ...anticipationData, items: newItems }
      dispatch(
        setOpenAnticipationFeedback({
          color: 'success',
          open: true,
          message: i18n._(t`AntecipacaoRealizadaComSucesso`),
        }),
      )
      dispatch(setAnticipationData(newData))
      dispatch(updateChartData(newItems))
      setModalOpen(false)
    } catch (err: any) {
      setOpenAnticipationFeedback({ color: 'error', open: true, message: err.message })
    } finally {
      setLoading(false)
    }
  }

  const Content = () => {
    return (
      <>
        <Box mt={10}>
          <Typography textAlign={'center'} variant='body2' color='error' gutterBottom>
            * {i18n._(t`selecionePeriodoAntecipacao`)}
          </Typography>
        </Box>
        <Box mt={8} width={1} display='flex' alignItems='center' justifyContent='space-evenly'>
          <LeftStylizedBox p={3} display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='body2' gutterBottom>
              {i18n._(t`TotalARreceber`)}
            </Typography>
            {formatToDefaultCurrency(Number(legendData.total))}
          </LeftStylizedBox>
          <MiddleStylizedBox p={3} display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='body2' gutterBottom>
              {i18n._(t`ValorAAntecipar`)}
            </Typography>
            {formatToDefaultCurrency(Number(legendData.valueToAnticipate))}
          </MiddleStylizedBox>
          <RightStylizedBox p={3} display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='body2' gutterBottom>
              {i18n._(t`liquidoAReceber`)}
            </Typography>
            {formatToDefaultCurrency(Number(legendData.advancementAmount))}
          </RightStylizedBox>
        </Box>

        <Box width={1} display='flex' justifyContent='center' my={3}>
          <Button type='button' color='primary' variant='contained' fullWidth onClick={() => setModalOpen(true)}>
            {i18n._(t`SolicitarAntecipacao`)}
          </Button>
        </Box>
      </>
    )
  }

  return (
    <>
      {isEmptyData() ? <Placeholder /> : <Content />}
      <SellerAnticipationModal
        close={() => setModalOpen(false)}
        open={modalOpen}
        title={i18n._(t`liquidoAReceber`)}
        advancementAmount={formatToDefaultCurrency(legendData.advancementAmount)}
        valueToAnticipate={formatToDefaultCurrency(legendData.valueToAnticipate)}
        fees={legendData.fees}
        discountedValue={formatToDefaultCurrency(legendData.valueToAnticipate - legendData.advancementAmount)}
        tariff={formatToDefaultCurrency(Number(anticipationData?.tax.rav.tariff))}
        rate={rounds(anticipationData?.tax.rav.cet, 2)}
        setAntecipate={() => setAntecipate(selectedDate ? [selectedDate] : chartData)}
        loading={loading}
      />
      <Snackbar
        open={openAnticipationFeedback.open}
        autoHideDuration={6000}
        onClose={() => dispatch(setOpenAnticipationFeedback({ open: false }))}
      >
        <Alert
          onClose={() => dispatch(setOpenAnticipationFeedback({ open: false }))}
          severity={openAnticipationFeedback.color}
          sx={{ width: '100%' }}
        >
          {openAnticipationFeedback.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SellerAnticipationChartLegend
