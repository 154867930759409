import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import AppBar from '@Components/appBar'

import { InputSearch, SearchResults } from '@Components/search'

import { MarketplaceSearchActions } from '../../store'
import { RootState } from '../../store/Store'

const { getMarketplaceList } = MarketplaceSearchActions

const MarketplaceSearch: React.FC = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { loading, searchResult } = useSelector((state: RootState) => state.creditorssearch)
  const user = useSelector((state: RootState) => state.user)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getMarketplaceList(user))
  }, [])

  const onChangePage = (nextPage: number) => {
    dispatch(getMarketplaceList(user, { search: searchTerm, page: nextPage }))
  }

  const searchByTerm = (name: string) => {
    setSearchTerm(name)
    dispatch(getMarketplaceList(user, { search: name }))
  }

  const onClickHandler = (item: any) => history.push(`/marketplace/edit/${item.id}/home`)

  const handleNewCreditor = () => {
    history.push('/marketplace/new')
  }

  return (
    <Page>
      <AppBar title={i18n._(t`Marketplace`)}>
        <InputSearch
          hasNew
          inputPlaceholder={i18n._(t`Pesquise um Marketplace`)}
          newLabel={i18n._(t`Novo Marketplace`)}
          onClickSearch={searchByTerm}
          onNewClick={handleNewCreditor}
        />
      </AppBar>
      <PageContent>
        <SearchResults
          header={[
            { title: i18n._(t`Nome do Marketplace`), prop: 'name' },
            { title: i18n._(t`documentNumber`), prop: 'document_number' },
          ]}
          data={searchResult}
          changePage={onChangePage}
          onClick={onClickHandler}
          loading={loading}
        />
      </PageContent>
    </Page>
  )
}

export { MarketplaceSearch }
