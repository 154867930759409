import { Dispatch } from 'redux'

import { IError } from '@pismo/sdk-core'

import { mountAuthHeaders, interceptor } from '../utils'
import {
  IGetOperationsType,
  IGetTransactionResult,
  IGetTransactions,
  IGetTransactionsTotalFull,
  IMarketplacesMerchantsListResult,
  IOperation,
  ITransactionType,
  ITransactionsTotal,
  Merchants,
} from 'src/resources/merchants'

export type TransactionApiVersion = 2 | 3

export enum MarketplaceScheduleEvents {
  GET_TRANSACTIONS_TOTAL = 'GET_TRANSACTIONS_TOTAL_mkt_schedule',
  GET_MERCHANTS_LIST = 'GET_MERCHANTS_LIST_mkt_schedule',
  SET_MERCHANTS_LIST_LOADING = 'SET_MERCHANTS_LIST_LOADING_mkt_schedule',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS_mkt_schedule',
  GET_TRANSACTIONS_TYPE = 'GET_TRANSACTIONS_TYPE_mkt_schedule',
  GET_OPERATIONS_TYPE = 'GET_OPERATIONS_TYPE_mkt_schedule',
  CHANGE_STATUS_RESULT = 'CHANGE_STATUS_RESULT_mkt_schedule',
}

export interface MarketplaceScheduleAction {
  type: MarketplaceScheduleEvents
  payload?: any
}

export interface MarketplaceSchedule {
  transactionsTotal: ITransactionsTotal | null
  merchantsList: IMarketplacesMerchantsListResult | null
  merchantsListLoading: boolean
  transactions: IGetTransactionResult | null
  transactionsType: ITransactionType[] | null
  operationsType: IOperation[] | null
  changeStatusResult: boolean | null
}

export const marketplaceSchedule: MarketplaceSchedule = {
  transactionsTotal: null,
  merchantsList: null,
  merchantsListLoading: false,
  transactions: null,
  transactionsType: null,
  operationsType: null,
  changeStatusResult: null,
}

export const marketplaceScheduleReducer = (
  state: MarketplaceSchedule = marketplaceSchedule,
  action: MarketplaceScheduleAction,
): MarketplaceSchedule => {
  switch (action.type) {
    case MarketplaceScheduleEvents.GET_TRANSACTIONS_TOTAL:
      return { ...state, transactionsTotal: action.payload }
    case MarketplaceScheduleEvents.GET_MERCHANTS_LIST:
      return { ...state, merchantsList: action.payload, merchantsListLoading: false }
    case MarketplaceScheduleEvents.SET_MERCHANTS_LIST_LOADING:
      return { ...state, merchantsListLoading: action.payload }
    case MarketplaceScheduleEvents.GET_TRANSACTIONS:
      return { ...state, transactions: action.payload }
    case MarketplaceScheduleEvents.GET_TRANSACTIONS_TYPE:
      return { ...state, transactionsType: action.payload }
    case MarketplaceScheduleEvents.GET_OPERATIONS_TYPE:
      return { ...state, operationsType: action.payload }
    case MarketplaceScheduleEvents.CHANGE_STATUS_RESULT: {
      return { ...state, changeStatusResult: action.payload }
    }
    default:
      return state
  }
}

export interface IMarketplaceScheduleActions {
  getTransactionsTotal: (props: IGetTransactionsTotalFull, credentials: any) => (dispatch: Dispatch) => void
  getMerchantsList: (marketplaceID: number, credentials: any) => (dispatch: Dispatch) => void
  getTransactions: (
    marketplaceID: number,
    version: TransactionApiVersion,
    props: IGetTransactions,
    credentials: any,
    merchantID?: number,
  ) => (dispatch: Dispatch) => void
  getTransactionsType: (
    marketplaceID: number,
    version: TransactionApiVersion,
    paymentDate: Date,
    credentials,
    merchantID?: number,
  ) => (dispatch: Dispatch) => void
  getOperationsType: (props: IGetOperationsType, credentials: any) => (dispatch: Dispatch) => void
  changeStatus: (
    transactionID: number,
    merchantID: number,
    status: string,
    credentials: any,
  ) => (dispatch: Dispatch) => void
  clearStatusResult: () => MarketplaceScheduleAction
}

export const MarketplaceScheduleActions: IMarketplaceScheduleActions = {
  getTransactionsTotal: (props: IGetTransactionsTotalFull, credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response = await MerchantsClient.getTransactionsTotalFull(props)

    const respError: IError = response as IError

    if (respError.error) {
      return
    }

    dispatch({ type: MarketplaceScheduleEvents.GET_TRANSACTIONS_TOTAL, payload: response as ITransactionsTotal })
  },

  getMerchantsList: (marketplaceID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: MarketplaceScheduleEvents.GET_MERCHANTS_LIST,
      payload: null,
    })
    dispatch({ type: MarketplaceScheduleEvents.SET_MERCHANTS_LIST_LOADING, payload: true })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response = await MerchantsClient.getMarketplacesMerchantList(marketplaceID)

    if ((response as IError).error) {
      dispatch({ type: MarketplaceScheduleEvents.GET_MERCHANTS_LIST, payload: null })
      return
    }

    dispatch({
      type: MarketplaceScheduleEvents.GET_MERCHANTS_LIST,
      payload: response as IMarketplacesMerchantsListResult,
    })
  },

  getTransactions:
    (
      marketplaceID: number,
      version: TransactionApiVersion,
      props: IGetTransactions,
      credentials: any,
      merchantID?: number,
    ) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: MarketplaceScheduleEvents.GET_TRANSACTIONS,
        payload: null,
      })

      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)

      const response = await MerchantsClient.getMarketplaceMerchantTransactions(
        marketplaceID,
        version,
        props,
        merchantID,
      )

      if ((response as IError).error) {
        return
      }

      dispatch({
        type: MarketplaceScheduleEvents.GET_TRANSACTIONS,
        payload: response as IGetTransactionResult,
      })
    },

  getTransactionsType:
    (marketplaceID: number, version: TransactionApiVersion, paymentDate: Date, credentials, merchantID?: number) =>
    async (dispatch: Dispatch) => {
      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)

      const response = await MerchantsClient.getMarketplaceMerchantsTransactionsType(
        marketplaceID,
        version,
        paymentDate,
        merchantID,
      )

      if ((response as IError).error) {
        dispatch({ type: MarketplaceScheduleEvents.GET_TRANSACTIONS_TYPE, payload: null })
        return
      }

      dispatch({ type: MarketplaceScheduleEvents.GET_TRANSACTIONS_TYPE, payload: response as ITransactionType[] })
    },

  getOperationsType: (props: IGetOperationsType, credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response = await MerchantsClient.getOperationsType(props)

    if ((response as IError).error) {
      dispatch({ type: MarketplaceScheduleEvents.GET_OPERATIONS_TYPE, payload: null })
      return
    }

    dispatch({ type: MarketplaceScheduleEvents.GET_OPERATIONS_TYPE, payload: response as IOperation[] })
  },

  changeStatus:
    (transactionID: number, merchantID: number, status: string, credentials: any) => async (dispatch: Dispatch) => {
      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)

      const response = await MerchantsClient.updateStatusTransaction(merchantID, transactionID, {
        id: transactionID,
        merchantId: merchantID,
        status,
      })

      if ((response as IError).error) {
        dispatch({ type: MarketplaceScheduleEvents.CHANGE_STATUS_RESULT, payload: false })
        return
      }

      dispatch({ type: MarketplaceScheduleEvents.CHANGE_STATUS_RESULT, payload: true })
    },
  clearStatusResult: () => ({ type: MarketplaceScheduleEvents.CHANGE_STATUS_RESULT, payload: null }),
}
