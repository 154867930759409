import { Dispatch } from 'redux'

export enum SellerAnticipationEvents {
  SET_LOADING = 'SET_LOADING',
  UPDATE_CHART_DATA = 'UPDATE_CHART_DATA',
  SET_ANTICIPATION_DATA = 'SET_ANTICIPATION_DATA',
  SET_ANTICIPATION_DATE = 'SET_ANTICIPATION_DATE',
  SET_OPEN_ANTICIPATION_FEEDBACK = 'SET_OPEN_ANTICIPATION_FEEDBACK',
}

export interface SellerAnticipationAction {
  type: SellerAnticipationEvents
  payload?: any
}

export interface OpenFeedbackType {
  open: boolean
  message?: string
  color?: 'error' | 'warning' | 'info' | 'success'
}

export interface SellerAnticipation {
  loading?: boolean
  chartData?: any
  anticipationData?: any
  openAnticipationFeedback: OpenFeedbackType
  selectedDate?: any
}

export const sellerAnticipation: SellerAnticipation = {
  loading: false,
  chartData: [],
  anticipationData: null,
  openAnticipationFeedback: { open: false, message: '', color: 'success' },
  selectedDate: null,
}

export const sellerAnticipationReducer = (
  state: SellerAnticipation = sellerAnticipation,
  action: SellerAnticipationAction,
): SellerAnticipation => {
  switch (action.type) {
    case SellerAnticipationEvents.SET_LOADING:
      return { ...state, loading: action.payload }
    case SellerAnticipationEvents.UPDATE_CHART_DATA:
      return { ...state, selectedDate: null, chartData: action.payload }
    case SellerAnticipationEvents.SET_ANTICIPATION_DATA:
      return { ...state, anticipationData: action.payload }
    case SellerAnticipationEvents.SET_OPEN_ANTICIPATION_FEEDBACK:
      return { ...state, openAnticipationFeedback: action.payload }
    case SellerAnticipationEvents.SET_ANTICIPATION_DATE:
      return { ...state, selectedDate: state.chartData[action.payload] || null }
    default:
      return state
  }
}

export interface ISellerAnticipationActions {
  updateChartData: (data: any) => (dispatch: Dispatch) => void
  setAnticipationData: (data: any) => (dispatch: Dispatch) => void
  setOpenAnticipationFeedback: (data: OpenFeedbackType) => SellerAnticipationAction
  setAnticipationDate: (data: any) => (dispatch: Dispatch) => void
}

export const SellerAnticipationActions: ISellerAnticipationActions = {
  updateChartData: (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: SellerAnticipationEvents.UPDATE_CHART_DATA, payload: data })
  },
  setAnticipationData: (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: SellerAnticipationEvents.SET_ANTICIPATION_DATA, payload: data })
  },
  setAnticipationDate: (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: SellerAnticipationEvents.SET_ANTICIPATION_DATE, payload: data })
  },
  setOpenAnticipationFeedback: (data: OpenFeedbackType) => ({
    type: SellerAnticipationEvents.SET_OPEN_ANTICIPATION_FEEDBACK,
    payload: data,
  }),
}
