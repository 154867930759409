import React, { useState, useEffect, Fragment, useMemo, memo } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import { t } from '@lingui/macro'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@Components/button'
import Autocomplete from '@mui/material/Autocomplete'

import debounce from 'lodash.debounce'

import TextField from '@Components/textField'
import { FormControl } from '@Components/formControl'
import { CurrencyInput } from '@Components/currency-input'
import { locale, getWeekNames } from '@Components/calendar'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import { SellerNewActions } from '../../../store'
import { CreditorCreationForm } from './CreditorCreationForm'
import { Typography } from '@mui/material'
import { RootState } from '../../../store/Store'
import { CreditorFormType, ICreditorCreationData, ICreditorCreationSubmitData } from './interface'
import { useSnackbar } from 'notistack'
import { CreditorResult } from 'src/resources/merchants'

const { getCreditors, clearError } = SellerNewActions

export interface CreditorFormProps {
  onNext: <T>(data: T) => void
  data?: CreditorResult
  children?: React.ReactElement
}

const CreditorForm: React.FC<CreditorFormProps> = ({ onNext, data: creditor, children }: CreditorFormProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  const [createNewCreditor, setCreateNewCreditor] = useState<boolean>(false)

  const schema = {
    creditor: {
      presence: { allowEmpty: false, message: i18n._(t`Escolha um credor`) },
    },
  }

  const submitHandler = (values) => {
    onNext({ ...values.creditor })
  }

  const setNewCreditor = (formCreditorData: ICreditorCreationSubmitData) => {
    const frequency_day = formCreditorData.values.receivingPeriod === 'DAILY' ? 1 : formCreditorData.values.receivingDay

    const creditor: ICreditorCreationData = {
      name: formCreditorData.values.name,
      advancement_fee: formCreditorData.values.advancementFee,
      crediting_frequency: formCreditorData.values.receivingPeriod,
      crediting_method: formCreditorData.values.methodCredit,
      discount_rate: formCreditorData.values.discountRate,
      crediting_frequency_day: frequency_day,
      maximum_advancement_percentage: formCreditorData.values.maximumAdvancementPercentage,
      bank: formCreditorData.bank,
    }

    submitHandler({ creditor })
  }

  return (
    <div data-testid='creditor-container'>
      {!!createNewCreditor && <CreditorCreationForm method='new' handleSubmit={setNewCreditor} />}
      <FormControl
        initialValue={{ creditor }}
        onSubmit={submitHandler}
        validationSchema={(window as any).Cypress ? {} : schema}
      >
        {(data) => {
          return (
            <Box mb='1rem'>
              <Box mt='1rem'>
                <Box mb='1rem'>
                  <Card data-testid='account-container'>
                    <CardContent>
                      <FormViewComponent
                        {...data}
                        createNewCreditor={createNewCreditor}
                        setCreateNewCreditor={setCreateNewCreditor}
                      />
                    </CardContent>
                  </Card>
                </Box>
              </Box>
              {!createNewCreditor && children}
            </Box>
          )
        }}
      </FormControl>
    </div>
  )
}

const FormView = ({
  values,
  errors,
  handleChange,
  creditors,
  createNewCreditor,
  setCreateNewCreditor,
}: CreditorFormType) => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const user = useSelector((state: RootState) => state.user)
  const { error } = useSelector((state: RootState) => state.sellerNew)

  const [creditorsList, setCreditorsList] = useState<any[]>([])
  const [creditorSelected, setCreditorSelected] = useState<CreditorResult | null>(null)

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const weekDays = useMemo(() => getWeekNames(locale[language], new Date(), 'EEEE'), [language])

  const period = {
    DAILY: i18n._(t`Diário`),
    WEEKLY: i18n._(t`Semanal`),
    BIWEEKLY: i18n._(t`Quinzenal`),
    MONTHLY: i18n._(t`Mensal`),
  }

  useEffect(() => {
    if (error?.hasError) {
      enqueueSnackbar(error?.errorMessage?.toString() || i18n._(t`genericApiError`), {
        variant: 'error',
        onClose: () => dispatch(clearError()),
      })
    }
  }, [error?.hasError])

  useEffect(() => {
    handleChange({ target: { name: 'creditor', value: creditorSelected } }, { creditor: creditorSelected })
  }, [creditorSelected])

  useEffect(() => {
    if (values.creditor) {
      setCreditorSelected(values.creditor)
    }
  }, [values.creditor])

  useEffect(() => {
    if (creditors) {
      setCreditorsList([...creditors])
    }
  }, [creditors])

  const search = (_, value) => {
    const v = value

    if (value.length >= 4) {
      fetch(v)
    }
  }

  const selectCreditor = (_, value) => {
    fetch.cancel()

    if (value) {
      setCreditorSelected(value)
    }
  }

  const handleCreateNewCreditorClick = (e) => {
    setCreditorSelected(null)
    setCreateNewCreditor(true)
  }

  const handleSelectCreditorClick = (e) => {
    setCreditorSelected(null)
    setCreateNewCreditor(false)
  }

  const fetch = debounce((v) => dispatch(getCreditors(user, { name: v, perPage: 10 })), 500)

  return (
    <Grid container spacing={3}>
      <Fragment>
        {!createNewCreditor ? (
          <Fragment>
            <Grid item xs={12}>
              <Autocomplete
                options={creditorsList}
                clearOnEscape
                autoHighlight
                getOptionLabel={(option: any) => `${option.name}`}
                // @ts-ignore
                value={creditorSelected}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n._(t`Creditor`)}
                    helperText={errors.creditor}
                    error={Boolean(errors.creditor)}
                    placeholder={i18n._(t`Digite o nome do creditor`)}
                    data-testid='creditor-input'
                    required
                  />
                )}
                onChange={selectCreditor}
                onInputChange={search}
              />
            </Grid>
            {creditorSelected && creditorSelected.crediting_method === 'BANK' && (
              <Fragment>
                <Grid item xs={12}>
                  <TextField label={i18n._(t`Banco`)} value={creditorSelected.bank.name} disabled />
                </Grid>
                <Grid item xs={6}>
                  <TextField label={i18n._(t`Agência`)} value={creditorSelected.bank.agency} disabled />
                </Grid>
                <Grid item xs={6}>
                  <TextField label={i18n._(t`Conta`)} value={creditorSelected.bank.account} disabled />
                </Grid>
              </Fragment>
            )}

            {creditorSelected && creditorSelected.crediting_method === 'ACCOUNT' && (
              <Fragment>
                <Grid item xs={12}>
                  <TextField
                    label={i18n._(t`ID da conta`)}
                    value={creditorSelected.account?.id ? creditorSelected.account?.id : ''}
                    disabled
                  />
                </Grid>
              </Fragment>
            )}

            {creditorSelected && (
              <Fragment>
                <Grid item xs={6}>
                  <TextField
                    label={i18n._(t`Período de recebimento`)}
                    value={period[creditorSelected.crediting_frequency]}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={i18n._(t`Dia do recebimento`)}
                    value={
                      creditorSelected.crediting_frequency_day
                        ? creditorSelected.crediting_frequency === 'WEEKLY'
                          ? weekDays[creditorSelected.crediting_frequency_day - 1]
                          : creditorSelected.crediting_frequency_day
                        : ''
                    }
                    disabled
                  />
                </Grid>
              </Fragment>
            )}

            {creditorSelected && (
              <Fragment>
                <Grid item xs={6}>
                  <CurrencyInput
                    lang={language}
                    initialValue={creditorSelected.advancement_fee ?? 0}
                    TextfieldProps={{ label: i18n._(t`Tarifa de antecipação`), disabled: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={i18n._(t`Taxa de desconto`)}
                    value={
                      creditorSelected.discount_rate
                        ? `${(creditorSelected.discount_rate * 100).toPrecision(2)}%`
                        : '0%'
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={i18n._(t`Porcentagem máx. de antecipação`)}
                    value={
                      creditorSelected.maximum_advancement_percentage
                        ? `${
                            creditorSelected.maximum_advancement_percentage < 1
                              ? (creditorSelected.maximum_advancement_percentage * 100).toPrecision(2)
                              : creditorSelected.maximum_advancement_percentage * 100
                          }%`
                        : '0%'
                    }
                    disabled
                    data-testid='creditor-max-antecip'
                  />
                </Grid>
              </Fragment>
            )}

            <Grid item xs={12}>
              <Typography variant='h5' align='center'>
                {i18n._(t`Ou para o modelo de liquidação digital, por favor crie um novo creditor`)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mx='auto' mb='50px' display={'flex'} justifyContent='center'>
                <Button
                  type='button'
                  variant='contained'
                  color='success'
                  onClick={handleCreateNewCreditorClick}
                  data-testid='creditor-next-button'
                >
                  {i18n._(t`Criar novo creditor`)}
                </Button>
              </Box>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Typography variant='h5' align='center'>
                {i18n._(t`Ou para o modelo de liquidação CNAB, por favor selecione um creditor existente`)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent='center' mx='auto'>
                <Button
                  type='buttom'
                  variant='contained'
                  color='success'
                  onClick={handleSelectCreditorClick}
                  data-testid='creditor-next-button'
                >
                  {i18n._(t`Selecionar um creditor existente`)}
                </Button>
              </Box>
            </Grid>
          </Fragment>
        )}
      </Fragment>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  creditors: state.sellerNew.creditors,
})

const FormViewComponent = memo(connect(mapStateToProps, {})(FormView))

export { CreditorForm }
