import React from 'react'
import waves from '../assets/waves.svg'
import man from '../assets/man.svg'

import { PismoMarketplace } from '../commons/Icons'
import styled from '@emotion/styled'

export const HomeStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, rgba(223, 69, 124, 0.2) 0%, rgba(223, 69, 124, 0) 100%), #ffba45;
  align-items: center;
`

export const Img1 = styled.img`
  right: 0;
  bottom: 0;
  height: 70%;
  position: absolute;
`

export const Img2 = styled.img`
  width: 100%;
  bottom: 0;
  position: absolute;
`

export const PismoMarketplaceStyled = styled(PismoMarketplace)`
  width: 30%;
  height: 300px;
  margin-left: 15%;
`

const Home: React.FC = () => {
  return (
    <HomeStyled>
      <Img2 src={waves} />
      <Img1 src={man} />
      <PismoMarketplaceStyled />
    </HomeStyled>
  )
}

export { Home }
