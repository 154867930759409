export enum Countrys {
  BRAZIL = 'brazil',
  CHILE = 'chile',
}

export enum CurrencyCode {
  BRAZIL = 'BRL',
  CHILE = 'CLP',
  US = 'USD',
}

export interface AddressFormat {
  postalCode: string
  addressLine1: string
  addressLine2: string
  neighborhood: string
  city: string
  state: string
}

export interface StateResult {
  id: number
  sigla: string
  name: string
}

export interface CitiesResult {
  id: number
  name: string
  state: StateResult
}

export interface Error {
  error: { message: string }
}

export interface Format {
  documentNumber: string
  documentNumberIsValid: (value: string) => boolean
  phoneNumber: string
  phoneNumberIsValid: (value: string) => boolean
  postalCode: string
  postalCodeIsValid: (value: string) => boolean
  getAddressByPostalCode: (postalCode: string) => Promise<AddressFormat | Error>
  getStatesList: () => Promise<StateResult[] | Error>
  getCitiesList: (state: StateResult) => Promise<CitiesResult[] | Error>
}
