import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import { RootState } from '../../store/Store'
import Card from '@mui/material/Card'

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}))
export interface NoResultsProps {
  message?: string
  inline?: boolean
}

const NoResults: React.FC<NoResultsProps> = ({ message, inline = false }: NoResultsProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  return (
    <Card>
      <Box
        display='flex'
        width={1}
        height={1}
        justifyContent='center'
        alignItems='center'
        data-testid='no-result'
        data-inline={inline}
      >
        <Box textAlign='center' py={4} px={4}>
          <StyledSearchIcon fontSize='large' />
          <Typography>{message ?? i18n._(t`Nenhum resultado encontrado. Tente fazer outra pesquisa.`)}</Typography>
        </Box>
      </Box>
    </Card>
  )
}

export { NoResults }
