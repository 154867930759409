import React from 'react'

import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material'
import { Store } from './store'
import Routes from './routes/Routes'
import Translation from './translation/Translation'

import { PismoID } from './PismoID'
import * as Sentry from '@sentry/react'
import { SnackbarProvider } from 'notistack'
import { theme } from './commons/themes/Theme'

import './App.css'

const App: React.FC = () => {
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <Store>
          <PismoID>
            <Box
              display='flex'
              sx={{
                height: 'inherit',
              }}
            >
              <Translation>
                <SnackbarProvider maxSnack={3}>
                  <Routes />
                </SnackbarProvider>
              </Translation>
            </Box>
          </PismoID>
        </Store>
      </ThemeProvider>
    </div>
  )
}

export default Sentry.withProfiler(App)
