import { Dispatch } from 'redux'
import { mountAuthHeaders, interceptor } from '../utils'
import { IMarketplace, IPostMarketplace, IPutMarketplace, Marketplace } from 'src/resources/marketplace'

export enum MarketplaceEditEvents {
  MARKETPLACE_EDIT_RESULT = 'MARKETPLACE_EDIT_RESULT_mkt_edit',
  LOAD_MARKETPLACE_EDIT = 'LOAD_MARKETPLACE_EDIT_mkt_edit',
  SET_LOADING = 'SET_LOADING_mkt_edit',
  ERROR_MARKETPLACE_EDIT = 'ERROR_mkt_edit',
  CLEAR_ITEMS_MARKETPLACE_EDIT = 'CLEAR_ITEMS_mkt_edit',
  CLEAR_MARKETPLACE_ERROR = 'CLEAR_MARKETPLACE_ERROR_mkt_edit',
}

export interface MarketplaceEditAction {
  type: MarketplaceEditEvents
  payload?: any
}

export interface MarketplaceEdit {
  loadingMarketplaceEdit: boolean
  marketplaceEditResult: IMarketplace | null
  paginationLoading: boolean
  marketplaceErrorMessage: string
  marketplaceEditSuccess: boolean | undefined
}

export const marketplaceEdit: MarketplaceEdit = {
  loadingMarketplaceEdit: false,
  marketplaceEditResult: null,
  paginationLoading: false,
  marketplaceErrorMessage: '',
  marketplaceEditSuccess: undefined,
}

export const marketplaceEditReducer = (state: MarketplaceEdit = marketplaceEdit, action: MarketplaceEditAction) => {
  switch (action.type) {
    case MarketplaceEditEvents.MARKETPLACE_EDIT_RESULT:
      return {
        ...state,
        marketplaceEditResult: action.payload,
        loadingMarketplaceEdit: false,
        marketplaceErrorMessage: action.payload.error,
      }
    case MarketplaceEditEvents.SET_LOADING:
      return {
        ...state,
        loadingMarketplaceEdit: action.payload,
      }
    case MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT:
      return {
        ...state,
        loadingMarketplaceEdit: !!action.payload.loading,
        marketplaceErrorMessage: action.payload.error,
        marketplaceEditSuccess: action.payload.success,
      }
    case MarketplaceEditEvents.ERROR_MARKETPLACE_EDIT:
      return {
        ...state,
        marketplaceErrorMessage: action.payload.error,
        marketplaceEditSuccess: false,
        loadingMarketplaceEdit: false,
      }
    case MarketplaceEditEvents.CLEAR_ITEMS_MARKETPLACE_EDIT:
      return {
        ...state,
        loadingMarketplaceEdit: false,
        marketplaceEditResult: null,
        paginationLoading: false,
        marketplaceErrorMessage: '',
        marketplaceEditSuccess: undefined,
      }
    case MarketplaceEditEvents.CLEAR_MARKETPLACE_ERROR:
      return {
        ...state,
        loadingMarketplaceEdit: false,
        marketplaceErrorMessage: '',
        marketplaceEditSuccess: undefined,
      }
    default:
      return state
  }
}

export interface IMarketplaceEditActions {
  // setTerm: (term: string) => (dispatch: Dispatch) => void
  marketplaceEditGet: (idMarketplace: number, credentials: any) => (dispatch: Dispatch) => void
  updateMarketplace: (idMarketplace: number, props: IPutMarketplace, credentials: any) => (dispatch: Dispatch) => void
  setMarketplace: (props: IPostMarketplace, credentials: any) => (dispatch: Dispatch) => void
  // sellerPagination: (page: number, credentials: any) => (dispatch: Dispatch) => void
  clearMarketplace: () => MarketplaceEditAction
  clearMarketplaceError: () => MarketplaceEditAction
}

export const MarketPlaceActions: IMarketplaceEditActions = {
  marketplaceEditGet: (idMarketplace: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT, payload: { loading: true, error: false } })

    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    try {
      const response = await MarketplaceClient.getMarketplace(idMarketplace)
      dispatch({ type: MarketplaceEditEvents.MARKETPLACE_EDIT_RESULT, payload: response })
    } catch (err: any) {
      dispatch({
        type: MarketplaceEditEvents.ERROR_MARKETPLACE_EDIT,
        payload: { error: err.response.data.message },
      })
    }
  },

  updateMarketplace:
    (idMarketplace: number, props: IPutMarketplace, credentials: any) => async (dispatch: Dispatch) => {
      dispatch({ type: MarketplaceEditEvents.SET_LOADING, payload: true })
      dispatch({ type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT, payload: { loading: true, success: undefined } })

      const MarketplaceClient = Marketplace({
        headers: mountAuthHeaders(credentials),
      })

      const client = MarketplaceClient.getClient()

      interceptor(client, dispatch)

      try {
        await MarketplaceClient.updateMarketplace(idMarketplace, props)
        dispatch({
          type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT,
          payload: { success: true },
        })
      } catch (err: any) {
        dispatch({
          type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT,
          payload: { error: err?.response?.data?.message, success: false },
        })
      }
    },

  setMarketplace: (props: IPostMarketplace, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceEditEvents.MARKETPLACE_EDIT_RESULT, payload: props })
    dispatch({ type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT, payload: { loading: true, success: undefined } })

    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    try {
      const response = await MarketplaceClient.setMarketplace(props)
      dispatch({ type: MarketplaceEditEvents.MARKETPLACE_EDIT_RESULT, payload: response?.data })
      dispatch({ type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT, payload: { success: true, load: false } })
    } catch (err: any) {
      dispatch({
        type: MarketplaceEditEvents.LOAD_MARKETPLACE_EDIT,
        payload: { error: err?.response?.data?.message, success: false },
      })
    }
  },

  clearMarketplace: () => ({ type: MarketplaceEditEvents.CLEAR_ITEMS_MARKETPLACE_EDIT }),
  clearMarketplaceError: () => ({ type: MarketplaceEditEvents.CLEAR_MARKETPLACE_ERROR }),
}
