import { Dispatch } from 'redux'
import { Merchants, IMerchantResult, IGet, IGetMerchants } from '../resources/merchants'
import { mountAuthHeaders, interceptor } from '../utils'
import { IError } from '@pismo/sdk-core'

export enum SellerSearchEvents {
  SEARCH = 'SEARCH_sellerSearch',
  LOADING = 'LOADING_sellerSearch',
  TERM = 'TERM_sellerSearch',
  SEARCHRESULT = 'SEARCHRESULT_sellerSearch',
  PAGINATIONLOADING = 'PAGINATIONLOADING_sellerSearch',
  ERROR = 'ERROR_sellerSearch',
  CLEAR_ITEMS = 'CLEAR_ITEMS_sellerSearch',
}

export interface SellerSearchAction {
  type: SellerSearchEvents
  payload?: any
}

export interface SellerSearch {
  term: string
  loading: boolean
  searchResult: IMerchantResult | null
  paginationLoading: boolean
  error: string | null
}

export const sellerSearch: SellerSearch = {
  term: '',
  loading: false,
  searchResult: null,
  paginationLoading: false,
  error: null,
}

export const sellerSearchReducer = (state: SellerSearch = sellerSearch, action: SellerSearchAction) => {
  switch (action.type) {
    case SellerSearchEvents.LOADING:
      return { ...state, loading: action.payload }
    case SellerSearchEvents.SEARCHRESULT:
      return { ...state, searchResult: action.payload, loading: false }
    case SellerSearchEvents.ERROR:
      return { ...state, error: action.payload?.error?.message, loading: false }
    default:
      return state
  }
}

export interface ISellerSearchActions {
  // setTerm: (term: string) => (dispatch: Dispatch) => void
  searchSeller: (credentials: any, props?: IGet) => (dispatch: Dispatch) => void
  // sellerPagination: (page: number, credentials: any) => (dispatch: Dispatch) => void
  clear: () => SellerSearchAction
}

export const SellerSearchActions: ISellerSearchActions = {
  // setTerm: (term: string) => (dispatch: Dispatch) => {
  //   dispatch({ type: SellerSearchEvents.SEARCH, payload: term })
  // },

  searchSeller: (credentials: any, props?: IGetMerchants) => async (dispatch: Dispatch) => {
    dispatch({ type: SellerSearchEvents.LOADING, payload: true })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response = await MerchantsClient.getMerchants(
      credentials.marketplace ? { ...props, marketplaceID: credentials.marketplace } : props,
    )

    if ((response as IError).error) {
      dispatch({ type: SellerSearchEvents.ERROR, payload: (response as IError).error.message })
      return
    }

    dispatch({ type: SellerSearchEvents.SEARCHRESULT, payload: response })
  },

  // sellerPagination: (page: number, credentials: any) => async (dispatch: Dispatch) => {
  //   dispatch({ type: SellerSearchEvents.PAGINATIONLOADING, payload: true })

  //   const response = await Marketplace({
  //     baseURL: String(process.env.REACT_APP_API_ADDRESS),
  //     headers: mountAuthHeaders(credentials),
  //   }).search({ search: sellerSearch.term, page })
  //   if (!response.hasOwnProperty('error') && response.hasOwnProperty('data')) {
  //     dispatch({ type: SellerSearchEvents.SEARCHRESULT, payload: response })
  //   } else {
  //     dispatch({ type: SellerSearchEvents.ERROR, payload: response })
  //   }
  // },

  clear: () => ({ type: SellerSearchEvents.CLEAR_ITEMS }),
}
