import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(255, 169, 69)',
      contrastText: '#333333',
    },
    success: {
      main: '#525061',
      contrastText: '#ffffff',
    },
  },
})
