import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@Components/button'
import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import CircularProgress from '@mui/material/CircularProgress'
import Autocomplete from '@mui/material/Autocomplete'
import AppBar from '@Components/appBar'
import debounce from 'lodash.debounce'

import { InputSearch } from '@Components/search/InputSearch'
import { FormControl } from '@Components/formControl'
import TextField from '@Components/textField'

import validate from 'validate.js'

import { UserscreenActions } from '../../store'

import { Typography } from '@mui/material'
import { RootState } from '../../store/Store'
import { useSnackbar } from 'notistack'

const {
  search,
  clearSearch,
  getRolesList,
  createUser,
  clearCreateResult,
  deleteUser,
  clearDeleteResult,
  updateUser,
  clearUpdateResult,
  searchMerchant,
  searchMarketplace,
} = UserscreenActions

const Users: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { searchResult, rolesList, createSuccess, deleteSuccess, updateSuccess } = useSelector(
    (state: RootState) => state.userscreen,
  )
  const user = useSelector((state: RootState) => state.user)

  const [searchError, setSearchError] = useState<string | null>(null)
  const [sending, setSending] = useState(false)

  const clearFormData = { name: '', email: '', selectedChips: {} }
  const [formData, setFormData] = useState<any>({})

  const [refreshSearch, setRefreshSearch] = useState(true)

  const dispatch = useDispatch()

  const location = useLocation()

  const schema = {
    email: {
      presence: { allowEmpty: false, message: i18n._(t`E-mail inválido`) },
      email: { message: i18n._(t`E-mail inválido`) },
    },
    name: {
      presence: { allowEmpty: false, message: i18n._(t`Insira um nome`) },
    },
    selectedChips: {
      length: (value) => {
        if (value) {
          return Object.keys(value).length === 0 ? { message: i18n._(t`Selecione ao menos uma regra:`) } : false
        }

        return false
      },
    },
  }

  useEffect(() => {
    if (!rolesList) {
      dispatch(getRolesList(user))
    }
  }, [rolesList])

  useEffect(() => {
    if (searchResult !== null) {
      const chips = {}
      searchResult.roles.map((r) => (chips[r] = true))
      setFormData({ name: searchResult.name, email: searchResult.email, selectedChips: chips })
    } else {
      setFormData(clearFormData)
    }
  }, [searchResult])

  useEffect(() => {
    if (searchError) {
      setSearchError(null)
    }
  }, [location.search])

  const showFeedback = (status: boolean) => {
    if (status) {
      enqueueSnackbar(i18n._(t`dataSuccessfullySaved`), {
        variant: 'success',
      })
    } else {
      enqueueSnackbar(i18n._(t`genericApiError`), {
        variant: 'success',
      })
    }
  }

  useEffect(() => {
    if (createSuccess !== null) {
      setSending(false)
      setFormData(clearFormData)
      dispatch(clearCreateResult())
      showFeedback(createSuccess)
    }
  }, [createSuccess])

  useEffect(() => {
    if (updateSuccess !== null) {
      setSending(false)
      showFeedback(updateSuccess)
      setFormData(clearFormData)
      dispatch(clearUpdateResult())
    }
  }, [updateSuccess])

  useEffect(() => {
    if (deleteSuccess !== null) {
      setSending(false)
      showFeedback(deleteSuccess)
      setFormData(clearFormData)
      dispatch(clearDeleteResult())
    }
  }, [deleteSuccess])

  const onSearch = (term: string) => {
    const isError = validate({ term }, { term: { email: true } })

    if (isError) {
      return setSearchError(i18n._(t`E-mail inválido`))
    }

    dispatch(search(term, user))
  }

  const onSubmit = (values) => {
    setSending(true)

    const res: any = { name: values.name, email: values.email, roles: Object.keys(values.selectedChips) }

    if (!searchResult || values.email !== searchResult.email) {
      if (values.isMerchant) {
        res.merchant = values.merchant.id
      }

      if (values.isMarketplace) {
        res.marketplace = values.marketplace.id
      }

      dispatch(createUser(res, user))
    } else {
      res.tenant = searchResult.tenant

      dispatch(updateUser(res, user))
    }
  }

  const onDelete = () => {
    setSending(true)
    dispatch(deleteUser(searchResult.email, user))
  }

  const getFormData = (_, data) => {
    if (searchResult && data.email !== searchResult.email) {
      dispatch(clearSearch())
      setRefreshSearch(false)

      // setFormData({ ...data })
      setTimeout(() => {
        setRefreshSearch(true)
        setFormData((d) => ({ ...data, selectedChips: {} }))
      }, 10)
      return
    }
    setFormData(data)
  }

  const onValidationHandler = (val) => {
    const scm = { ...schema }
    if (val.isMerchant) {
      scm['merchant.id'] = {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Selecione um Seller`),
        },
      }
    }

    if (val.isMarketplace) {
      scm['marketplace.id'] = {
        presence: {
          allowEmpty: false,
          message: i18n._(t`Selecione um Marketplace`),
        },
      }
    }
    return scm
  }

  return (
    <Page>
      <AppBar title={i18n._(t`Usuários`)}>
        {refreshSearch && (
          <InputSearch
            inputPlaceholder={i18n._(t`Pesquise por usuário (e-mail)`)}
            onClickSearch={onSearch}
            error={Boolean(searchError)}
            helperText={searchError ?? ''}
          />
        )}
      </AppBar>
      <PageContent>
        <FormControl
          initialValue={{
            name: formData?.name,
            email: formData?.email,
            selectedChips: formData?.selectedChips,
          }}
          onSubmit={onSubmit}
          validationSchema={schema}
          onChange={getFormData}
          onValidation={onValidationHandler}
        >
          {(data) => (
            <FormView
              {...data}
              searchResult={searchResult}
              rolesList={rolesList}
              sending={sending}
              onDelete={onDelete}
            />
          )}
        </FormControl>
      </PageContent>
    </Page>
  )
}

const FormView = ({ values, errors, handleChange, searchResult, rolesList, sending, onDelete }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { merchantList, marketplaceList } = useSelector((state: RootState) => state.userscreen)
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const [blocked, setBlocked] = useState<Array<string>>([])
  const [chips, setChips] = useState<any>({})
  const [isMarketplace, setIsMarketplace] = useState(false)
  const [isMerchant, setIsMerchant] = useState(false)

  const [merchantSelected, setMerchantSelected] = useState<any>({ merchant_name: '' })
  const [marketplaceSelected, setMarketplaceSelected] = useState<any>({ name: '' })
  const [allBlocked, setAllBlocked] = useState(false)

  const [refreshRoles, setRefreshRoles] = useState(true)

  useEffect(() => {
    if (values.selectedChips && Object.keys(values.selectedChips).length === 0) setChips({})
  }, [values.selectedChips])

  useEffect(() => {
    if (JSON.stringify(chips) !== JSON.stringify(values.selectedChips)) {
      if (errors.selectedChips) {
        delete errors.selectedChips
      }
      handleChange({ target: { name: '', value: '' } }, { ...values, selectedChips: { ...chips } })
    }
  }, [chips, values])

  useEffect(() => {
    if (isMerchant && (!searchResult || searchResult.email !== values.email)) {
      dispatch(searchMerchant({ perPage: 10 }, user))
    }
  }, [isMerchant])

  useEffect(() => {
    if (isMarketplace && (!searchResult || searchResult.email !== values.email)) {
      dispatch(searchMarketplace({ per_page: 10 }, user))
    }
  }, [isMarketplace])

  useEffect(() => {
    // if (!searchResult) {
    setBlocked([])

    setRefreshRoles(false)
    setIsMarketplace(false)
    setIsMerchant(false)

    setTimeout(() => {
      setChips({})
      setRefreshRoles(true)
    }, 10)
    // }
  }, [searchResult])

  useEffect(() => {
    if (!searchResult) return setAllBlocked(false)
    if (
      (isMerchant || isMarketplace) &&
      searchResult.email === values.email &&
      searchResult.roles.some((r) => /(merchant|marketplace)/.test(r))
    ) {
      setAllBlocked(true)
    } else {
      setAllBlocked(false)
    }
  }, [isMerchant, isMarketplace, searchResult, values.email])

  const changeChip = (chip: { label: string; value: boolean; blocked: boolean }) => {
    setTimeout(() => {
      setChips((_chips) => {
        const list = { ..._chips }
        if (chip.value) {
          list[chip.label] = chip.value
        } else {
          delete list[chip.label]
        }
        if (!chip.blocked) {
          const keys = Object.keys(list).map((name) => {
            const isMerchantSet = /merchant/.test(name) && /marketplace/.test(name)
            const isMarketplaceSet = /marketplace/.test(name) && !/merchant/.test(name)
            if (isMerchantSet) {
              setBlocked(['marketplace-manager', 'marketplace-administrator'])
              setIsMerchant(true)
              setIsMarketplace(false)
            }
            if (isMarketplaceSet) {
              setBlocked(['marketplace-merchant-manager'])
              setIsMarketplace(true)
              setIsMerchant(false)
            }
            if (isMerchantSet || isMarketplaceSet) return true
            return name
          })
          if (keys.length === 0 || !keys.includes(true)) {
            setBlocked([])
            setIsMerchant(false)
            setIsMarketplace(false)
          }
        }
        return { ...list }
      })
    }, 10)
  }

  const search = (type: string) => (_, value) => {
    const v = value

    if (value.length >= 4 && value !== 'undefined') {
      fetch(type, v)
    }
  }

  const linkAccount = (type: string) => (_, value) => {
    fetch.cancel()

    switch (type) {
      case 'merchant':
        handleChange({ target: { name: 'merchant', value } })
        setMerchantSelected(value)
        break
      case 'marketplace':
        handleChange({ target: { name: 'marketplace', value } })
        setMarketplaceSelected(value)
        break
    }
  }

  const fetch = debounce((type, v) => {
    switch (type) {
      case 'merchant':
        return dispatch(searchMerchant({ name: v, perPage: 10 }, user))
      case 'marketplace':
      default:
        return dispatch(searchMarketplace({ search: v, per_page: 10 }, user))
    }
  }, 500)

  const handleSave = () => {
    handleChange(
      { target: { name: '', value: '' } },
      { isMarketplace, isMerchant, merchant: merchantSelected, marketplace: marketplaceSelected },
    )
  }

  return (
    <Fragment>
      <Box px='20px' py='30px' data-testid='edition-box'>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <TextField
              required
              name='name'
              label={i18n._(t`nome`)}
              value={values.name}
              error={Boolean(errors.name)}
              helperText={Boolean(errors.name) ? errors.name : ''}
              onChange={handleChange}
              inputProps={{ 'data-testid': 'name' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              name='email'
              label={i18n._(t`e-mail`)}
              value={values.email}
              error={Boolean(errors.email)}
              helperText={Boolean(errors.email) ? errors.email : ''}
              onChange={handleChange}
              inputProps={{ 'data-testid': 'email' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Box display='flex' alignItems='center' height='100%'>
              {sending ? (
                <Box width={1} display='flex' justifyContent='center' height={1} alignItems='center'>
                  <CircularProgress color='success' />
                </Box>
              ) : (
                <Box width={1} display='flex' flexDirection='column'>
                  <Box width={1}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='success'
                      data-testid='save-button'
                      onClick={handleSave}
                      disabled={allBlocked}
                    >
                      {i18n._(t`salvar`)}
                    </Button>
                  </Box>
                  {searchResult && searchResult.email === values.email && (
                    <Box width={1} mt='10px'>
                      <Button variant='contained' color='primary' onClick={onDelete} data-testid='delete-button'>
                        {i18n._(t`deletar`)}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          {isMerchant && merchantList && !allBlocked && (
            <Fragment>
              <Grid item xs={12}>
                <Autocomplete
                  options={merchantList.items}
                  clearOnEscape
                  autoHighlight
                  getOptionLabel={(option: any) => `${option?.merchant_name}`}
                  value={merchantSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n._(t`Vincular conta a um Seller`)}
                      helperText={errors?.merchant?.id}
                      error={Boolean(errors.merchant)}
                      data-testid='creditor-input'
                    />
                  )}
                  onChange={linkAccount('merchant')}
                  onInputChange={search('merchant')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  {i18n._(t`Ao vincular um Seller, esta conta não poderá ser alterada no futuro.`)}
                </Typography>
              </Grid>
            </Fragment>
          )}

          {isMarketplace && marketplaceList && !allBlocked && (
            <Fragment>
              <Grid item xs={12}>
                <Autocomplete
                  options={marketplaceList.items}
                  clearOnEscape
                  autoHighlight
                  getOptionLabel={(option: any) => `${option.name}`}
                  value={marketplaceSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n._(t`Vincular conta a um Marketplace`)}
                      helperText={errors?.marketplace?.id}
                      error={Boolean(errors.marketplace)}
                      data-testid='creditor-input'
                    />
                  )}
                  onChange={linkAccount('marketplace')}
                  onInputChange={search('marketplace')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  {i18n._(t`Ao vincular um Marketplace, esta conta não poderá ser alterada no futuro.`)}
                </Typography>
              </Grid>
            </Fragment>
          )}

          {allBlocked && (
            <Grid item xs={12}>
              <Typography variant='h6'>
                {i18n._(
                  t`Esta conta está vinculada à um ${isMerchant ? 'Seller' : 'Marketplace'} e não pode ser alterada.`,
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      {rolesList && refreshRoles && (
        <Box>
          <Grid container spacing={1} data-testid='container-chips'>
            {Boolean(errors.selectedChips) && (
              <Grid item xs={12}>
                <Typography variant='caption'>{errors.selectedChips}</Typography>
              </Grid>
            )}
            {rolesList.map((role, index) => (
              <ChipItem
                key={`${role.name}-${index}`}
                label={role.name}
                blockeds={blocked}
                userRoles={searchResult !== null ? searchResult.roles : []}
                onChange={changeChip}
                blocked={allBlocked}
              />
            ))}
          </Grid>
        </Box>
      )}
    </Fragment>
  )
}

const ChipItem = ({ label, blockeds = [], userRoles = [], onChange, blocked }: any) => {
  const [isActive, setIsActive] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)

  useEffect(() => {
    if (blocked) return setIsBlocked(true)
    if (blockeds.includes(label)) {
      setIsActive(false)
      setIsBlocked(true)
      if (onChange) onChange({ label, value: false, blocked: true })
    } else setIsBlocked(false)
  }, [blockeds, blocked])

  useEffect(() => {
    if (userRoles.includes(label)) {
      setIsActive(true)
      if (onChange) onChange({ label, value: true, blocked: isBlocked })
    }
  }, [userRoles])

  const clicked = (e) => {
    const value = !isActive
    setIsActive(value)
    if (onChange) onChange({ label, value, blocked: isBlocked })
  }
  return (
    <Grid item xs={6} sm={4} lg={3}>
      <FormControlLabel
        label={<span data-testid='chip-label'>{label}</span>}
        control={
          <Switch
            color={isActive ? 'primary' : 'success'}
            onClick={clicked}
            disabled={isBlocked}
            checked={isActive}
            data-testid='chip-button'
            data-selected={isActive}
          />
        }
        data-testid='chip'
      />
    </Grid>
  )
}

export { Users }
