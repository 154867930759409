import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { RootState } from '../../store/Store'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as PismoWave } from '../../assets/pismo-wave.svg'
import { InfiniteRotate } from './styles'

const ModalLoader = (): JSX.Element => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  return (
    <Box
      height='100%'
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        backgroundColor: 'primary.main',
      }}
    >
      <Box height='5rem' width='5rem' mb={2}>
        <InfiniteRotate>
          <PismoWave key='logoOrage' />
        </InfiniteRotate>
      </Box>
      <Box>
        <Typography variant='h6'>{i18n ? i18n._(t`Carregando...`) : ''}</Typography>
      </Box>
    </Box>
  )
}

export { ModalLoader }
