import React, { useState, useEffect } from 'react'
import { Auth } from '@pismo/sdk-auth'
import { useDispatch } from 'react-redux'

import { UserActions, User } from './store'
import { getBaseAPIURL } from './utils'

import { ModalLoader } from '@Components/ModalLoader/ModalLoader'

const { updateUser, clear } = UserActions

export const PismoContext = React.createContext({ logout: () => {}, setIsLoggedIn: {}, isLoggedIn: false })

const PismoID: React.FC<any> = ({ children }: any) => {
  const baseAPIURL: string = getBaseAPIURL()
  const dispatch = useDispatch()

  const updateHandler = (_input: User) => {
    if (!_input) {
      return logout()
    }
    dispatch(updateUser(_input))
    setInput(_input)
  }

  const AppAuth = Auth({ baseURL: baseAPIURL, onUpdate: updateHandler, keepAlive: false })

  const [input, setInput] = useState<any>(null)

  const [isValid, setIsValid] = useState<boolean>(true)
  const [tokenRefresher] = useState<any>(null)

  useEffect(() => {
    initializeAuth()

    return () => {
      if (tokenRefresher) {
        tokenRefresher.stop()
      }
    }
  }, [])

  const initializeAuth = async () => {
    try {
      const data = await AppAuth.ensure()

      if (data) {
        await AppAuth.setSession(data)
        setIsValid(true)
      }

      return AppAuth.onUpdateProxy(tokenRefresher)
    } catch (err) {
      setIsValid(false)

      return AppAuth.resetSession()
    }
  }
  const logout = async () => {
    await AppAuth.resetSession()
    await AppAuth.logout()
    dispatch(clear())
    setIsValid(false)
    window.location.replace(`${url}?${paramReturn}`)
  }

  const url = String(process.env.REACT_APP_LOGIN_ADDRESS)
  const paramReturn = `returnTo=${window.location.origin}`

  if (isValid) {
    return (
      <PismoContext.Provider value={{ logout, setIsLoggedIn: setIsValid, isLoggedIn: isValid }}>
        {input && children}
      </PismoContext.Provider>
    )
  }

  return (
    <>
      <ModalLoader />
      {!isValid && setTimeout(() => window.location.replace(`${url}?${paramReturn}`), 2000)}
    </>
  )
}

export const usePismoID = () => React.useContext(PismoContext)
export { PismoID }
