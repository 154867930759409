import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { CreditorActions } from '../../store'
import { CreditorForm } from '../creditors/CreditorForm'
import { RootState } from '../../store/Store'
import { useSnackbar } from 'notistack'

const { updateCreditor, clearEditionResult, clear } = CreditorActions

const CreditorEdit = ({ loading }: any) => {
  const { id } = useParams<any>()
  const dispatch = useDispatch()

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { editionSuccess, openFeedback, errorMessage } = useSelector((state: RootState) => state.creditor)
  const user = useSelector((state: RootState) => state.user)
  const { enqueueSnackbar } = useSnackbar()

  // eslint-disable-next-line
  const [openBack, setOpenBack] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearEditionResult())
      dispatch(clear())
    }
  }, [])

  const onSubmit = (data) => {
    const { values, bank, details } = data
    const result = {
      name: values.name || null,
      crediting_method: values.methodCredit || null,
      crediting_frequency: values.receivingPeriod || null,
      crediting_frequency_day: values.receivingDay || null,
      cedente_id: values.bankCedent || null,
      advancement_fee: values.advancementFee || null,
      maximum_advancement_percentage: values.maximumAdvancementPercentage || null,
      discount_rate: values.discountRate || null,
      bank_code: values.bankSelect ? values.bankSelect.bank_code : null,
      account: {
        id: values.idAccount || null,
      },
      marketplaces: details.marketplaces,
      operations: details.operations,
      bank: bank,
    }
    dispatch(updateCreditor(id, user, result))
    setOpenBack(true)
  }

  useEffect(() => {
    if (openFeedback) {
      if (editionSuccess) {
        enqueueSnackbar(i18n._(t`Seus dados foram registrados com sucesso.`), {
          variant: 'success',
        })
      } else {
        enqueueSnackbar(errorMessage?.toString() || i18n._(t`genericApiError`), {
          variant: 'error',
        })
      }
    }
  }, [openFeedback])

  return (
    <Box data-testid='creditors-main-edit'>
      <Box>
        {loading ? (
          <Box display='flex' mt={12} width={1} height={1} justifyContent='center' alignItems='center'>
            <CircularProgress color='primary' />
          </Box>
        ) : (
          <Box data-testid='creditors-form-edit' pt='15px'>
            {<CreditorForm method={'edit'} handleSubmit={onSubmit} />}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export { CreditorEdit }
