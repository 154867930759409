import * as React from 'react'

import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import Fab from '@mui/material/Fab'

import CloseIcon from '@mui/icons-material/Close'

import { PismoBarProps, ApplicationsType } from './components/interfaces'
import { ApplicationToolbar } from './components/ApplicationToolbar'
import { ApplicationButton } from './components/ApplicationButton'

const { Fragment, useState } = React

const applications: ApplicationsType = {
  marketplace: {
    name: 'Marketplace',
    url: '',
  },
  adjustments: {
    name: 'Adjustments',
    url: '',
  },
  customer: {
    name: 'Customer',
    url: '',
  },
  backoffice: {
    name: 'Backoffice',
    url: '',
  },
  webpos: {
    name: 'WebPOS',
    url: '',
  },
}

const PismoBar: React.FC<PismoBarProps> = ({
  AppBarProps,
  ToolbarProps,
  current,
  appSelected,
  contract,
  full,
  statusApp = false,
  onClick,
}: PismoBarProps) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [countClick, setCountClick] = useState<number>(0)

  const toOpen = () => {
    if (onClick && countClick === 0) {
      setCountClick(1)
      onClick()
    } else {
      setCountClick(0)
      setIsOpen(true)
    }
  }

  const toClose = () => {
    setIsOpen(false)
  }

  const appSelectedHandler = ({ url }) => {
    if (appSelected) {
      appSelected({ url })
    }
  }

  return (
    <Fragment>
      <ApplicationToolbar
        applications={applications}
        AppBarProps={AppBarProps}
        ToolbarProps={ToolbarProps}
        current={current}
        onClick={toOpen}
        contract={contract}
        app={statusApp}
        full={full}
      />
      <Drawer
        className={`Bolt-PismoBar-drawer`}
        anchor={matches || contract ? 'left' : 'top'}
        open={isOpen}
        onClose={toClose}
        ModalProps={{ hideBackdrop: true }}
        data-testid='drawer'
      >
        <Box textAlign='center' py='15px'>
          <Fab variant='circular' color='secondary' onClick={toClose}>
            <CloseIcon />
          </Fab>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {Object.keys(applications).map((k, index) => (
              <ApplicationButton
                data={applications[k]}
                isSelected={k === current}
                key={`${k}-${index}`}
                onClick={appSelectedHandler}
              />
            ))}
          </Grid>
        </Box>

        <Box>
          <ApplicationToolbar
            applications={applications}
            AppBarProps={AppBarProps}
            ToolbarProps={ToolbarProps}
            current={current}
            onClick={toClose}
            full
          />
        </Box>
      </Drawer>
    </Fragment>
  )
}

export { PismoBar }
