import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Page from '@Components/pageContent'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import AppBar from '@Components/appBar'
import { CreditorActions } from '../../store'
import { CreditorForm } from '../creditors/CreditorForm'
import { RootState } from '../../store/Store'
import { useSnackbar } from 'notistack'

const { setCreditor } = CreditorActions

const CreditorNew = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { openFeedback, editionSuccess, errorMessage } = useSelector((state: RootState) => state.creditor)
  const user = useSelector((state: RootState) => state.user)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (openFeedback) {
      if (editionSuccess) {
        enqueueSnackbar(i18n._(t`Seus dados foram registrados com sucesso.`), {
          variant: 'success',
        })

        history.push('/creditors')
      } else {
        enqueueSnackbar(errorMessage?.toString() || i18n._(t`genericApiError`), {
          variant: 'error',
        })
      }
    }
  }, [openFeedback])

  const backToList = () => {
    history.push('/creditors')
  }

  const onSubmit = (data) => {
    const { values, bank } = data
    let result = {
      name: values.name || null,
      crediting_method: values.methodCredit || null,
      crediting_frequency: values.receivingPeriod || null,
      crediting_frequency_day: values.receivingDay || null,
      advancement_fee: values.advancementFee || null,
      maximum_advancement_percentage: values.maximumAdvancementPercentage || null,
      discount_rate: values.discountRate || null,
      bank: bank,
      operations: [
        {
          mdr: 0,
          first_installment_interval: 0,
          nth_installment_interval: 0,
          transaction_fee: 0,
          matched_settlement: false,
          matched_mdr: false,
          not_card_allowed: false,
          doc_number_allowed: false,
          operation_type: {
            id: 40,
          },
        },
      ],
    }
    dispatch(setCreditor(user, result))
  }

  return (
    <div className='container'>
      <AppBar back={backToList} title={i18n._(t`Novo Credor`)}></AppBar>
      <Page data-testid='creditors-main-new'>
        <Box data-testid='creditors-form-new' mt='20px'>
          <CreditorForm method={'new'} handleSubmit={onSubmit} />
        </Box>
      </Page>
    </div>
  )
}

export { CreditorNew }
