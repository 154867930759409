import * as React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ButtonBase from '@mui/material/ButtonBase'

import { ApplicationButtonProps } from './interfaces'

const ApplicationButton: React.FC<ApplicationButtonProps> = ({ data, isSelected, onClick }: ApplicationButtonProps) => {
  const clickHandler = () => {
    if (onClick) {
      onClick({ url: data.url })
    }
  }

  return (
    <Grid item xs={6}>
      <ButtonBase onClick={clickHandler}>
        <Box width={1} display='flex' flexDirection='column' alignItems='center' py='15px'>
          <Box mb='1rem' />
          <Typography variant='body1'>{data.name}</Typography>
        </Box>
      </ButtonBase>
    </Grid>
  )
}

export { ApplicationButton }
