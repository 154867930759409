import React, { memo, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useSelector, useDispatch } from 'react-redux'
import { TranslationActions } from '../../store'
import { RootState } from '../../store/Store'
import Button from '../button'
import Menu from '@mui/material/Menu'
import { PismoContext } from '../../PismoID'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { t } from '@lingui/macro'

const { changeLanguage } = TranslationActions

const StyledBox = styled(Box)({
  boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
})

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'lowercase',
  color: theme.palette.primary.contrastText,
  height: '100%',
}))

type Props = {
  back?: () => void
  title: string
  children?: React.ReactNode
}

const PageContent = ({ back, title, children }: Props) => {
  const dispatch = useDispatch()
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const { email } = useSelector((state: RootState) => state.user)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { logout } = useContext(PismoContext)

  const logoutClicked = () => {
    dispatch({ type: 'LOGOUT' })
    logout()
  }

  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeUserMenu = () => {
    setAnchorEl(null)
  }

  const setLanguage = (event) => {
    dispatch(changeLanguage(event.target.value))
  }
  return (
    <>
      <AppBar component='nav' position='relative'>
        <Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
          <Box mx='1rem' my={back ? '0,5rem' : '1rem'} display={'flex'} alignItems='center'>
            <Box data-testid='creditors-header-new'>
              {!!back && (
                <IconButton onClick={back} size='large'>
                  <ArrowBackIcon data-testid='creditors-header-arrow' />
                </IconButton>
              )}
            </Box>
            <Typography variant='h6'>{title}</Typography>
          </Box>
          <Box paddingRight={'1rem'} display='flex'>
            <FormControl>
              <Select value={language} onChange={setLanguage} displayEmpty>
                <MenuItem value={'en'}>EN</MenuItem>
                <MenuItem value={'es'}>ES</MenuItem>
                <MenuItem value={'pt'}>PT</MenuItem>
              </Select>
            </FormControl>

            <Box>
              <StyledButton
                aria-controls='simple-menu'
                aria-haspopup='true'
                color='primary'
                variant='outlined'
                onClick={openUserMenu}
                startIcon={<AccountCircle />}
                size='large'
              >
                {email}
              </StyledButton>
              <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeUserMenu}>
                <MenuItem onClick={logoutClicked}>{i18n._(t`sair`)}</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </AppBar>
      {!!children && (
        <StyledBox px='1rem' py='.8rem'>
          {children}
        </StyledBox>
      )}
    </>
  )
}

export default memo(PageContent)
