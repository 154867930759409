import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/Store'
import Backdrop from '@mui/material/Backdrop'
import { theme } from '@Commons/themes/Theme'

const zIndex = theme.zIndex.drawer + 1

const Loading = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  return (
    <Box display='flex' width={1} height={1} justifyContent='center' alignItems='center' flexDirection='column'>
      <Box mb='20px'>
        <Typography component='div' variant='h6' sx={{ ml: 1 }} color='primary'>
          {i18n._(t`Carregando...`)}
        </Typography>
      </Box>
      <div>
        <CircularProgress color='primary' />
      </div>
    </Box>
  )
}

export const BackdropLoading = ({ loading }: { loading: boolean }) => (
  <Backdrop sx={{ color: (theme) => theme.palette.background.default, zIndex: zIndex }} open={loading}>
    <CircularProgress color='inherit' />
  </Backdrop>
)

export default Loading
