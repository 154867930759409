import React, { useEffect, useState, Fragment } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { Snackbar } from '@Components/snackbar'

import { InputSearch, SearchResultsTyped } from '@Components/search'

import { SellerSearchActions, MarketplaceEstablishmentsActions } from '../../../store'
import { RootState } from '../../../store/Store'
import { Switch } from '@mui/material'

const { searchSeller, clear } = SellerSearchActions
const { getLinkedItem, linkMerchant, unlinkMerchant, clearToggleLinkError, clearUploadResult } =
  MarketplaceEstablishmentsActions

const MarketplaceEstablishments = ({ marketplace }: any) => {
  const dispatch = useDispatch()
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { loading, searchResult } = useSelector((state: RootState) => state.sellersearch)
  const { linkedList, linkError, uploadResult } = useSelector((state: RootState) => state.marketplaceEstablishments)
  const user = useSelector((state: RootState) => state.user)
  const [linkedResult, setLinkedResult] = useState<any>(null)
  const [openFeedback, setOpenfeedback] = useState(false)
  const [isError, setIsError] = useState(true)
  const [isFiltered, setIsFiltered] = useState(false)
  const [openUploadError, setUploadError] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    dispatch(clear())
    dispatch(searchSeller(user))
    dispatch(getLinkedItem(marketplace.id, user))
  }, [])

  useEffect(() => {
    if (linkError !== null) {
      dispatch(clearToggleLinkError())
      setOpenfeedback(true)
    }
  }, [linkError])

  useEffect(() => {
    if (uploadResult !== null) {
      dispatch(clearUploadResult())
      if (uploadResult) {
        setIsError(false)
      }

      setOpenfeedback(true)
    }
  }, [uploadResult])

  useEffect(() => {
    if (searchResult && searchResult.total_items > 0 && linkedList) {
      if (isFiltered) {
        filterMerchants()
      } else {
        let res = searchResult.items.map((el) => ({
          ...el,
          isLinked: Boolean(linkedList.find((linked) => el.id === linked.merchant_id)),
        }))
        setLinkedResult({ ...searchResult, items: res })
      }
    } else {
      setLinkedResult(null)
    }
  }, [searchResult, linkedList])

  const filterMerchants = () => {
    let res = {
      total_items: linkedList?.length,
      per_page: linkedList?.length,
      pages: 1,
      items: linkedList?.map((el) => ({
        ...el,
        id: el.merchant_id,
        isLinked: true,
      })),
    }
    if (res.items?.length) {
      setLinkedResult(res)
    } else {
      setLinkedResult(null)
    }
    setIsFiltered(true)
  }

  const toggleFilter = () => {
    if (isFiltered === false) {
      filterMerchants()
    } else {
      dispatch(searchSeller(user))
      setIsFiltered(false)
    }
  }

  const searchByTerm = (name: string) => {
    setSearchTerm(name)
    if (isFiltered === false) {
      dispatch(searchSeller(user, { name }))
    } else {
      // Search merchant name in filtered list
      let res = linkedList
        ?.filter((el) => el.merchant_name.toLowerCase().includes(name.toLowerCase()))
        .map((el) => ({
          ...el,
          isLinked: true,
        }))
      setLinkedResult({ ...linkedResult, items: res })
    }
  }

  const onChangePage = (nextPage: number) => {
    dispatch(searchSeller(user, { page: nextPage, name: searchTerm }))
  }

  const toggleLink = (item) => () => {
    if (!item.isLinked) {
      dispatch(linkMerchant(marketplace.id, item.id, user))
    } else {
      dispatch(unlinkMerchant(marketplace.id, item.id, user))
    }
  }

  const closeFeedback = () => {
    if (!isError) {
      dispatch(getLinkedItem(marketplace.id, user))
    }

    setOpenfeedback(false)
    setUploadError(false)
    setIsError(true)
  }
  return (
    <Fragment>
      <Box p='15px'>
        <Box>
          <InputSearch inputPlaceholder={i18n._(t`Pesquise um estabelecimento`)} onClickSearch={searchByTerm} />
        </Box>
        <Box display='flex' width={1} justifyContent='flex-start' marginTop='2rem' alignItems='center'>
          <Switch color='primary' checked={isFiltered} onChange={toggleFilter} data-testid='merchant-filter-button' />
          <span>{i18n._(t`Exibir apenas estabelecimentos vinculados`)}</span>
        </Box>

        <Box display='flex' flexDirection='column'>
          <SearchResultsTyped
            header={[
              { title: i18n._(t`establishmentName`), prop: 'merchant_name' },
              { title: i18n._(t`documentNumber`), prop: 'document_number' },
              {
                title: '',
                cellComponent: (item: any) => {
                  return (
                    <Button
                      variant='contained'
                      color={item.isLinked ? 'success' : 'primary'}
                      style={{ maxWidth: '15.625rem' }}
                      onClick={toggleLink(item)}
                      data-testid={`toggle-link-${item.id}`}
                    >
                      {item.isLinked ? i18n._(t`desvincular`) : i18n._(t`vincular`)}
                    </Button>
                  )
                },
              },
            ]}
            data={linkedResult}
            changePage={onChangePage}
            loading={loading}
          />
        </Box>
      </Box>
      <Snackbar
        open={openFeedback}
        variant={isError ? 'error' : 'success'}
        message={
          isError
            ? i18n._(t`Falha no envio dos dados. Por favor, tente novamente mais tarde`)
            : i18n._(t`Dados enviados com sucesso.`)
        }
        onClose={closeFeedback}
        data-testid='snackbar'
      />
      <Snackbar
        open={openUploadError}
        variant='error'
        message={i18n._(t`Formato não suportado.`)}
        onClose={closeFeedback}
        data-testid='snackbar'
      />
    </Fragment>
  )
}

export { MarketplaceEstablishments }
