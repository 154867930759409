import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import { FormControl } from '@Components/formControl'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { CurrencyInput } from '@Components/currency-input'
import Typography from '@mui/material/Typography'
import { PercentInput } from '@Components/percentageInput'
import { CreditorActions } from '../../store'
import { RootState } from '../../store/Store'
import Grid from '@mui/material/Grid'
import Banklist from '../../resources/data/listBanksBrasil.json'
import { Bank } from 'src/resources/merchants'
import CircularProgress from '@mui/material/CircularProgress'
import { Autocomplete } from '@mui/material'
import { ContentBankType } from './Interfaces'

const { clear } = CreditorActions

export interface Header {
  method: string
  handleSubmit: (item: any) => void
}

const CreditorForm: React.FC<Header> = ({ method, handleSubmit }: Header) => {
  const listBank: Bank[] = Banklist.data
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { creditorResult, loadingCreditor } = useSelector((state: RootState) => state.creditor)
  const [agency_init, agency__init_dv] =
    creditorResult && creditorResult.bank && creditorResult.bank.agency && method !== 'new'
      ? creditorResult.bank.agency.split('-')
      : [null, null]
  const [count_currency, count_currency_dv] =
    creditorResult && creditorResult.bank && creditorResult.bank.account && method !== 'new'
      ? creditorResult.bank.account.split('-')
      : [null, null]

  const [bank_Select, setBankSelect] = useState<Bank | undefined>()
  const [credit_method, setCredit_method] = useState(creditorResult ? creditorResult.crediting_method : null)
  const [type_account, setType_account] = useState(creditorResult ? creditorResult?.bank?.type : null)

  const schemaPlatform = {
    name: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, preencha o campo nome`),
      },
    },
    methodCredit: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione uma opção`),
      },
    },
    receivingPeriod: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione uma opção`),
      },
    },
    receivingDay: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione uma opção`),
      },
    },
  }

  const schemaBank = {
    ...schemaPlatform,
    bankSelect: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione um banco`),
      },
    },
    typeAccount: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, selecione o tipo da conta.`),
      },
    },
    agency: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, digite o numero da agencia`),
      },
    },
    currentAccount: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, digite o numero da conta`),
      },
    },
    currentAccountDv: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, digite o numero`),
      },
    },
  }
  const resetVariables = () => {
    setBankSelect(undefined)
    setType_account(null)
    setCredit_method(null)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    if (listBank && creditorResult) {
      setBankSelect(listBank.find((el) => el.bank_id === creditorResult?.bank?.id))
    }
  }, [listBank])

  useEffect(() => {
    if (method === 'new') {
      dispatch(clear())
      resetVariables()
    }
  }, [method])

  const onSubmitHandler = (values) => {
    let bank = {}
    let editDetails = {}

    if (values.methodCredit === 'BANK' && values.bankSelect && Object.keys(values.bankSelect).length > 0) {
      bank = {
        account: `${values.currentAccount}-${values.currentAccountDv}`,
        account_dv: values.currentAccountDv,
        account_number: values.currentAccount,
        agency: `${values.agency}-${values.agencyDv}`,
        agency_dv: values.agencyDv || null,
        agency_number: values.agency,
        type: values.typeAccount,
        id: values.bankSelect.bank_id || null,
      }
    }

    editDetails = {
      account: creditorResult ? creditorResult.account : null,
      marketplaces: creditorResult ? creditorResult.marketplaces : null,
      operations: creditorResult ? creditorResult.operations : null,
    }

    const dataResult = {
      values: values,
      bank: bank,
      details: editDetails,
    }

    handleSubmit(dataResult)
  }

  const validationHandler = (values) => {
    if (values.methodCredit === 'BANK') {
      return schemaBank
    } else {
      return schemaPlatform
    }
  }

  const valueInitReceivingDay = () => {
    let resultDay

    if (creditorResult === null) return

    switch (creditorResult.crediting_frequency) {
      case 'DAILY':
        resultDay = method === 'edit' ? 0 : ''
        return resultDay
      default:
        resultDay = method === 'edit' ? (resultDay = creditorResult.crediting_frequency_day) : ''
        return resultDay
    }
  }

  return (
    <Box>
      <FormControl
        initialValue={{
          name: creditorResult ? creditorResult.name : '',
          bankSelect: bank_Select,
          agency: agency_init,
          agencyDv: agency__init_dv,
          currentAccount: count_currency,
          currentAccountDv: count_currency_dv,
          idAccount: creditorResult ? creditorResult?.account?.id : null,
          receivingPeriod: creditorResult ? creditorResult.crediting_frequency : '',
          receivingDay: creditorResult ? valueInitReceivingDay() : '',
          methodCredit: credit_method,
          typeAccount: type_account,
          advancementFee: creditorResult && method !== 'new' ? creditorResult.advancement_fee : 0,
          discountRate: creditorResult && method !== 'new' ? creditorResult.discount_rate : 0,
          maximumAdvancementPercentage:
            creditorResult && method !== 'new' ? creditorResult.maximum_advancement_percentage : 0,
        }}
        onValidation={validationHandler}
        onSubmit={onSubmitHandler}
      >
        {(data) => {
          return <CreditorLayout loadingCreditor={loadingCreditor} {...data} banklist={listBank} method={method} />
        }}
      </FormControl>
    </Box>
  )
}

const CreditorLayout: React.FC<any> = ({ values, handleChange, errors, banklist, method, loadingCreditor }: any) => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)

  const commonMask = { placeholder: ' ', autoUnmask: true, showMaskOnFocus: false, showMaskOnHover: false }

  let crediting_day
  const handleSelect = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value } })
  }

  const handlePeriod = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value } })

    if (item.target.value === 'DAILY') {
      handleChange({ target: { name: 'receivingDay', value: 0 } })
    } else {
      handleChange({ target: { name: 'receivingDay', value: '' } })
    }
  }

  const handleDays = (handleChange: (prop: any) => void) => (item) => {
    let valueDays = crediting_day === 0 ? 0 : item.target.value
    handleChange({ target: { name: item.target.name, value: valueDays } })
  }

  const DayMonth = () => {
    let days = {}

    for (let day = 1; day < 29; day++) {
      days[day - 1] = {
        name: day,
        value: day,
      }
    }
    return days
  }

  switch (values.receivingPeriod) {
    case 'DAILY':
      crediting_day = 0
      break
    case 'WEEKLY':
      crediting_day = [
        {
          name: i18n._(t`Segunda-feira`),
          value: 2,
        },
        {
          name: i18n._(t`Terça-feira`),
          value: 3,
        },
        {
          name: i18n._(t`Quarta-feira`),
          value: 4,
        },
        {
          name: i18n._(t`Quinta-feira`),
          value: 5,
        },
        {
          name: i18n._(t`Sexta-feira`),
          value: 6,
        },
      ]
      break
    default:
      crediting_day = DayMonth()
      break
  }

  const ContentBank = ({ values, itemErros }: ContentBankType) => {
    const changeBankCedent = (handleChange: (prop: any) => void) => (ev, value) => {
      handleChange({ target: { name: 'bankSelect', value } })
    }
    return (
      <Fragment>
        <br />
        <Grid container spacing={2}>
          <br />
          {banklist && (
            <Grid item xs={6} data-testid='creditors-input-nameBank'>
              <Autocomplete
                options={banklist}
                clearOnEscape
                autoHighlight
                getOptionLabel={(option: any) => `${option.bank_code} - ${option.bank_name}`}
                value={values?.bankSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n._(t`Banco`)}
                    helperText={errors.bankSelect}
                    error={Boolean(errors.bankSelect)}
                  />
                )}
                onChange={changeBankCedent(handleChange)}
              />
            </Grid>
          )}

          <Grid item xs={6} data-testid='creditors-input-typeAccount'>
            <TextField
              label={i18n._(t`Tipo de conta`)}
              name='typeAccount'
              select
              onChange={handleSelect(handleChange)}
              value={values?.typeAccount}
              helperText={itemErros?.typeAccount}
              error={Boolean(itemErros?.typeAccount)}
              required
            >
              <MenuItem key={'method-0'} value={'CA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta corrente`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'DA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta Deposito`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'PA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta Pagamento`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'SA'} data-testid='creditor-typeAccount-options'>
                {i18n._(t`Conta Poupança`)}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={3} data-testid='creditors-input-agency'>
            <TextField
              label={i18n._(t`Agência`)}
              name='agency'
              onChange={handleChange}
              value={values?.agency}
              helperText={itemErros?.agency}
              error={Boolean(itemErros?.agency)}
              mask
              maskOptions={{ mask: '9', repeat: 10, ...commonMask }}
              required
            />
          </Grid>
          <Grid item xs={3} data-testid='creditors-input-agencyDv'>
            <TextField
              label={i18n._(t`DV`)}
              name='agencyDv'
              onChange={handleChange}
              value={values?.agencyDv}
              helperText={itemErros?.agencyDv}
              error={Boolean(itemErros?.agencyDv)}
              mask
              maskOptions={{ regex: '[a-zA-Z0-9]{3}', ...commonMask }}
              required
            />
          </Grid>
          <Grid item xs={3} data-testid='creditors-input-currentAccount'>
            <TextField
              label={i18n._(t`Conta Corrente`)}
              name='currentAccount'
              onChange={handleChange}
              value={values?.currentAccount}
              helperText={itemErros?.currentAccount}
              error={Boolean(itemErros?.currentAccount)}
              mask
              maskOptions={{ mask: '9', repeat: 15, ...commonMask }}
              required
            />
          </Grid>
          <Grid item xs={3} data-testid='creditors-input-currencyAccountDv'>
            <TextField
              label={i18n._(t`DV`)}
              name='currentAccountDv'
              onChange={handleChange}
              value={values?.currentAccountDv}
              helperText={itemErros?.currentAccountDv}
              error={Boolean(itemErros?.currentAccountDv)}
              mask
              maskOptions={{ regex: '[a-zA-Z0-9]{3}', ...commonMask }}
              required
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
  return (
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} data-testid='creditors-input-name'>
            <TextField
              label={i18n._(t`Nome`)}
              name='name'
              onChange={handleChange}
              value={values?.name}
              helperText={errors?.name}
              error={Boolean(errors?.name)}
              required
            />
          </Grid>
          <Grid item xs={6} data-testid='creditors-input-methodCredit'>
            <TextField
              label={i18n._(t`Método de crédito`)}
              name='methodCredit'
              select
              onChange={handleSelect(handleChange)}
              value={values?.methodCredit}
              helperText={errors?.methodCredit}
              error={Boolean(errors?.methodCredit)}
              placeholder={i18n._(t`accountTypeForSettlement`)}
              required
            >
              <MenuItem key={'method-0'} value={'ACCOUNT'} data-testid='creditor-method-options'>
                {i18n._(t`platformAccount`)}
              </MenuItem>
              <MenuItem key={'method-1'} value={'BANK'} data-testid='creditor-method-options'>
                {i18n._(t`Banco`)}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {values.methodCredit === 'BANK' ? <ContentBank values={values} itemErros={errors} /> : ''}
        <br />
        <Grid container spacing={2}>
          {method === 'edit' && (
            <Grid item xs={6} data-testid='creditors-input-idAccount'>
              <TextField
                label={i18n._(t`Id da conta`)}
                name='idAccount'
                onChange={handleChange}
                value={values?.idAccount ? values?.idAccount : '-'}
                helperText={errors?.idAccount}
                error={Boolean(errors?.idAccount)}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={6} data-testid='creditors-input-receivingPeriod'>
            <TextField
              label={i18n._(t`Período de recebimento`)}
              name='receivingPeriod'
              onChange={handlePeriod(handleChange)}
              select
              value={values?.receivingPeriod}
              helperText={errors?.receivingPeriod}
              error={Boolean(errors?.receivingPeriod)}
              required
            >
              <MenuItem key={'pReceivement-1'} value={'DAILY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Diário`)}
              </MenuItem>
              <MenuItem key={'pReceivement-2'} value={'WEEKLY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Semanal`)}
              </MenuItem>
              <MenuItem key={'pReceivement-3'} value={'BIWEEKLY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Quinzenal`)}
              </MenuItem>
              <MenuItem key={'pReceivement-4'} value={'MONTHLY'} data-testid='creditor-receivingPeriod-options'>
                {i18n._(t`Mensal`)}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} data-testid='creditors-input-receivingDay'>
            <TextField
              label={i18n._(t`Dia de recebimento`)}
              name='receivingDay'
              select
              onChange={handleDays(handleChange)}
              value={values?.receivingDay}
              helperText={errors?.receivingDay}
              error={Boolean(errors?.receivingDay)}
              disabled={!crediting_day}
              required
            >
              {crediting_day ? (
                Object.keys(crediting_day).map((res) => {
                  return (
                    <MenuItem
                      key={crediting_day[res].value}
                      value={crediting_day[res].value}
                      data-testid='creditor-receivingDay-options'
                    >
                      {crediting_day[res].name}
                    </MenuItem>
                  )
                })
              ) : (
                <MenuItem key={0} value={crediting_day === 0 ? 0 : -1} data-testid='creditor-receivingDay-options'>
                  -
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
        <br />
      </Box>
      <Box>
        <Box my='15px'>
          <Typography variant='h6' gutterBottom>
            {i18n._(t`Dados de antecipação`)}
          </Typography>
        </Box>
        <Box display='flex' width={1} height={1} flexDirection='row' justifyContent='space-around'>
          <Box width={1 / 3} pr={'20px'} data-testid='creditors-input-advancementFee'>
            <CurrencyInput
              lang={language}
              initialValue={values.advancementFee}
              TextfieldProps={{
                label: i18n._(t`Tarifa de Antecipação`),
                name: 'advancementFee',
              }}
              onChange={(val) => {
                handleChange({ target: { name: 'advancementFee', value: val } })
              }}
            />
          </Box>
          <Box width={1 / 3} pr={'20px'} data-testid='creditors-input-discountRate'>
            <PercentInput
              TextfieldProps={{
                label: i18n._(t`Taxa de desconto`),
                name: 'discountRate',
              }}
              initialValue={values.discountRate}
              onChange={(val) => {
                handleChange({ target: { name: 'discountRate', value: val } })
              }}
            />
          </Box>
          <Box width={1 / 3} data-testid='creditors-input-maxPercentage'>
            <PercentInput
              TextfieldProps={{
                label: i18n._(t`Porcentagem Máx de antecipação`),
                name: 'maximumAdvancementPercentage',
              }}
              initialValue={values.maximumAdvancementPercentage}
              onChange={(val) => {
                handleChange({ target: { name: 'maximumAdvancementPercentage', value: val } })
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box width={1} display='flex' justifyContent='center' py='50px'>
        <Button
          startIcon={loadingCreditor && <CircularProgress color='inherit' size={16} />}
          disabled={loadingCreditor}
          type='submit'
          color='primary'
          variant='contained'
          data-testid='creditors-input-salvar'
          fullWidth
        >
          {i18n._(t`Salvar`)}
        </Button>
      </Box>
    </Box>
  )
}

export { CreditorForm }
