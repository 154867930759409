import React, { useEffect, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import AppBar from '@Components/appBar'
import Page from '@Components/pageContent'

import { Top } from '@Components/creditors'
import { CreditorActions } from '../../store'
import { CreditorEdit } from './CreditorEdit'
import { CreditorSellers } from './CreditorSellers'

import Home from '@mui/icons-material/Home'
import { ReactComponent as BagIcon } from '../../assets/bag.svg'
import { RootState } from '../../store/Store'

const { getProfileCreditor } = CreditorActions

const CreditorContainer: React.FC = () => {
  const { id, page } = useParams<any>()

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { creditorResult } = useSelector((state: RootState) => state.creditor)
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  const history = useHistory()

  const menuElements = [
    {
      Icon: Home,
      label: i18n._(t`home`),

      name: 'home',
    },
    {
      Icon: BagIcon,
      label: i18n._(t`Estabelecimentos`),
      name: 'sellers',
    },
  ]

  useEffect(() => {
    if (!creditorResult || creditorResult?.id?.toString() !== id) {
      dispatch(getProfileCreditor(id, user))
    }
  }, [])

  const onBack = () => history.push('/creditors')
  const menuClicked = (page: string) => history.push(`/creditors/${id}/edit/${page}`)
  const getPage = () => {
    switch (page) {
      case 'home':
        return <CreditorEdit loading={!creditorResult} />
      case 'sellers':
        return <CreditorSellers creditor={creditorResult?.id} user={user} />
      default:
        return <div>page not found</div>
    }
  }

  const backToList = () => {
    history.push('/creditors')
  }

  return (
    <div className='container'>
      <AppBar back={backToList} title={i18n._(t`Credor`)}></AppBar>
      <Top items={menuElements} name={creditorResult?.name} page={page} onBack={onBack} onMenuClick={menuClicked} />
      <Page>
        <Fragment>{getPage()}</Fragment>
      </Page>
    </div>
  )
}

export { CreditorContainer }
