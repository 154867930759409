import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'

import gsap from 'gsap'

export interface FeedbackProps {
  title: string
  message: string
  buttonLabel: string
  open: boolean
  success?: boolean
  onClose?: () => void
}

const Feedback: React.FC<FeedbackProps> = ({
  open,
  onClose,
  title,
  message,
  buttonLabel,
  success = true,
}: FeedbackProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [stateOpen, setStateOpen] = useState(false)

  let container: any = null

  const tl = gsap.timeline()

  useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open)
    }
  }, [open])

  useEffect(() => {
    if (isOpen && !stateOpen && container) {
      const [title, message, icon, button] = getChildren(container)
      tl.fromTo(container, 0.3, { opacity: 0 }, { display: 'flex', opacity: 1 })
        .fromTo(title, 0.5, { opacity: 0, y: '-=50' }, { opacity: 1, y: '+=50' })
        .fromTo(button, 0.5, { opacity: 0, y: '+=50' }, { opacity: 1, y: '-=50' }, '-=0.5')
        .fromTo(message, 0.3, { opacity: 0 }, { opacity: 1 })
        .fromTo(
          icon,
          1,
          { scale: 0, opacity: 1 },
          { scale: 1, ease: 'Bounce.easeOut', onComplete: () => setStateOpen(true) },
          '-=0.5',
        )
    }

    if (!isOpen && stateOpen && container) {
      const children = getChildren(container)
      tl.to(children, 0.5, { opacity: 0 }).to(container, 0.3, {
        opacity: 0,
        onComplete: () => {
          gsap.to(container, 0, { display: 'none' })
          setStateOpen(false)
          if (onClose) onClose()
        },
      })
    }
  }, [isOpen, container, stateOpen])

  const getChildren = (container: HTMLElement): HTMLElement[] => {
    const title = container.getElementsByClassName('title')[0] as HTMLElement
    const message = container.getElementsByClassName('message')[0] as HTMLElement
    const icon = container.getElementsByClassName('icon')[0] as HTMLElement
    const button = container.getElementsByClassName('button')[0] as HTMLElement

    return [title, message, icon, button]
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <div
      ref={(el) => {
        if (el) container = el
      }}
      style={{ display: 'none' }}
    >
      <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
        <Typography variant='h3'>{title}</Typography>
        <Box mt='30px'>
          <Typography variant='h5'>{message}</Typography>
        </Box>
        <Box my='50px'>
          {success ? <CheckCircleOutlineIcon data-testid='success-icon' /> : <CloseIcon data-testid='error-icon' />}
        </Box>
        <Box>
          <Button variant='contained' color='secondary' onClick={close} data-testid='feedback-continue-button'>
            {buttonLabel}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export { Feedback }
