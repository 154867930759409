import React from 'react'
import { Pagination as PaginationMui } from '@mui/material'
import { PaginationItem } from '@mui/material'

type PaginationProps = {
  pages: number
  onChange: ((page: number) => void) | undefined
  disabled: boolean
}

const Pagination = ({ pages, onChange, disabled }: PaginationProps) => {
  const buttonType = {
    page: (item) => (
      <PaginationItem size='small' {...item}>
        {item?.page}
      </PaginationItem>
    ),
    previous: (item) => <PaginationItem {...item} />,
    next: (item) => <PaginationItem {...item} />,
    'start-ellipsis': (item) => <PaginationItem {...item} />,
    'end-ellipsis': (item) => <PaginationItem {...item} />,
  }

  return (
    <PaginationMui
      count={pages}
      onChange={(event, page) => {
        onChange && onChange(page)
      }}
      color='primary'
      disabled={disabled}
      renderItem={(item) => {
        return buttonType[item.type](item)
      }}
    />
  )
}

export { Pagination }
