export enum ToolbarEvents {
  TOGGLE_MAIN = 'TOGGLE_MAIN',
  SET_MAIN_CURRENT = 'SET_MAIN_CURRENT',
}

export interface ToolbarAction {
  type: ToolbarEvents
  payload?: any
}

export interface Toolbar {
  mainIsOpen: boolean
  mainCurrent: string
}

export const toolbar: Toolbar = {
  mainIsOpen: true,
  mainCurrent: '',
}

export const toolbarReducer = (state: Toolbar = toolbar, action: ToolbarAction) => {
  switch (action.type) {
    case ToolbarEvents.TOGGLE_MAIN:
      return { ...state, mainIsOpen: action.payload }
    case ToolbarEvents.SET_MAIN_CURRENT:
      return { ...state, mainCurrent: action.payload }
    default:
      return state
  }
}

export interface IToolbarActions {
  toggleMain: (open: boolean) => ToolbarAction
  setMainCurrent: (name: string) => ToolbarAction
}

export const ToolbarActions: IToolbarActions = {
  toggleMain: (open: boolean) => ({ type: ToolbarEvents.TOGGLE_MAIN, payload: open }),
  setMainCurrent: (name: string) => ({ type: ToolbarEvents.SET_MAIN_CURRENT, payload: name }),
}
