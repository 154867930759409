import React, { useEffect } from 'react'
import { t } from '@lingui/macro'
import { useSelector, useDispatch } from 'react-redux'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Menu from '@mui/icons-material/Menu'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paid from '@mui/icons-material/Paid'
import ShoppingBag from '@mui/icons-material/ShoppingBag'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Work from '@mui/icons-material/Work'
import Store from '@mui/icons-material/Store'
import { ReactComponent as PismoWave } from '../../assets/pismo-wave.svg'
import { commonRoles } from '../../utils/Roles'
import { RootState } from '../../store/Store'
import { Can } from '@Components/can'
import { ToolbarButton } from './ToolBarButton'
import { useHistory, useLocation } from 'react-router-dom'

import { ToolbarActions } from '../../store'

const { toggleMain, setMainCurrent } = ToolbarActions

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const BrandWrapper = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '2rem',
  display: 'flex',
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export function Toolbar() {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { mainIsOpen, mainCurrent } = useSelector((state: RootState) => state.toolbar)
  const userRoles = useSelector((state: RootState) => state?.user?.roles || [])
  const [open, setOpen] = React.useState(true)

  const dispatch = useDispatch()

  const location = useLocation()
  const history = useHistory()

  const menuItens = [
    {
      Icon: ShoppingBag,
      label: i18n._(t`Estabelecimentos`),
      name: 'sellers',
      roles: commonRoles,
    },
    { Icon: Paid, label: i18n._(t`Credores`), name: 'creditors', roles: commonRoles },
    {
      Icon: Store,
      label: i18n._(t`Marketplace`),
      name: 'marketplace',
      roles: commonRoles,
    },
  ]

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const inMenu = menuItens.some((item) => {
      const routeParent = location.pathname.split('/')[1]
      const match = routeParent === item.name

      if (match && mainCurrent !== item.name) {
        dispatch(setMainCurrent(item.name))
        if (!mainIsOpen) {
          dispatch(toggleMain(true))
        }
      }

      return match
    })

    if (!inMenu) dispatch(setMainCurrent(''))
  }, [location.pathname])

  const clicked = (name: string) => () => {
    if (name === mainCurrent) return

    history.push(`/${name.toLowerCase()}`)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant='permanent' open={open}>
        <DrawerHeader
          sx={{
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemButton
            sx={{
              justifyContent: open ? 'initial' : 'center',
            }}
            color='paimary'
          >
            <Box mr='5px'>
              <BrandWrapper>
                <PismoWave />
              </BrandWrapper>
            </Box>
            <ListItemText primary={'PismoMKT'} disableTypography sx={{ fontSize: '1.5rem', opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </DrawerHeader>
        <Divider />
        <DrawerHeader
          sx={{
            height: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <Menu />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItens.map((item, index) => (
            <Can key={`${item.label}-${index}`} roles={item.roles} userRoles={userRoles} fallback={() => <div />}>
              <ToolbarButton
                Icon={<item.Icon fontSize='large' />}
                label={item.label}
                onClick={clicked(item.name)}
                selected={mainCurrent === item.name}
                iconMode={!mainIsOpen}
                testid={item.name}
              />
            </Can>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  )
}
