import { Dispatch } from 'redux'
import { Orgs, IProgramList } from '@pismo/sdk-orgs'
import { mountAuthHeaders, interceptor } from '../utils'
import { CNAE, Cedent, CreditorResult, IGet, Merchants } from 'src/resources/merchants'
import { IError } from 'src/resources/interfaces'

export enum SellerNewEvents {
  GET_PROGRAMS_TYPES = 'GET_PROGRAMS_TYPES_sellerNew',
  CLEAR_PROGRAMS_TYPES = 'CLEAR_PROGRAMS_TYPES_sellerNew',
  GET_CREDITORS = 'GET_CREDITORS_sellerNew',
  CLEAR_CREDITORS = 'CLEAR_CREDITORS_sellerNew',
  GET_CEDENT = 'GET_CEDENT_sellerNew',
  CLEAR_CEDENT = 'CLEAR_CEDENT_sellerNew',
  CREATE_RESULT = 'CREATE_RESULT_sellerNew',
  CLEAR_CREATE_RESULT = 'CLEAR_CREATE_RESULT_sellerNew',
  SET_OPEN_FEEDBACK = 'SET_OPEN_FEEDBACK',
  SET_BUSINESS_CATEGORIES = 'SET_BUSINESS_CATEGORIES',
  SET_ERROR = 'SET_ERROR',
}

export interface SellerNewAction {
  type: SellerNewEvents
  payload?: any
}

export interface SellerNew {
  programsTypes: IProgramList | null
  creditors: CreditorResult[] | null
  cedent: Cedent | null
  response: any
  openFeedback: boolean
  businessCategories: CNAE[] | null
  error: any
}

export const sellernew: SellerNew = {
  programsTypes: null,
  creditors: null,
  cedent: null,
  response: null,
  openFeedback: false,
  businessCategories: null,
  error: null,
}

export const sellernewReducer = (state: SellerNew = sellernew, action: SellerNewAction): SellerNew => {
  switch (action.type) {
    case SellerNewEvents.GET_PROGRAMS_TYPES:
      return { ...state, programsTypes: action.payload }
    case SellerNewEvents.CLEAR_PROGRAMS_TYPES:
      return { ...state, programsTypes: null }
    case SellerNewEvents.GET_CREDITORS:
      return { ...state, creditors: action.payload }
    case SellerNewEvents.CLEAR_CREDITORS:
      return { ...state, creditors: null }
    case SellerNewEvents.GET_CEDENT:
      return { ...state, cedent: action.payload }
    case SellerNewEvents.CLEAR_CEDENT:
      return { ...state, cedent: null }
    case SellerNewEvents.CREATE_RESULT:
      return { ...state, response: action.payload, openFeedback: true }
    case SellerNewEvents.SET_OPEN_FEEDBACK:
      return { ...state, openFeedback: action.payload }
    case SellerNewEvents.CLEAR_CREATE_RESULT:
      return { ...state, response: null }
    case SellerNewEvents.SET_BUSINESS_CATEGORIES:
      return { ...state, businessCategories: action.payload }
    case SellerNewEvents.SET_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export interface ISellerNewActions {
  getProgramsTypes: (credentials: any, props?: IGet) => (dispatch: Dispatch) => void
  clearProgramsTypes: () => SellerNewAction
  getCreditors: (credentials: any, props?: IGet) => (dispatch: Dispatch) => void
  clearCreditors: () => SellerNewAction
  getCedent: (cedentID: number, credentials: any) => (dispatch: Dispatch) => void
  clearCedent: () => SellerNewAction
  createSeller: (seller, credentials: any) => (dispatch: Dispatch) => void
  clearCreateResult: () => SellerNewAction
  clearError: () => SellerNewAction
  setOpenFeedback: (open: boolean) => SellerNewAction
  getBusinessCategories: (credentials: any) => (dispatch: Dispatch) => void
}

export const SellerNewActions: ISellerNewActions = {
  getProgramsTypes: (credentials: any, props?: IGet) => async (dispatch: Dispatch) => {
    const OrgsClient = Orgs({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = OrgsClient.getClient()

    interceptor(client, dispatch)

    const resp: IProgramList | IError = await OrgsClient.getProgramsList({ perPage: 999 })

    if ((resp as IError).error) {
      return
    }

    dispatch({ type: SellerNewEvents.GET_PROGRAMS_TYPES, payload: resp as IProgramList })
  },
  clearProgramsTypes: () => ({ type: SellerNewEvents.CLEAR_PROGRAMS_TYPES }),

  getCreditors: (credentials: any, props?: IGet) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const { data } = await MerchantsClient.getCreditors(props)
      dispatch({ type: SellerNewEvents.GET_CREDITORS, payload: data.items })
    } catch (err: any) {
      dispatch({
        type: SellerNewEvents.SET_ERROR,
        payload: { hasError: true, message: err?.response?.data?.message },
      })
    }
  },
  clearCreditors: () => ({ type: SellerNewEvents.CLEAR_PROGRAMS_TYPES }),
  clearError: () => ({ type: SellerNewEvents.SET_ERROR, payload: null }),
  getCedent: (cedentID: number, credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const { data } = await MerchantsClient.getCedent(cedentID)
      dispatch({ type: SellerNewEvents.GET_CEDENT, payload: data as Cedent })
    } catch (err: any) {
      dispatch({
        type: SellerNewEvents.SET_ERROR,
        payload: { hasError: true, message: err?.response?.data?.message },
      })
    }
  },
  getBusinessCategories: (credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    try {
      const resp = await MerchantsClient.getBusinessCategories()
      dispatch({ type: SellerNewEvents.SET_BUSINESS_CATEGORIES, payload: resp })
    } catch (err: any) {
      dispatch({ type: SellerNewEvents.SET_BUSINESS_CATEGORIES, payload: [] })
    }
  },
  clearCedent: () => ({ type: SellerNewEvents.CLEAR_CEDENT }),

  createSeller: (seller, credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const response = await MerchantsClient.createMerchant(seller)

      dispatch({ type: SellerNewEvents.CREATE_RESULT, payload: response })
    } catch (error: any) {
      dispatch({
        type: SellerNewEvents.CREATE_RESULT,
        payload: { success: false, message: error.response.data.message },
      })
    }
  },
  clearCreateResult: () => ({ type: SellerNewEvents.CLEAR_CREATE_RESULT }),
  setOpenFeedback: (open: boolean) => ({ type: SellerNewEvents.SET_OPEN_FEEDBACK, payload: open }),
}
