import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'

import TextField from '../textField'

import Button from '@Components/button'

export interface InputSearchProps {
  inputPlaceholder?: string
  onClickSearch?: (term: string) => void
  hasNew?: boolean
  newLabel?: string
  onNewClick?: () => void
  helperText?: string
  error?: boolean
}

const InputSearch: React.FC<InputSearchProps> = ({
  inputPlaceholder,
  hasNew = false,
  newLabel,
  onNewClick,
  onClickSearch,
  helperText,
  error,
}: InputSearchProps) => {
  const history = useHistory()
  const location = useLocation()

  const [term, setTerm] = useState('')

  useEffect(() => {
    window.removeEventListener('keyup', onKeyEnter)
    window.addEventListener('keyup', onKeyEnter)

    return () => window.removeEventListener('keyup', onKeyEnter)
  }, [term])

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search)
  //   const queryString = (query.get('term') || '').toString()
  // }, [location.search])

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    history.push(`${location.pathname}?term=${e.target.value}`)
    setTerm(e.target.value)
  }

  const onKeyEnter = (e: KeyboardEvent) => {
    if (!e.key) return
    if (e.key.match(/enter/gi)) {
      handlerClickSearch()
    }
  }

  const handlerClickSearch = () => {
    if (onClickSearch) onClickSearch(term)
  }

  const handlerNew = () => {
    if (onNewClick) onNewClick()
  }

  return (
    <Box display='flex'>
      <Box display='flex' flexGrow={1} alignItems='center'>
        <TextField
          type='search'
          placeholder={inputPlaceholder}
          onChange={changeSearch}
          value={term}
          autoFocus
          fullWidth
          key={'InputSearchSeller'}
          data-testid='input-search-seller'
          helperText={helperText}
          error={error}
        />
        <Box ml='15px'>
          <Button color='primary' variant='contained' data-testid='button-search-seller' onClick={handlerClickSearch}>
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      {hasNew && (
        <Box ml='20px'>
          <Button color='success' variant='contained' onClick={handlerNew}>
            {newLabel}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export { InputSearch }
