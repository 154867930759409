import * as React from 'react'
import Button from '@Components/button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Box, Divider } from '@mui/material'
import { RootState } from 'src/store/Store'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogTitleProps, SellerAnticipationModalPropType } from './interface'

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: (theme) => theme.palette.primary.main }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function SellerAnticipationModal({
  open,
  close,
  title,
  advancementAmount,
  valueToAnticipate,
  fees,
  discountedValue,
  tariff,
  rate,
  setAntecipate,
  loading,
}: SellerAnticipationModalPropType) {
  const handleClose = () => {
    close(false)
  }
  const { i18n } = useSelector((state: RootState) => state.translation)

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} fullWidth={true}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          {i18n._(t`Antecipacao`)}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box p={3} display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h6' gutterBottom>
              {title}
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.primary.dark }} gutterBottom>
              {advancementAmount}
            </Typography>
          </Box>
          <Divider />
          <Box p={1} width={1} display='flex' justifyContent='space-between'>
            <Box>{i18n._(t`ValorAAntecipar`)}</Box>
            <Box>{valueToAnticipate}</Box>
          </Box>
          <Divider />
          <Box p={1} width={1} display='flex' justifyContent='space-between'>
            <Box>{`${i18n._(t`juros`)} - (${fees}% ${i18n._(t`mes`)})`}</Box>
            <Box>{discountedValue}</Box>
          </Box>
          <Divider />
          <Box p={1} width={1} display='flex' justifyContent='space-between'>
            <Box>{i18n._(t`Tarifa`)}</Box>
            <Box>{tariff}</Box>
          </Box>
          <Divider />
          <Box p={1} width={1} display='flex' justifyContent='space-between'>
            <Box>{i18n._(t`TaxaEfetivaCET`)}</Box>
            <Box>{rate}%</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null}
            disabled={loading}
            autoFocus
            onClick={setAntecipate}
            variant='contained'
          >
            {i18n._(t`ConfirmarAntecipacao`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
