import React from 'react'

import get from 'lodash.get'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Pagination } from '@Components/pagination'
import Loading from '@Components/loading'
import { styled } from '@mui/material/styles'
import { NoResults } from './NoResults'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Header<T> {
  title?: string
  prop?: string
  clickIcon?: (item: T) => void
  cellComponent?: (item: T) => React.ReactElement
  headerAlign?: 'left' | 'center' | 'right'
  cellAlign?: 'left' | 'center' | 'right'
  className?: string
}

export interface SearchResultsTypedProps<T> {
  header: Header<T>[]
  data: {
    items: T[]
    current_page: number
    per_page: number
    total_items: number
    pages: number
  }
  changePage?: ((nextPage: number) => void) | undefined
  onClick?: (item: T) => void
  loading?: boolean
}

const renderLoading = (loading: boolean) => {
  return (
    loading && (
      <Box width='100%' display='flex' alignItems='center'>
        <Loading />
      </Box>
    )
  )
}

const renderNoResult = (loading: boolean, hasResult: boolean) => {
  return !loading && !hasResult && <NoResults />
}

function SearchResultsTyped<T>({
  header,
  data,
  changePage,
  onClick,
  loading = false,
}: SearchResultsTypedProps<T>): React.ReactElement {
  const handlerClickItem = (item) => () => {
    if (onClick) onClick(item)
  }

  return (
    <Box p='10px' height={1}>
      <Paper sx={{ height: '100%' }}>
        <Box display={'flex'} height={1} flexDirection='column'>
          <TableContainer sx={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  {header.map((el, index) => (
                    <StyledTableCell
                      key={`${el.prop}-${index}`}
                      align={el.headerAlign ?? 'left'}
                      className={el.className}
                    >
                      {el.title}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data?.items?.map((item, index) => (
                    <StyledTableRow
                      key={`${item['id'] ?? ''}-${index}`}
                      hover
                      onClick={handlerClickItem(item)}
                      data-testid={`search-result-${index}`}
                    >
                      {header.map((k, i) => (
                        <StyledTableCell
                          key={`${k.prop}:${item[k.prop ?? 'id'] ?? ''}-${i}`}
                          align={k.cellAlign ?? 'left'}
                        >
                          {k.cellComponent ? k.cellComponent(item) : get(item, k.prop)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {renderLoading(loading)}
          {renderNoResult(loading, !!data?.items?.length)}
          <Box display='flex' py='20px' justifyContent='center'>
            <Pagination pages={data?.pages} onChange={changePage} disabled={!data} />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export { SearchResultsTyped }
