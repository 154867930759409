import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { styled } from '@mui/material/styles'

import 'react-datepicker/dist/react-datepicker.css'

import enLocale from 'date-fns/locale/en-US'
import ptLocale from 'date-fns/locale/pt-BR'
import esLocale from 'date-fns/locale/es'

export const locale = {
  en: enLocale,
  pt: ptLocale,
  es: esLocale,
}

// CSS Modules, react-datepicker-cssmodules.css

const StyledDatePicker = styled(DatePicker)({
  height: '2.5rem',
  fontSize: '.94rem',
  paddingRight: '1.5rem',
  borderRadius: '.3rem',
  borderColor: '#858585',
  borderStyle: 'inset',
  width: '100%',
})

type DateRange = {
  startDate: Date
  endDate: Date
}

type Props = {
  startDate: Date
  endDate: Date
  onChange: (dateRange: DateRange) => void
  language: string
  maxDate: Date
  placeholderText?: string
  isClearable?: boolean
}

const DateRangePicker = ({
  startDate: start,
  endDate: end,
  onChange,
  language = 'en',
  maxDate,
  placeholderText,
}: Props) => {
  const [dateRange, setDateRange] = useState([start, end])
  const [startDate, endDate] = dateRange

  return (
    <StyledDatePicker
      locale={locale[language]}
      selectsRange={true}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      maxDate={maxDate}
      onChange={(update) => {
        setDateRange(update)
        onChange({ startDate: update[0], endDate: update[1] })
      }}
      isClearable={true}
      placeholderText={placeholderText}
    />
  )
}

export default DateRangePicker
