import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import { NoResults } from '@Components/search/NoResults'
import { RootState } from '../../../store/Store'
import { formatToDefaultCurrency } from 'src/utils/number'
import { formatDate } from 'src/utils/date'

interface DetailsTransactionsInterface {
  dataItens: any
  statusLoad?: boolean
  statusError?: boolean
  sellerName?: string
}

const format = (item, type, style = 'yyyy/MM/dd HH:mm:ss') => {
  const patternData = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/

  if (item === '' || item === null || item === undefined) return
  switch (type) {
    case 'number':
      if (isNaN(item)) return item
      return formatToDefaultCurrency(item)
    case 'date':
      if (patternData.test(item)) return item
      return formatDate(new Date(item), style)
  }
}

const TransactionDetails: React.FC<DetailsTransactionsInterface> = ({
  statusError,
  dataItens,
  sellerName,
}: DetailsTransactionsInterface) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  return (
    <Box display='flex' flexDirection='column'>
      {dataItens && !statusError ? (
        <Fragment>
          <div>
            <div>
              <span>{format(dataItens.date, 'date')}</span>
              <h4>{dataItens.name}</h4>
              <p>
                {i18n._(
                  t`autorização: ${dataItens.authorizationId ? dataItens.authorizationId : i18n._(t`- Não informado`)}`,
                )}
              </p>
            </div>
          </div>
          <div>
            <span>
              {dataItens.installments > 0 && <small>{`${dataItens.installments} x `}</small>}
              {formatToDefaultCurrency(Number(dataItens.value))}
            </span>

            <p>{`${sellerName} - ${dataItens.authorizationId ? `ID: ${dataItens.id}` : i18n._(t`Não informado`)}`}</p>
          </div>
        </Fragment>
      ) : (
        <Box>
          <NoResults message={i18n._(t`Essa transação nao possui detalhes`)} />
        </Box>
      )}
    </Box>
  )
}

export { TransactionDetails }
