import React from 'react'

import { default as ButtonMaterial } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledButton = styled(ButtonMaterial)({
  textTransform: 'capitalize',
})

const Button = (props) => {
  return <StyledButton {...props}></StyledButton>
}

export default Button
