import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'

import { CommonView } from '@Components/creditors/marketplaces'
import { ICreditorMarketplacesSearchResult } from '../../store/Creditor'
import { CreditorActions } from '../../store'
import { store } from '../../store/Store'
const { getCreditorSeller } = CreditorActions

interface CreditorSellersProps {
  creditor: any
  user: any
}

// eslint-disable-next-line react/prop-types
const CreditorSellers: React.FC<CreditorSellersProps> = ({ user, creditor }) => {
  const [stateSellers, setStateSellers] = useState<ICreditorMarketplacesSearchResult | undefined>(undefined)

  const dispatch = useDispatch()

  const getSellers = async (page: number) => {
    await dispatch(getCreditorSeller(creditor, page, user))
    const state = store.getState()
    const newCreditor = state.creditor.listMarketplaces
    setStateSellers(newCreditor)
  }

  useEffect(() => {
    if (user && creditor) {
      getSellers(1)
    }
  }, [user, creditor])

  return (
    <Box width={1} display='flex' height={1} flexDirection='column' data-testid='tax-page-container'>
      <CommonView marketplaces={stateSellers} getSellers={getSellers} />
    </Box>
  )
}

export { CreditorSellers }
