import React from 'react'
import { useSelector } from 'react-redux'

import { t } from '@lingui/macro'

import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { RootState } from '../../../store/Store'

export interface DownloadRemoveDialogProps {
  open?: boolean
  isRemove?: boolean
  onClose?: () => void
  onRemove: (result) => void
}

const DownloadRemoveDialog: React.FC<DownloadRemoveDialogProps> = ({
  open = false,
  onClose,
  onRemove,
  isRemove = false,
}: DownloadRemoveDialogProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  const changeItem = (_remove: boolean) => (e) => {
    if (_remove) {
      e.preventDefault()

      onRemove(_remove)

      if (onClose) onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ id: 'download-remove-dialog' }}>
      <Box display='flex' width={1} p='20px'>
        <Grid container spacing={3}>
          {isRemove && (
            <Grid item xs={12}>
              <Typography variant='h5' align='center' data-testid='nerd-remove-title'>
                {i18n._(t`Tem certeza que deseja remover este item?`)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display='flex' width={1} justifyContent='center'>
              <Box width='200px'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={changeItem(isRemove)}
                  data-testid='nerd-confirm-button'
                  component='a'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {isRemove ? i18n._(t`remover`) : i18n._(t`download`)}
                </Button>
              </Box>
              <Box width='200px' ml='15px'>
                <Button variant='contained' color='secondary' onClick={onClose} data-testid='nerd-cancel-button'>
                  {i18n._(t`cancelar`)}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export { DownloadRemoveDialog }
