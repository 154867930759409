import { Dispatch } from 'redux'
import { Merchants, IGetCreditorsResult, IGet } from '../resources/merchants'
import { mountAuthHeaders, interceptor } from '../utils'

export enum CreditorsSearchEvents {
  SEARCHRESULT = 'SEARCHRESULT',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export interface CreditorsSearchAction {
  type: CreditorsSearchEvents
  payload?: any
}

export interface CreditorsSearch {
  term: string
  loading: boolean
  searchResult: IGetCreditorsResult | null
  paginationLoading: boolean
  error: string | null
}

export const creditorSearch: CreditorsSearch = {
  term: '',
  loading: false,
  searchResult: null,
  paginationLoading: false,
  error: null,
}

export const creditorsSearchReducer = (
  state: CreditorsSearch = creditorSearch,
  action: CreditorsSearchAction,
): CreditorsSearch => {
  switch (action.type) {
    case CreditorsSearchEvents.SEARCHRESULT:
      return { ...state, searchResult: action.payload, loading: false }
    case CreditorsSearchEvents.LOADING:
      return { ...state, loading: action.payload }
    case CreditorsSearchEvents.ERROR:
      return { ...state, error: action.payload?.error?.message, loading: false }
    default:
      return state
  }
}

export interface ICreditorsSearchActions {
  getCreditorsList: (credentials: any, props?: IGet) => (dispatch: Dispatch) => void
}

export const CreditorsSearchActions: ICreditorsSearchActions = {
  getCreditorsList: (credentials: any, props?: IGet) => async (dispatch: Dispatch) => {
    dispatch({ type: CreditorsSearchEvents.LOADING, payload: true })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const { data } = await MerchantsClient.getCreditors(props)
      dispatch({ type: CreditorsSearchEvents.SEARCHRESULT, payload: data })
    } catch (err: any) {
      dispatch({ type: CreditorsSearchEvents.ERROR, payload: err.message })
    }
  },
}
