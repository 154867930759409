import { Dispatch } from 'redux'

import { mountAuthHeaders, interceptor } from '../utils'

import { getYear, getMonth, startOfMonth, endOfMonth, format, isWithinInterval } from 'date-fns'
import {
  IGetTransactionResult,
  IOperation,
  ITransactionType,
  ITransactionsTotal,
  Merchants,
  TransactionResult,
} from 'src/resources/merchants'

const getTransactions = async ({ merchantID, credentials, params }) => {
  const MerchantsClient = Merchants({
    headers: mountAuthHeaders(credentials),
  })
  const { startPaymentDate, endPaymentDate } = params
  const groupByMonth = {}
  const isBetweenTwoDates = (date) => {
    return isWithinInterval(date, {
      start: new Date(format(startPaymentDate, 'yyyy-MM-dd 00:00:00')),
      end: new Date(format(endPaymentDate, 'yyyy-MM-dd 23:59:59')),
    })
  }
  try {
    const { data } = await MerchantsClient.getTransactions(merchantID, { ...params })
    const { items: itemsByMonth } = data as IGetTransactionResult
    await Promise.all(
      itemsByMonth.map(async (el) => {
        const [y, m, d] = el.payment_date.split('-')
        const date = new Date(Number(y), Number(m) - 1, Number(d))
        const year = getYear(date)
        const month = getMonth(date)

        if (!groupByMonth[year]) groupByMonth[year] = {}
        groupByMonth[year][month] = { total: el }

        const transactionsByDay = await MerchantsClient.getTransactions(merchantID, {
          startPaymentDate: startOfMonth(date),
          endPaymentDate: endOfMonth(date),
          groupBy: 'paymentDate',
          perPage: 31,
        })
        const { items: nonFilteredDays } = transactionsByDay.data as IGetTransactionResult
        const itemsByDay = nonFilteredDays.filter((val) => {
          const payDate = new Date(`${val.payment_date} 23:59:59`)

          return isBetweenTwoDates(payDate)
        })
        await Promise.all(
          itemsByDay.map(async (itemsByDay_item) => {
            const [y, m, d] = itemsByDay_item.payment_date.split('-')
            const paydate = new Date(Number(y), Number(m) - 1, Number(d))
            if (!!groupByMonth[year][month][Number(d)]) {
              groupByMonth[year][month][Number(d)].total.net_value =
                groupByMonth[year][month][Number(d)].total.net_value + itemsByDay_item.net_value
            } else {
              groupByMonth[year][month][Number(d)] = { total: itemsByDay_item }
            }

            const { data: respTypes } = await MerchantsClient.getTransactionsType(merchantID, paydate)

            const transactionTypes = respTypes as ITransactionType[]
            await Promise.all(
              transactionTypes.map(async (transactionTypes_item) => {
                const operations = await MerchantsClient.getOperations(merchantID, paydate, transactionTypes_item.type)

                if (!groupByMonth[year][month][Number(d)][transactionTypes_item.type])
                  groupByMonth[year][month][Number(d)][transactionTypes_item.type] = {
                    total: transactionTypes_item,
                  }
                groupByMonth[year][month][Number(d)][transactionTypes_item.type].operations = operations?.data
              }),
            )
          }),
        )
      }),
    )
    return groupByMonth
  } catch (err: any) {
    throw err
  }
}

export enum SellerEventEvents {
  CLEAR_TRANSACTIONS_TOTAL = 'CLEAR_TRANSACTIONS_TOTAL',
  CLEAR_EVENT_TRANSACTION = 'CLEAR_EVENT_TRANSACTION',
  SET_TRANSACTIONS_TOTAL = 'SET_TRANSACTIONS_TOTAL',
  SET_EVENT_TRANSACTION = 'SET_EVENT_TRANSACTION',
  SET_EVENT_TRANSACTION_DETAILS = 'SET_EVENT_TRANSACTION_DETAILS',
  EVENT_LOADING = 'EVENT_LOADING',
  SET_TRANSACTION_LOADING = 'SET_TRANSACTION_LOADING',
  SET_ERROR_EVENT = 'SET_ERROR_EVENT',
  SET_TRANSACTIONS_RESULT = 'SET_TRANSACTIONS_RESULT',
}

export interface SellerEventAction {
  type: SellerEventEvents
  payload?: any
}

export interface IEventTransaction {
  [year: number]: {
    [month: number]: {
      total: TransactionResult
      [day: number]: {
        [transactionType: string]:
          | TransactionResult
          | {
              total: ITransactionType
              operations: IOperation[]
            }
      }
    }
  }
}

export interface SellerEvent {
  transactionsTotal: ITransactionsTotal | null
  transactions: IEventTransaction | null
  transactionsDetails: IEventTransaction | null
  eventLoading: boolean
  eventError: boolean
  transactionsResult: IGetTransactionResult | null
  transactionLoading: boolean
}

export const sellerEvent: SellerEvent = {
  transactionsTotal: null,
  transactions: null,
  transactionsDetails: null,
  eventLoading: true,
  eventError: false,
  transactionsResult: null,
  transactionLoading: false,
}

export const sellerEventReducer = (state: SellerEvent = sellerEvent, action: SellerEventAction): SellerEvent => {
  switch (action.type) {
    case SellerEventEvents.CLEAR_TRANSACTIONS_TOTAL:
      return { ...state, transactionsTotal: null, eventError: false }
    case SellerEventEvents.SET_TRANSACTIONS_TOTAL:
      return { ...state, transactionsTotal: action.payload, eventError: false, eventLoading: false }
    case SellerEventEvents.CLEAR_EVENT_TRANSACTION:
      return { ...state, transactions: null, eventError: false }
    case SellerEventEvents.SET_EVENT_TRANSACTION:
      return { ...state, transactions: action.payload, eventError: false }
    case SellerEventEvents.SET_EVENT_TRANSACTION_DETAILS:
      return { ...state, transactionsDetails: action.payload, eventError: false }
    case SellerEventEvents.SET_ERROR_EVENT:
      return { ...state, transactions: null, eventError: true, eventLoading: false }
    case SellerEventEvents.SET_TRANSACTIONS_RESULT:
      return { ...state, transactionsResult: action.payload, eventError: false, eventLoading: false }
    case SellerEventEvents.EVENT_LOADING:
      return { ...state, eventLoading: action.payload }
    case SellerEventEvents.SET_TRANSACTION_LOADING:
      return { ...state, transactionLoading: action.payload }
    default:
      return state
  }
}

export interface ISellerEventActions {
  getTransactionsTotal: (
    merchantID: number,
    startPaymentDate: Date,
    endPaymentDate: Date,
    credentials: any,
  ) => (dispatch: Dispatch) => void
  getTransactionsList: (props: any) => (dispatch: Dispatch) => void
  getTransactionsListDetail: (props: any) => (dispatch: Dispatch) => void
}

export const SellerEventActions: ISellerEventActions = {
  getTransactionsTotal:
    (merchantID: number, startPaymentDate: Date, endPaymentDate: Date, credentials: any) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: SellerEventEvents.EVENT_LOADING, payload: true })
      dispatch({ type: SellerEventEvents.CLEAR_TRANSACTIONS_TOTAL })

      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)

      try {
        const response = await MerchantsClient.getTransactionsTotal(merchantID, startPaymentDate, endPaymentDate)
        dispatch({ type: SellerEventEvents.SET_TRANSACTIONS_TOTAL, payload: response as ITransactionsTotal })
      } catch (err: any) {
        dispatch({ type: SellerEventEvents.SET_ERROR_EVENT })
      } finally {
        dispatch({ type: SellerEventEvents.EVENT_LOADING, payload: false })
      }
    },

  getTransactionsList:
    ({ merchantID, credentials, params }) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: SellerEventEvents.SET_TRANSACTION_LOADING, payload: true })
      dispatch({ type: SellerEventEvents.CLEAR_EVENT_TRANSACTION })
      try {
        const data = await getTransactions({ merchantID, credentials, params })
        dispatch({ type: SellerEventEvents.SET_EVENT_TRANSACTION, payload: data })
      } catch (err) {
        dispatch({ type: SellerEventEvents.SET_ERROR_EVENT })
      } finally {
        dispatch({ type: SellerEventEvents.SET_TRANSACTION_LOADING, payload: false })
      }
    },
  getTransactionsListDetail:
    ({ merchantID, credentials, params }) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: SellerEventEvents.SET_TRANSACTION_LOADING, payload: true })
      try {
        const data = await getTransactions({ merchantID, credentials, params })
        dispatch({ type: SellerEventEvents.SET_EVENT_TRANSACTION_DETAILS, payload: data })
      } catch (err) {
        dispatch({ type: SellerEventEvents.SET_ERROR_EVENT })
      } finally {
        dispatch({ type: SellerEventEvents.SET_TRANSACTION_LOADING, payload: false })
      }
    },
}
