import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import { InputSearch, SearchResults } from '@Components/search'
import AppBar from '@Components/appBar'

import { CreditorsSearchActions } from '../../store'
import { RootState } from '../../store/Store'
import Page from '@Components/pageContent'

const { getCreditorsList } = CreditorsSearchActions

const CreditorsSearch: React.FC = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { loading, searchResult } = useSelector((state: RootState) => state.creditorssearch)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getCreditorsList(user))
  }, [])

  const onChangePage = (nextPage: number) => {
    dispatch(getCreditorsList(user, { page: nextPage, name: searchTerm }))
  }

  const searchByTerm = (name: string) => {
    setSearchTerm(name)
    dispatch(getCreditorsList(user, { name }))
  }

  const onClickHandler = (item: any) => {
    history.push(`/creditors/${item.id}/edit/home`)
  }

  const handleNewCreditor = () => {
    history.push('/creditors/new')
  }

  return (
    <div className='container'>
      <AppBar title={i18n._(t`Credores`)}>
        <InputSearch
          hasNew
          inputPlaceholder={i18n._(t`Pesquise um credor`)}
          newLabel={i18n._(t`Novo credor`)}
          onClickSearch={searchByTerm}
          onNewClick={handleNewCreditor}
        />
      </AppBar>

      <Page data-testid='creditors-main-transaction'>
        <SearchResults
          header={[
            { title: i18n._(t`Nome`), prop: 'name' },
            { title: i18n._(t`Agência`), prop: 'bank.agency' },
            { title: i18n._(t`Conta`), prop: 'bank.account' },
          ]}
          data={searchResult}
          changePage={onChangePage}
          onClick={onClickHandler}
          loading={loading}
        />
      </Page>
    </div>
  )
}

export { CreditorsSearch }
