import React from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import BlockIcon from '@mui/icons-material/Block'
import { RootState } from '../store/Store'

const RestrictAccess = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  return (
    <Box width={1} height={'100vh'} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Box>
        {/* <FaBan size={100} fill={theme.colors.red} /> */}
        <BlockIcon />
      </Box>
      <Box mt='70px'>
        <Typography variant='h2'>{i18n._(t`Acesso Restrito`)}</Typography>
      </Box>
      <Box mt='10px'>
        <Typography variant='h6'>{i18n._(t`Você não tem permissão para acessar esta área.`)}</Typography>
      </Box>
    </Box>
  )
}

export { RestrictAccess }
