import React from 'react'
import TextField from '@mui/material/TextField'
import { StandardTextFieldProps } from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

interface Props extends StandardTextFieldProps {
  mask?: boolean
  maskOptions?: any
  disablecheck?: boolean
  description?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const getLabel = (label, required, description) => {
  const textLabel = required ? `${label} *` : label

  if (!description) {
    return textLabel
  }

  return `${textLabel} ⓘ`
}

const TextFieldd = ({
  label,
  required,
  description,
  size = 'small',
  fullWidth = true,
  maskOptions,
  mask,
  ...props
}: Props) => {
  if (!description) {
    return (
      <TextField
        {...props}
        size={size}
        label={getLabel(label, required, description)}
        fullWidth={fullWidth}
        variant='outlined'
      />
    )
  }
  return (
    <Tooltip title={description} arrow placement='top'>
      <Box display='flex' alignItems='center'>
        <TextField
          {...props}
          size={size}
          label={getLabel(label, required, description)}
          fullWidth={fullWidth}
          variant='outlined'
        />
      </Box>
    </Tooltip>
  )
}

export default TextFieldd
