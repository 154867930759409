import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { RootState } from '../../../store/Store'

export interface FooterProps {
  goBack: () => void
  isFirstStep?: boolean
  isLastStep?: boolean
}

const Footer = ({ isFirstStep, goBack, isLastStep }: FooterProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  return (
    <div>
      <Box display={'flex'} justifyContent='center' my='30px'>
        <Box mx='5px'>
          <Button
            fullWidth={false}
            variant='contained'
            color='success'
            data-testid='next-button'
            onClick={goBack}
            disabled={isFirstStep}
          >
            {i18n._(t`voltar`)}
          </Button>
        </Box>
        <Box mx='5px'>
          <Button
            fullWidth={false}
            type='submit'
            variant='contained'
            color={isLastStep ? 'primary' : 'success'}
            data-testid='next-button'
          >
            {isLastStep ? i18n._(t`salvar`) : i18n._(t`próximo`)}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Footer
