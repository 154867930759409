import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import { InputSearch, SearchResults } from '@Components/search'
import PageContent from '@Components/pageContent'
import Page from '@Components/page'
import AppBar from '@Components/appBar'

import { SellerSearchActions } from '../../store'
import { RootState } from '../../store/Store'

const { searchSeller } = SellerSearchActions

type SearchTerm = {
  documentNumber?: string
  name?: string
}

const SellerSearch: React.FC = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { loading, searchResult } = useSelector((state: RootState) => state.sellersearch)
  const user = useSelector((state: RootState) => state.user)
  const [searchTerm, setSearchTerm] = useState<SearchTerm>()

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(searchSeller(user))
  }, [])

  const onChangePage = (nextPage: number) => {
    dispatch(searchSeller(user, { ...searchTerm, page: nextPage }))
  }

  const onSearchTerm = (term: string) => {
    const minNumberOfIntegers = 4
    const param =
      String(term).replace(/[^0-9]/g, '').length >= minNumberOfIntegers ? { documentNumber: term } : { name: term }
    setSearchTerm(param)
    dispatch(searchSeller(user, param))
  }

  const onClickHandler = (item: any) => history.push(`/sellers/edit/${item.id}/home`)

  const newClickHandler = () => history.push(`/sellers/new`)

  return (
    <Page>
      <AppBar title={i18n._(t`Estabelecimentos`)}>
        <InputSearch
          hasNew
          inputPlaceholder={i18n._(t`Pesquise um estabelecimento`)}
          newLabel={i18n._(t`Novo estabelecimento`)}
          onClickSearch={onSearchTerm}
          onNewClick={newClickHandler}
        />
      </AppBar>
      <PageContent>
        <SearchResults
          header={[
            { title: i18n._(t`establishmentName`), prop: 'merchant_name' },
            { title: i18n._(t`documentNumber`), prop: 'document_number' },
            { title: i18n._(t`Status do processo`), prop: 'status' },
          ]}
          data={searchResult}
          changePage={onChangePage}
          onClick={onClickHandler}
          loading={loading}
        />
      </PageContent>
    </Page>
  )
}

export { SellerSearch }
