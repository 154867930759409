import * as React from 'react'

import { StandardTextFieldProps } from '@mui/material/TextField'

import TextField from '../textField'
import { NumericFormat } from 'react-number-format'

const { useState } = React

export interface PercentageInputProps {
  initialValue?: number
  maxInteger?: number
  minInteger?: number
  toggleScore?: boolean
  hiddenDecimal?: boolean
  onChange: (value: number) => void
  TextfieldProps?: StandardTextFieldProps
}

const PercentInput = ({ onChange, initialValue = 0, TextfieldProps }: PercentageInputProps) => {
  const [value, setValue] = useState(initialValue * 100)

  const changeValue = (e) => {
    const value = e.target.value
    setValue(value)
    const formattedValue = Number(value.replace(' %', '')) / 100
    onChange(formattedValue)
  }

  return (
    <NumericFormat
      label={TextfieldProps?.label}
      customInput={TextField}
      value={value}
      onChange={changeValue}
      suffix=' %'
      valueIsNumericString={false}
    />
  )
}

export { PercentInput }
