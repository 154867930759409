import { Dispatch } from 'redux'
import { AuthAPI, IUser, IRole } from '@pismo/sdk-auth'
import { IError, IGet, ISuccess } from '@pismo/sdk-core'

import { mountAuthHeaders, interceptor } from '../utils'
import { ISearchProps, ISearchResult, Marketplace } from 'src/resources/marketplace'
import { IMerchantResult, Merchants } from 'src/resources/merchants'

export enum UserscreenEvents {
  SEARCH = 'SEARCH_userscreen',
  CLEAR_SEARCH = 'CLEAR_SEARCH_userscreen',
  SET_ROLES = 'SET_ROLES_userscreen',
  CREATE_RESULT = 'CREATE_RESULT_userscreen',
  CLEAR_CREATE_RESULT = 'CLEAR_CREATE_RESULT_userscreen',
  DELETE_RESULT = 'DELETE_RESULT_userscreen',
  CLEAR_DELETE_RESULT = 'CLEAR_DELETE_RESULT_userscreen',
  UPDATE_RESULT = 'UPDATE_RESULT_userscrren',
  CLEAR_UPDATE_RESULT = 'CLEAR_UPDATE_RESULT_userscreen',
  SEARCH_MERCHANT = 'SEARCH_MERCHANT_userscreen',
  SEARCH_MARKETPLACE = 'SEARCH_MARKETPLACE_userscreen',
}

export interface UserscreenAction {
  type: UserscreenEvents
  payload?: any
}

export interface Userscreen {
  searchResult: IUser | any
  rolesList: IRole[] | null
  fixedRoleList: IRole[] | null
  createSuccess: boolean | null
  deleteSuccess: boolean | null
  updateSuccess: boolean | null
  merchantList: IMerchantResult | null
  marketplaceList: ISearchResult | null
}

export const userscreen: Userscreen = {
  searchResult: null,
  rolesList: null,
  fixedRoleList: null,
  createSuccess: null,
  deleteSuccess: null,
  updateSuccess: null,
  merchantList: null,
  marketplaceList: null,
}

export const userscreenReducer = (state: Userscreen = userscreen, action: UserscreenAction): Userscreen => {
  switch (action.type) {
    case UserscreenEvents.SEARCH:
      return { ...state, searchResult: action.payload }
    case UserscreenEvents.CLEAR_SEARCH:
      return { ...state, searchResult: null }
    case UserscreenEvents.SET_ROLES:
      return { ...state, rolesList: action.payload, fixedRoleList: action.payload }
    case UserscreenEvents.CREATE_RESULT:
      return {
        ...state,
        createSuccess: action.payload,
        searchResult: null,
        rolesList: null,
        merchantList: null,
        marketplaceList: null,
      }
    case UserscreenEvents.CLEAR_CREATE_RESULT:
      return { ...state, createSuccess: null }
    case UserscreenEvents.DELETE_RESULT:
      return {
        ...state,
        deleteSuccess: action.payload,
        searchResult: null,
        rolesList: null,
        merchantList: null,
        marketplaceList: null,
      }
    case UserscreenEvents.CLEAR_DELETE_RESULT:
      return { ...state, deleteSuccess: null }
    case UserscreenEvents.UPDATE_RESULT:
      return {
        ...state,
        updateSuccess: action.payload,
        searchResult: null,
        rolesList: null,
        merchantList: null,
        marketplaceList: null,
      }
    case UserscreenEvents.CLEAR_UPDATE_RESULT:
      return { ...state, updateSuccess: null }
    case UserscreenEvents.SEARCH_MERCHANT:
      return { ...state, merchantList: action.payload }
    case UserscreenEvents.SEARCH_MARKETPLACE:
      return { ...state, marketplaceList: action.payload }
    default:
      return state
  }
}

export interface IUserscreenActions {
  search: (email: string, credentials: any) => (dispatch: Dispatch) => void
  clearSearch: () => UserscreenAction
  getRolesList: (credentials: any) => (dispatch: Dispatch, getState: any) => void
  createUser: (user: Omit<IUser, 'tenant'>, credentials: any) => (dispatch: Dispatch) => void
  clearCreateResult: () => UserscreenAction
  deleteUser: (email: string, credentials: any) => (dispatch: Dispatch) => void
  clearDeleteResult: () => UserscreenAction
  updateUser: (user: IUser, credentials: any) => (dispatch: Dispatch) => void
  clearUpdateResult: () => UserscreenAction
  searchMerchant: (props: IGet, credentials: any) => (dispatch: Dispatch) => void
  searchMarketplace: (props: ISearchProps, credentials: any) => (dispatch: Dispatch) => void
}

export const UserscreenActions: IUserscreenActions = {
  search: (email: string, credentials: any) => async (dispatch: Dispatch) => {
    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const resp: IUser | IError | null = await AuthAPIClient.searchUser(email)

    if (resp && (resp as IError).error) {
      return
    }

    dispatch({ type: UserscreenEvents.SEARCH, payload: resp })
  },
  clearSearch: () => ({ type: UserscreenEvents.CLEAR_SEARCH }),

  getRolesList: (credentials: any) => async (dispatch: Dispatch, getState: any) => {
    const {
      userscreen: { fixedRoleList },
    } = getState()

    if (fixedRoleList) {
      return dispatch({ type: UserscreenEvents.SET_ROLES, payload: fixedRoleList as IRole[] })
    }

    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const resp: IRole[] | IError = await AuthAPIClient.getRolesList()

    if ((resp as IError).error) {
      return
    }

    dispatch({ type: UserscreenEvents.SET_ROLES, payload: resp as IRole[] })
  },

  createUser: (user: Omit<IUser, 'tenant'>, credentials: any) => async (dispatch: Dispatch) => {
    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const response: ISuccess | IError = await AuthAPIClient.createUser(user)

    let result: boolean = response.hasOwnProperty('success')

    dispatch({ type: UserscreenEvents.CREATE_RESULT, payload: result })
  },
  clearCreateResult: () => ({ type: UserscreenEvents.CLEAR_CREATE_RESULT }),

  deleteUser: (email: string, credentials: any) => async (dispatch: Dispatch) => {
    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const response: ISuccess | IError = await AuthAPIClient.deleteUser(email)

    let result: boolean = response.hasOwnProperty('success')

    dispatch({ type: UserscreenEvents.DELETE_RESULT, payload: result })
  },
  clearDeleteResult: () => ({ type: UserscreenEvents.CLEAR_DELETE_RESULT }),

  updateUser: (user: IUser, credentials: any) => async (dispatch: Dispatch) => {
    const AuthAPIClient = AuthAPI({
      baseURL: String(process.env.REACT_APP_API_ADDRESS),
      headers: mountAuthHeaders(credentials),
    })

    const client = AuthAPIClient.getClient()

    interceptor(client, dispatch)

    const response: ISuccess | IError = await AuthAPIClient.updateUser(user)

    let result: boolean = response.hasOwnProperty('success')

    dispatch({ type: UserscreenEvents.CREATE_RESULT, payload: result })
  },
  clearUpdateResult: () => ({ type: UserscreenEvents.CLEAR_UPDATE_RESULT }),

  searchMerchant: (props: IGet, credentials: any) => async (dispatch: Dispatch) => {
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const resp: IMerchantResult | IError = await MerchantsClient.getMerchants(props)

    if ((resp as IError).error) {
      dispatch({ type: UserscreenEvents.SEARCH_MERCHANT, payload: null })
      return
    }

    dispatch({ type: UserscreenEvents.SEARCH_MERCHANT, payload: resp as IMerchantResult })
  },

  searchMarketplace: (props: ISearchProps, credentials: any) => async (dispatch: Dispatch) => {
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const resp = await MarketplaceClient.search(props)

    if ((resp as IError).error) {
      dispatch({ type: UserscreenEvents.SEARCH_MARKETPLACE, payload: null })
      return
    }

    dispatch({ type: UserscreenEvents.SEARCH_MARKETPLACE, payload: resp as ISearchResult })
  },
}
