import { Dispatch } from 'redux'
import { Merchants, IMerchant, IPutMerchant } from '../resources/merchants'
import { CNAE } from 'src/resources/merchants'

import { mountAuthHeaders } from '../utils'

export enum SellerEvents {
  SET_SELLER = 'SET_SELLER',
  CLEAR_SELLER = 'CLEAR_SELLER',
  UPDATE_RESULT = 'UPDATE_RESULT',
  CLEAR_UPDATE_RESULT = 'CLEAR_UPDATE_RESULT',
  SET_CNAE_LIST = 'SET_CNAE_LIST',
  SET_LOADING = 'SET_LOADING',
  SET_SAVE_LOADING = 'SET_SAVE_LOADING',
  SET_OPEN_SELLER_FORM_FEEDBACK = 'SET_OPEN_SELLER_FORM_FEEDBACK',
}

export interface SellerAction {
  type: SellerEvents
  payload?: any
}

export interface Seller {
  seller: IMerchant | null
  updateSuccess: null | boolean
  loading?: boolean
  saveLoading?: boolean
  openFeedback: boolean
  editionSuccess: boolean
  registrationSuccess: boolean
  errorMessage: string
  cnaelist: CNAE[] | null
}

export const seller: Seller = {
  seller: null,
  updateSuccess: null,
  loading: false,
  saveLoading: false,
  openFeedback: false,
  editionSuccess: false,
  registrationSuccess: false,
  errorMessage: '',
  cnaelist: null,
}

export const sellerReducer = (state: Seller = seller, action: SellerAction): Seller => {
  switch (action.type) {
    case SellerEvents.SET_LOADING:
      return { ...state, loading: action.payload }
    case SellerEvents.SET_SAVE_LOADING:
      return { ...state, saveLoading: action.payload }
    case SellerEvents.CLEAR_SELLER:
      return { ...state, seller: null }
    case SellerEvents.SET_SELLER:
      return { ...state, seller: action.payload, loading: false }
    case SellerEvents.SET_CNAE_LIST:
      return { ...state, cnaelist: action.payload }
    case SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK:
      return {
        ...state,
        openFeedback: action.payload.open,
        editionSuccess: action.payload.result,
        registrationSuccess: action.payload.result,
        errorMessage: action.payload.errorMessage,
      }
    case SellerEvents.UPDATE_RESULT:
      return { ...state, updateSuccess: action.payload.result, seller: action.payload.seller, saveLoading: false }
    case SellerEvents.CLEAR_UPDATE_RESULT:
      return { ...state, updateSuccess: null, openFeedback: false }
    default:
      return state
  }
}

export interface ISellerActions {
  getSeller: (sellerId: number, credentials: any) => (dispatch: Dispatch) => void
  updateSeller: (
    sellerId: number,
    data: IPutMerchant & { [key: string]: any },
    credentials: any,
  ) => (dispatch: Dispatch) => void
  clearUpdateResult: () => SellerAction
  setOpenFeedback: (open: boolean) => SellerAction
}

export const SellerActions: ISellerActions = {
  getSeller: (sellerId: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: SellerEvents.CLEAR_SELLER })
    dispatch({ type: SellerEvents.SET_LOADING, payload: true })
    dispatch({
      type: SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK,
      payload: { result: undefined, open: false, errorMessage: '' },
    })

    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    try {
      const cnaeList = await MerchantsClient.getBusinessCategories()
      const response = await MerchantsClient.getMerchant(sellerId)
      dispatch({ type: SellerEvents.SET_SELLER, payload: response })
      dispatch({ type: SellerEvents.SET_CNAE_LIST, payload: cnaeList })
    } catch (err: any) {
      dispatch({
        type: SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK,
        payload: { result: false, open: true, errorMessage: err?.response?.data?.message },
      })
    } finally {
      dispatch({ type: SellerEvents.SET_LOADING, payload: false })
    }
  },

  updateSeller:
    (sellerId: number, data: IPutMerchant & { [key: string]: any }, credentials: any) => async (dispatch: Dispatch) => {
      dispatch({ type: SellerEvents.SET_SELLER, payload: data })
      dispatch({ type: SellerEvents.SET_SAVE_LOADING, payload: true })
      dispatch({
        type: SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK,
        payload: { result: undefined, open: false, errorMessage: '' },
      })
      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      try {
        await MerchantsClient.updateMerchant(sellerId, data)
        dispatch({ type: SellerEvents.SET_SAVE_LOADING, payload: false })
        dispatch({ type: SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK, payload: { result: true, open: true } })
      } catch (err: any) {
        dispatch({ type: SellerEvents.SET_SAVE_LOADING, payload: false })
        dispatch({
          type: SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK,
          payload: { result: false, open: true, errorMessage: err.response.data.message },
        })
      }
    },

  clearUpdateResult: () => ({ type: SellerEvents.CLEAR_UPDATE_RESULT }),
  setOpenFeedback: (open: boolean) => ({ type: SellerEvents.SET_OPEN_SELLER_FORM_FEEDBACK, payload: open }),
}
