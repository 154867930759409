import React from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'

const { useState } = React

interface ExpansionMenuProps {
  data: { [key: string]: any }[]
  getComponent: (item: { [key: string]: any }) => React.ReactNode
  SumaryComponent: () => React.ReactNode
  onChange?: (item: { [key: string]: any }) => void
  expansionMenuClasses?: any
  sumaryClasses?: any
  menuItemClasses?: any
  expandMoreIconClasses?: any
}

const ExpansionMenu: React.FC<ExpansionMenuProps> = ({
  data,
  getComponent,
  SumaryComponent,
  onChange,
  expansionMenuClasses,
  sumaryClasses,
  menuItemClasses,
  expandMoreIconClasses,
}: ExpansionMenuProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSelect = (item) => () => {
    setIsExpanded(false)
    if (onChange) {
      onChange(item)
    }
  }

  return (
    <Accordion expanded={isExpanded} square={true} onChange={toggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={`${expandMoreIconClasses} Bolt-ExpansionMenu-expandMoreIcon`} />}
      >
        {SumaryComponent()}
      </AccordionSummary>
      <AccordionDetails>
        {data.map((item, index) => (
          <MenuItem key={index} onClick={handleSelect(item)}>
            {getComponent(item)}
          </MenuItem>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export { ExpansionMenu }
