import { useState, useEffect } from 'react'
import { Merchants } from './merchants'
import { Marketplace } from './marketplace'
import { mountAuthHeaders } from '../utils'
import { RootState } from 'src/store/Store'
import { useSelector } from 'react-redux'
import React from 'react'
import { Alert, Snackbar } from '@mui/material'

type ClientNameType = 'merchants' | 'marketplace'

const useFetch = (clientName: ClientNameType) => {
  const [client, setClient] = useState<any>()
  const [message, setMessage] = useState<string>('')
  const [openModal, setOpenModal] = useState(false)
  const user = useSelector((state: RootState) => state.user)

  const api = {
    merchants: Merchants,
    marketplace: Marketplace,
  }

  const createClient = (clientName: string) => {
    const newClient = api[clientName]({
      headers: mountAuthHeaders(user),
    })
    setClient(newClient)
  }

  const fetch = async (apiRequest: () => void) => {
    try {
      const result = await apiRequest()
      return result
    } catch (err: any) {
      setOpenModal(true)
      setMessage(err.message)
      throw err
    }
  }

  const toaster = () => {
    return (
      <Snackbar open={openModal} autoHideDuration={6000} onClose={() => setOpenModal(false)}>
        <Alert onClose={() => setOpenModal(false)} severity='error' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    )
  }

  useEffect(() => {
    createClient(clientName)
  }, [clientName])

  return [client, fetch, toaster]
}

export default useFetch
