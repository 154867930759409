import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import en from './locales/en'
import es from './locales/es'
import pt from './locales/pt'

import { TranslationActions, AbledLanguages } from '../store'

import { I18nProvider } from '@lingui/react'
import { setupI18n } from '@lingui/core'
import { RootState } from '../store/Store'

const i18n = setupI18n()

const catalogs = { en, es, pt }

const { changeLanguage, exposeI18n } = TranslationActions

interface TranslationProps {
  children: React.ReactNode
}
const Translation: React.FC<TranslationProps> = ({ children }: TranslationProps) => {
  const { language, i18n: statei18n } = useSelector((state: RootState) => state.translation)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!statei18n) dispatch(exposeI18n(i18n))
  }, [statei18n])

  useEffect(() => {
    const localLang: string | null = localStorage.getItem('ui.language')

    if (!localLang) {
      let userLang: string | null = navigator.language || (window.navigator as any).userLanguage
      if (userLang) {
        userLang = userLang.substr(0, 2)
        dispatch(changeLanguage(userLang as keyof AbledLanguages))
      }
    } else {
      dispatch(changeLanguage(localLang as keyof AbledLanguages))
    }
  }, [language])

  return (
    // @ts-expect-error
    <I18nProvider i18n={i18n} catalogs={{ ...catalogs }} language={language}>
      {children}
    </I18nProvider>
  )
}

export default Translation
