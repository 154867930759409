import React from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ICreditorMarketplacesSearchResult } from '../../../store/Creditor'
import { SearchResults } from '../../search'
import { RootState } from '../../../store/Store'

interface CommonViewProps {
  marketplaces: ICreditorMarketplacesSearchResult | undefined
  getSellers: (page: number) => void
}

const CommonView: React.FC<CommonViewProps> = ({ marketplaces, getSellers }: CommonViewProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)

  const history = useHistory()

  const onChangePage = (nextPage: number) => {
    getSellers(nextPage)
  }

  const onClick = (clicked) => {
    history.push(`/marketplace/edit/${clicked.id}/home`)
    return clicked
  }

  return (
    <SearchResults
      changePage={onChangePage}
      loading={!marketplaces}
      header={[
        {
          title: 'ID',
          prop: 'id',
        },
        {
          title: i18n._(t`Nome da marca`),
          prop: 'brand_name',
        },
        {
          title: i18n._(t`Nome`),
          prop: 'name',
        },
        {
          title: i18n._(t`Numero do documento`),
          prop: 'document_number',
        },
      ]}
      data={marketplaces}
      onClick={onClick}
    />
  )
}

export { CommonView }
